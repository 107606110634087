// import Vuex from "vuex"
import { fetchApartmentImagesInCache } from "@/helpers/imagesCache"
import Case from "case"
import dot from "dot-object"

import {
  getClientByUrlKey,
  getEmailSendFroms,
  postCosultationEmail,
} from "@/helpers/api"
import { Logger } from "@/helpers/logger"
import modules from "@/storeModules"
import {
  DEAL_TYPE,
  LOCAL_STORAGE_KEY,
  OBJECT_LOCAL_STORAGE_KEY,
  PROPERTY_DATA_TYPE,
  PROPERTY_TYPE,
  SORT_TYPE_OPTIONS,
  URL_SAVE_STATE_KEYS,
  USER_TYPE,
} from "@share/const"
import { getCustomerState, getRegistrationStep } from "@share/customerState"
import { checkValidOptions } from "@share/entities/filterOptions"
import { initialFilter, initialState } from "@share/storeInitialData"
import {
  ENABLE_SESSION_FROM_URL_SHARE,
  INITIALIZE_FILTER,
  INITIALIZE_POLYGONS,
  LOAD_POLYGONS,
  MARK_IMAGE_CACHE_UPDATED,
  REPLACE_LAYERS,
  RESET_FILTER_PRICE,
  RETRIEVE_FILTER,
  SAVE_URL_KEY,
  SET_USER_ERROR_CODE,
  UPDATE_CUSTOM_MAIL_TEMPLATE_DATA,
  UPDATE_DATA_TYPE,
  UPDATE_EMAIL_SEND_FROMS,
  UPDATE_FILTER_LOCATION_NAME,
  UPDATE_FILTER_VALUE,
  UPDATE_IS_FILTER_MODE,
  UPDATE_LIST_APPEARANCE_TYPE,
  UPDATE_LOCAL_STORAGE_VALUE,
  UPDATE_MANAGER,
  UPDATE_MAP_CENTER,
  UPDATE_REFERENCE,
  UPDATE_SATEI_INFO,
  UPDATE_SATEI_INFO_LOCATION,
  UPDATE_SELL_FORM_EMAIL_SEND_FROM,
  UPDATE_SORT_TYPE,
  UPDATE_SP_SIMPLE_MESSAGE_CONTENTS,
  UPDATE_USER_EMAIL,
  UPDATE_USER_INFO,
  UPDATE_USER_PHOTO_INFO,
  USER_LOGIN,
  USER_LOGOUT,
} from "./mutationNames"

import { createStore } from "vuex"
import { usePropertyList } from "./composables/propertyList/usePropertyList"
import {
  chain,
  cloneDeep,
  difference,
  each,
  extend,
  get,
  isEqual,
  keys,
  merge,
  pick,
  pickBy,
  reduce,
  values,
} from "lodash"

// Vue.use(Vuex)

const logger = Logger("store")

const getters = {
  isLoggedIn: (state) => {
    return state.registerForm.isLoggedIn
  },
  emailSendFromDropDownList: (state) => {
    const { emailSendFroms } = state
    const { manager } = state

    if (!manager) return []

    return [
      {
        value: "0",
        text: manager.userName,
        photoUrl: manager.photoUrl,
      },
    ].concat(
      chain(emailSendFroms)
        .filter((esf) => {
          return esf.emailConfirmed
        })
        .map((esf) => {
          const { id, staffName, corporateName, photoUrl } = esf
          return {
            value: id.toString(),
            text: staffName,
            photoUrl,
          }
        })
        .value()
    )
  },
  referDataValue: (state) => {
    const value = pick(state, URL_SAVE_STATE_KEYS)
    value.urlKey = state.urlKey
    return value
  },
  fromUrlShareFlag: (state) => {
    if (!state.isSessionFromUrlShare) {
      return false
    }
    return true
  },
  propertyTypeLabel: (state) => {
    if (
      state.sateiTarget.propertyType == "apartment" ||
      state.sateiTarget.propertyType[0] == "apartment"
    ) {
      return "マンション"
    } else if (
      state.sateiTarget.propertyType == "house" ||
      state.sateiTarget.propertyType[0] == "house"
    ) {
      return "戸建"
    }
  },
  isApartment: (state) => {
    return (
      state.sateiTarget.propertyType === "apartment" ||
      state.sateiTarget.propertyType[0] === "apartment"
    )
  },
  userId: (state) => {
    return state.registerForm.userId
  },
  managerId: (state) => {
    return state.registerForm.managerId
  },
  isManager: (state) => {
    return state.registerForm.userId === state.registerForm.managerId
  },
  userType: (state) => {
    if (state.registerForm.corporateName) {
      return USER_TYPE.CORPORATE_USER
    }
    return USER_TYPE.ANONYMOUS_USER
  },
  isCorporateUser: (state, getters) => {
    return getters.userType === USER_TYPE.CORPORATE_USER
  },
  isAdminRole: (state) => {
    return state.registerForm.role === "admin"
  },
  getGuestUrlKey(state) {
    return state.localStorageValues[LOCAL_STORAGE_KEY.GUEST_URL_KEY]
  },
  isShowConsultation(state, getters) {
    const selectedProperty = getters["properties/selectedProperty"]
    const isNotContract =
      selectedProperty && selectedProperty.dataType !== "contract"
    return (
      getters.getGuestUrlKey &&
      getters.userType !== USER_TYPE.CORPORATE_USER &&
      isNotContract
    )
  },
  // customer は Stripe の概念で、PinRich に対して支払いをするもの = user
  customerState(state, getters) {
    if (!getters.isLoggedIn) {
      return null
    }
    return getCustomerState(state.registerForm)
  },
  registrationStep(state) {
    return getRegistrationStep(state.registerForm)
  },
}

const mutations = {
  [UPDATE_FILTER_VALUE](state, { key, value }) {
    dot.str(key, value, state.filter)
    localStorage.setItem("filterForm", JSON.stringify(state.filter))
  },
  [INITIALIZE_FILTER](state) {
    const filterData =
      cloneDeep(JSON.parse(localStorage.getItem("filterForm"))) ??
      cloneDeep(initialFilter)
    const activeFilter = cloneDeep(initialState.activeFilter)
    state.filter = filterData
    state.activeFilter = activeFilter
  },
  [RETRIEVE_FILTER](state) {
    const filterData =
      cloneDeep(JSON.parse(localStorage.getItem("filterForm"))) ??
      cloneDeep(initialFilter)
    const defaultFilterData = cloneDeep(initialFilter)
    const diffLocalFilter = pickBy(
      filterData,
      (value, key) => !isEqual(value, defaultFilterData[key])
    )
    const diffCurrentFilter = pickBy(
      state.filter,
      (value, key) => !isEqual(value, defaultFilterData[key])
    )

    state.filter = {
      ...defaultFilterData,
      ...diffLocalFilter,
      ...diffCurrentFilter,
    }
  },
  [RESET_FILTER_PRICE](state) {
    if (state.filter.filterPrice) {
      const defaultFilterData = cloneDeep(initialFilter)
      if (state.selectedContractType === "rent") {
        const isValidOption = checkValidOptions({
          key: "rentPrice",
          value: state.filter.filterPrice,
        })
        if (isValidOption)
          state.filter.rentPrice = cloneDeep(state.filter.filterPrice)
        state.filter.contractPrice = cloneDeep(defaultFilterData.contractPrice)
      } else {
        const isValidOption = checkValidOptions({
          key: "contractPrice",
          value: state.filter.filterPrice,
        })
        if (isValidOption)
          state.filter.contractPrice = cloneDeep(state.filter.filterPrice)
        state.filter.rentPrice = cloneDeep(defaultFilterData.rentPrice)
      }
    }
    localStorage.setItem("filterForm", JSON.stringify(state.filter))
  },
  [ENABLE_SESSION_FROM_URL_SHARE](state) {
    state.isSessionFromUrlShare = true
  },
  [UPDATE_DATA_TYPE](state, tabType) {
    state.selectedContractType = tabType
    localStorage.setItem("dataType", tabType)
  },
  [USER_LOGIN](state) {
    state.registerForm.isLoggedIn = true
  },
  [USER_LOGOUT](state) {
    state.registerForm = cloneDeep(initialState.registerForm)
  },
  [SET_USER_ERROR_CODE](state, errorCode) {
    state.userErrorCode = errorCode
  },
  [UPDATE_USER_EMAIL](state, email) {
    state.registerForm.email = email
  },
  [UPDATE_USER_INFO](state, userInfo) {
    const keys = Object.keys(state.registerForm)
    keys.forEach((key) => {
      if (userInfo[key] !== undefined) {
        state.registerForm[key] = userInfo[key]
      }
    })
  },
  [UPDATE_USER_PHOTO_INFO](state, photoInfo) {
    if (photoInfo.photoName !== undefined) {
      state.registerForm.photoName = photoInfo.photoName
    }
    if (photoInfo.photoUploaded !== undefined) {
      state.registerForm.photoUploaded = photoInfo.photoUploaded
    }
    if (photoInfo.photoUrl !== undefined) {
      state.registerForm.photoUrl = photoInfo.photoUrl
    }
  },
  [UPDATE_CUSTOM_MAIL_TEMPLATE_DATA](state, data) {
    extend(state.customMailTemplateData, data)
  },
  [UPDATE_MANAGER](state, manager) {
    state.manager = manager
  },
  [UPDATE_EMAIL_SEND_FROMS](state, emailSendFroms) {
    state.emailSendFroms = emailSendFroms
  },
  [UPDATE_FILTER_LOCATION_NAME](state, location) {
    state.activeFilter.locationName = location
  },
  [UPDATE_SATEI_INFO_LOCATION](state, location) {
    state.sateiTarget.location = location
  },
  [SAVE_URL_KEY](state, key) {
    state.urlKey = key
  },
  [UPDATE_REFERENCE](state, { value }) {
    each(URL_SAVE_STATE_KEYS, (k) => {
      if (!value[k]) {
        return
      }
      state[k] = value[k]
    })
  },
  [LOAD_POLYGONS](state, { polygonType, data }) {
    state.polygons[polygonType] = data
  },
  [UPDATE_SATEI_INFO](
    state,
    { location, position, exclusiveArea, propertyType }
  ) {
    extend(state.sateiTarget, {
      location,
      position,
      propertyType,
      exclusiveArea,
    })
    state.sessionPropertySearchNum = state.sessionPropertySearchNum + 1
    state["properties/selectedPropertyId"] = ""
  },
  [UPDATE_IS_FILTER_MODE](state, isFilterMode) {
    state.isFilterMode = isFilterMode
  },
  [UPDATE_LOCAL_STORAGE_VALUE](state, { key, value }) {
    state.localStorageValues[key] = value
    if (value) {
      const setValue = OBJECT_LOCAL_STORAGE_KEY[Case.constant(key)]
        ? JSON.stringify(value)
        : value
      localStorage.setItem(key, setValue)
    } else {
      localStorage.removeItem(key)
    }
  },
  [INITIALIZE_POLYGONS](state) {
    state.selectedLayers = []
    state.polygons = cloneDeep(initialState.polygons)
  },
  [REPLACE_LAYERS](state, selectedLayers) {
    state.selectedLayers = selectedLayers
  },
  [UPDATE_SELL_FORM_EMAIL_SEND_FROM](state, emailSendFrom) {
    state.sellFormEmailSendFrom = emailSendFrom
  },
  [UPDATE_MAP_CENTER](state, mapCenter) {
    if (mapCenter.zoom) {
      state.mapCenter.zoom = mapCenter.zoom
    }
    if (mapCenter.position) {
      state.mapCenter.position = mapCenter.position
    }
  },
  [UPDATE_SP_SIMPLE_MESSAGE_CONTENTS](state, contents) {
    state.spSimpleMessageContents = contents
  },
  [UPDATE_LIST_APPEARANCE_TYPE](state, type) {
    state.listAppearanceType = type
  },
  [UPDATE_SORT_TYPE](state, type) {
    state.sortType = type
  },
  [MARK_IMAGE_CACHE_UPDATED](state) {
    state.imageCacheUpdatedAt = Date.now()
  },
}

const actions = {
  saveGuestUrlKey: ({ getters, state, commit }) => {
    const userType = getters.userType
    if (userType !== USER_TYPE.CORPORATE_USER) {
      const urlKey = state.urlKey
      commit(UPDATE_LOCAL_STORAGE_VALUE, {
        key: LOCAL_STORAGE_KEY.GUEST_URL_KEY,
        value: urlKey,
      })
    }
  },
  updateSpSimleMessageContents: ({ commit }, contents) => {
    commit(UPDATE_SP_SIMPLE_MESSAGE_CONTENTS, contents)
  },
  removeGuestUrlKey: ({ getters, commit }) => {
    const userType = getters.userType
    if (userType === USER_TYPE.CORPORATE_USER) {
      commit(UPDATE_LOCAL_STORAGE_VALUE, {
        key: LOCAL_STORAGE_KEY.GUEST_URL_KEY,
      })
    }
  },
  updateFilterValue: ({ commit }, { key, value }) => {
    commit(UPDATE_FILTER_VALUE, { key, value })
  },
  enableSessionFromUrlShare: ({ commit }) => {
    commit(ENABLE_SESSION_FROM_URL_SHARE)
  },
  initializeFilter: ({ commit }) => {
    commit(INITIALIZE_FILTER)
    commit(RESET_FILTER_PRICE)
  },
  updateDataType: ({ commit }, tabType) => {
    commit(UPDATE_DATA_TYPE, tabType)
  },
  userLogin: ({ commit, dispatch }) => {
    commit(USER_LOGIN)
    dispatch("removeGuestUrlKey")
  },
  userLogout: ({ commit }) => {
    commit(USER_LOGOUT)
    commit(UPDATE_LOCAL_STORAGE_VALUE, {
      key: LOCAL_STORAGE_KEY.REGISTER_FORM,
    })
  },
  setUserError: ({ commit }, errorCode) => {
    commit(SET_USER_ERROR_CODE, errorCode)
  },
  updateUserEmail: ({ commit }, email) => {
    commit(UPDATE_USER_EMAIL, email)
    commit(UPDATE_LOCAL_STORAGE_VALUE, {
      key: LOCAL_STORAGE_KEY.EMAIL,
      value: email,
    })
  },
  updateCustomMailTemplateData: ({ commit }, data) => {
    commit(UPDATE_CUSTOM_MAIL_TEMPLATE_DATA, data)
  },
  updateUserInfo: ({ commit }, userInfo) => {
    commit(UPDATE_USER_INFO, userInfo)
  },
  updateUserPhotoInfo: ({ commit }, photoInfo) => {
    commit(UPDATE_USER_PHOTO_INFO, photoInfo)
  },
  updateFilterLocationName: ({ commit }, location) => {
    commit(UPDATE_FILTER_LOCATION_NAME, location)
  },
  updateListAppearanceType: ({ commit }, type) => {
    commit(UPDATE_LIST_APPEARANCE_TYPE, type)
  },
  updateLocalStorageValue: ({ commit }, { key, value }) => {
    commit(UPDATE_LOCAL_STORAGE_VALUE, { key, value })
  },
  async updateEmailSendFroms({ commit }) {
    const { manager, staffs } = await getEmailSendFroms()
    commit(UPDATE_MANAGER, manager)
    commit(UPDATE_EMAIL_SEND_FROMS, staffs)
  },
  async saveAndPostConsultation({ commit, dispatch }, info) {
    const { urlKey, property, type } = info
    commit(SAVE_URL_KEY, urlKey)
    dispatch("saveGuestUrlKey")
    await postCosultationEmail({
      type,
      urlKey,
      property,
    })
  },
  updateReference({ commit }, value) {
    commit(UPDATE_REFERENCE, { value })
  },
  async loadReference({ commit, dispatch, state }, urlKey) {
    if (state.urlKey === urlKey) {
      return
    }
    logger.time("loadReference")
    const client = await getClientByUrlKey({ urlKey })
    const { referenceData, emailSendFromData } = client
    referenceData.sateiTarget.propertyType = [client.propertyType]
    const position = get(referenceData, "sateiTarget.position")
    commit(UPDATE_REFERENCE, { value: referenceData })
    commit(UPDATE_MAP_CENTER, {
      position,
    })
    commit(UPDATE_SELL_FORM_EMAIL_SEND_FROM, emailSendFromData)
    commit(SAVE_URL_KEY, urlKey)
    let dataType
    switch (client.dealType) {
      case DEAL_TYPE.SELL:
        dispatch("updateDataType", PROPERTY_DATA_TYPE.CONTRACT)
        dataType = PROPERTY_DATA_TYPE.CONTRACT
        break
      case DEAL_TYPE.BUY:
        dispatch("updateDataType", PROPERTY_DATA_TYPE.SALE)
        dataType = PROPERTY_DATA_TYPE.SALE
        break
      case DEAL_TYPE.RENT:
        dispatch("updateDataType", PROPERTY_DATA_TYPE.RENT)
        dataType = PROPERTY_DATA_TYPE.RENT
        break
    }
    commit(RETRIEVE_FILTER)
    commit(RESET_FILTER_PRICE)
    dispatch("saveGuestUrlKey")
    const propertyList = usePropertyList()
    const propertyType = client.propertyType || "apartment"
    let subPropertyType = propertyList.filter.subPropertyType
    if (
      propertyType === "apartment" &&
      !subPropertyType.includes("apartment")
    ) {
      subPropertyType = ["apartment"]
    } else if (
      propertyType === "house" &&
      subPropertyType.includes("apartment")
    ) {
      subPropertyType = ["onlyLand", "onlyHouse"]
    }
    propertyList.filter = {
      ...propertyList.filter,
      dataType: dataType || "sale",
      ...state.filter,
      propertyType,
      subPropertyType,
      isDefaultValue: undefined,
    }
    logger.timeEnd("loadReference")
  },
  updateSateiInfoLocation({ commit }, location) {
    commit(UPDATE_SATEI_INFO_LOCATION, location)
  },
  // FIXME: updateSateiInfoが顧客登録系からも呼ばれているが、
  //  本質的に違う情報にアクセスしたいはずなので、そちらを分離する
  async updateSateiInfo(
    { commit, dispatch, state },
    {
      location,
      position,
      mapCenter,
      exclusiveArea,
      propertyType,
      deleteProperties = true,
      postActionLog = true,
      keepSateiPosition = false,
    }
  ) {
    const nextSateiTarget = {
      location,
      position,
      exclusiveArea,
      propertyType,
    }

    if (deleteProperties) {
      dispatch("properties/deleteProperties")
    }

    if (propertyType) {
      localStorage.setItem("propertyType", propertyType)
    }
    commit(UPDATE_SATEI_INFO, nextSateiTarget)
    commit(UPDATE_MAP_CENTER, {
      position: mapCenter || position,
      zoom: keepSateiPosition ? undefined : 16,
    })
    dispatch("initializePolygons")
  },
  updateMapCenter({ commit }, mapCenter) {
    commit(UPDATE_MAP_CENTER, mapCenter)
  },
  updateIsFilterMode({ commit }, isFilterMode) {
    commit(UPDATE_IS_FILTER_MODE, isFilterMode)
  },
  initializePolygons({ commit }) {
    commit(INITIALIZE_POLYGONS)
  },
  replaceSelectedLayers({ commit }, selectedLayers) {
    commit(REPLACE_LAYERS, selectedLayers)
  },
  updateSortType({ commit }, type) {
    if (!SORT_TYPE_OPTIONS[type]) return
    commit(UPDATE_SORT_TYPE, type)
  },
  markImageCacheUpdated({ commit }) {
    commit(MARK_IMAGE_CACHE_UPDATED)
  },
  retrieveFilter: ({ commit }) => {
    commit(RETRIEVE_FILTER)
    commit(RESET_FILTER_PRICE)
  },
}

const getPathsToSave = () => {
  const pathsToExclude = ["urlKey", "mailModal"]
  const keys = keys(initialState)
  return difference(keys, pathsToExclude)
}

const initialSateiTarget = {
  sateiTarget: {
    // 東京駅
    position: {
      lat: 35.681236,
      lng: 139.767125,
    },
    location: "",
    exclusiveArea: 50,
    propertyType: ["apartment"],
  },
}

const initialMapCenter = {
  mapCenter: {
    position: initialSateiTarget.sateiTarget.position,
    zoom: 16,
  },
}

const initialLocalStorageValues = {
  localStorageValues: reduce(
    values(LOCAL_STORAGE_KEY),
    (result, key) => {
      const value = localStorage.getItem(key)
      if (value) {
        const isJson = OBJECT_LOCAL_STORAGE_KEY[Case.constant(key)]
        if (isJson) {
          result[key] = JSON.parse(value)
        } else {
          result[key] = value
        }
      }
      return result
    },
    {}
  ),
}

const initialRegisterForm = {
  registerForm:
    initialLocalStorageValues.localStorageValues.registerForm ||
    initialState.registerForm,
}

const store = createStore({
  state: merge(
    cloneDeep(initialState),
    cloneDeep(initialSateiTarget),
    cloneDeep(initialMapCenter),
    cloneDeep(initialLocalStorageValues),
    cloneDeep(initialRegisterForm)
  ),
  getters,
  actions,
  mutations,
  modules,
})
export default store

store.watch(
  (state, getters) => ({
    activeProperties: getters["properties/activeProperties"],
    properties: getters["properties/properties"],
  }),
  async ({ activeProperties, properties }) => {
    logger.time("fetchImages")
    if (
      store.state.sateiTarget.propertyType?.includes(PROPERTY_TYPE.APARTMENT)
    ) {
      const PROPERTIES_PER_PAGE = 18
      await fetchApartmentImagesInCache(
        activeProperties.slice(0, PROPERTIES_PER_PAGE)
      )
      store.dispatch("markImageCacheUpdated")
      await fetchApartmentImagesInCache(properties)
    }
    store.dispatch("markImageCacheUpdated")
    logger.timeEnd("fetchImages")
  }
)
