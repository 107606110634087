import { moment } from "./moment"

const TIME_ZONE_NAME = "Asia/Tokyo"

export function utcToJstFormat(utcTime: string) {
  if (!utcTime) {
    return
  }
  return moment.tz(utcTime, TIME_ZONE_NAME).format("YYYY-MM-DD HH:mm:ss")
}

export function isNightTime(targetTime: string) {
  if (!targetTime) {
    return
  }
  const hour = moment.tz(targetTime, TIME_ZONE_NAME).hour()
  return hour >= 22 || hour <= 7
}
