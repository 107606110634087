import { CUSTOMER_STATE_TYPE, REGISTRATION_STEP } from "@share/const"

const { NOT_REGISTERED, COMPLETED, NEED_VERIFICATION } = REGISTRATION_STEP
const { PAID } = CUSTOMER_STATE_TYPE

export const getCustomerState = (
  { userId, sesEmailVerified, currentPeriodEnd, cancelAtPeriodEnd, expiresAt } // user data
) => {
  // フロントエンドではログインしていなければ null

  const currentTime = Date.now()

  const registrationStep = getRegistrationStep({
    userId,
    sesEmailVerified,
    currentPeriodEnd,
  })

  if (registrationStep !== COMPLETED) {
    return null
  }
  // // サブスク登録済み
  // if (currentTime > expiresAt) {
  //   return EXPIRED
  // }
  // // 有効期限内
  // if (cancelAtPeriodEnd) {
  //   return CANCELED
  // }
  // // 解約していない
  // if (currentTime > currentPeriodEnd) {
  //   return NEED_CHECK_PAYMENT
  // } else {
  //   return PAID
  // }
  return PAID
}

export const getRegistrationStep = ({ userId, sesEmailVerified }) => {
  // 未登録
  if (!userId) {
    return NOT_REGISTERED
  }
  // https://pinrichdev.atlassian.net/browse/PN-2821
  // if (!sesEmailVerified) {
  //   return NEED_VERIFICATION
  // }
  // 有効なユーザー
  return COMPLETED
}
