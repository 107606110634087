import { computed, reactive, ref } from "vue"

import { createContext } from "@/composables/createContext"
import * as apiSiteSettings from "@/helpers/api/apiSiteSettings"
import { pageConstants } from "@/helpers/pageConstants"
import store from "@/store"
import { SiteSettings } from "@share/entities/SiteSettings"
import { throwError } from "@share/error"

export const [provideSharedSiteSettings, injectSiteSettings] = createContext<
  ReturnType<typeof useSiteSettings>
>("site settings", true)

export const useSiteSettings = (
  { initialState, api = apiSiteSettings } = {
    initialState: {},
  }
) => {
  const settings = new SiteSettings(initialState)
  // MEMO: vue2 の composition-api では reactive は mutating なのでコピーしておく
  // settings は浅いオブジェクトなので、ここでは shallow copy で十分
  const state = reactive({ ...settings })
  const managerIdRef = ref(store.getters.managerId)

  const siteUrlBeforeSubdomain = (() => {
    const { protocol } = window.location
    return `${protocol}//`
  })()
  const siteUrlAfterSubdomain = (() => {
    const { hostname, port } = window.location
    const baseDomain = (function getBaseDomain() {
      const currentPageSubdomain = pageConstants.siteSettings.subdomain
      if (currentPageSubdomain) {
        // すでにサブドメインが適用された状態で管理画面に入ってきた場合への対応
        const regexpCaptureBaseDomain = new RegExp(
          `^(?:${currentPageSubdomain}.)?(?<baseDomain>.+)$`
        )
        const match = hostname.match(regexpCaptureBaseDomain)
        return match?.groups?.baseDomain
      } else {
        return hostname
      }
    })()
    return [baseDomain, port ? ":" + port : ""].join("")
  })()
  const siteUrl = computed(() => {
    return [
      siteUrlBeforeSubdomain,
      state.subdomain,
      state.subdomain ? "." : "",
      siteUrlAfterSubdomain,
    ].join("")
  })
  const siteUrlSearch = computed(() => {
    return siteUrl.value + "/search"
  })
  const siteUrlAi = computed(() => {
    return siteUrl.value + "/ai"
  })
  const siteUrlLoan = computed(() => {
    return siteUrl.value + "/loan"
  })

  const initialize = (initialState) => {
    Object.assign(state, new SiteSettings(initialState || {}))
  }

  /**
   * initialState がなければ loadSiteSettings によって設定を読み込まなければならない。
   *
   * Suspense を使った async setup ができないので、
   * Promise の pending / fulfilled を ref(boolean) の true / false に変換し、
   * vue の reactive の仕組みによって描画をトリガーする。
   */
  const isLoading = ref(!initialState)
  const loadSiteSettings = async ({ managerId }: { managerId: string }) => {
    isLoading.value = true
    managerIdRef.value = managerId
    try {
      const siteSettings = await api.get(managerIdRef.value)
      Object.assign(state, siteSettings)
    } finally {
      isLoading.value = false
    }
  }

  const updateSiteSettings = async (values: Partial<SiteSettings>) => {
    const managerId = managerIdRef.value
    if (!managerId) {
      throwError("MANAGER_ID_IS_REQUIRED_IN_SITE_SETTINGS")
    }
    const siteSettings = {
      subdomain: state.subdomain, // サーバーの都合で送信しなければならない
      ...values,
    }
    const siteSettingsUpdated = await api.update(managerId, siteSettings)
    Object.assign(state, siteSettingsUpdated)
  }

  return {
    state,
    isLoading,
    // computed
    siteUrl,
    siteUrlSearch,
    siteUrlAi,
    siteUrlLoan,
    siteUrlBeforeSubdomain,
    siteUrlAfterSubdomain,
    // actions
    initialize,
    loadSiteSettings,
    updateSiteSettings,
  }
}
