import { ROLE_TYPE } from "@share/const"

/**
 * @memo
 * - class の getter は { ...user } などとすると消えてしまう
 * - getters が class 定義の中にズラッとならんでわかりにくい
 * という問題があるので、
 *
 * ```
 * const { someGetterValue1, someGetterValue2 } = getSomeGetterValues(user)
 * ```
 * のように、必要に応じて値を生成する関数を作ったほうがいいかも。
 */
export class User {
  id: string
  managerId: string
  userName: string
  corporateName: string
  email: string
  phone: string
  photoUrl: string
  userIcon: string
  // iconUploaded: string // 不要？
  deliveryLimit: number
  sesEmailVerified: string
  status: string
  enabled: boolean
  createDate: string
  lastAccessAt: string
  lastModifiedDate: string
  /**
   * @deprecated stripeは使わない
   */
  customerId: string
  /**
   * @deprecated stripeは使わない
   */
  currentPeriodEnd: number
  /**
   * @deprecated stripeは使わない
   */
  cancelAtPeriodEnd: boolean
  role: string
  formattedPhone: string
  googleToken: string

  constructor(user) {
    this.id = user.id
    this.managerId = user.managerId || user.id
    this.userName = user.userName
    this.corporateName = user.corporateName
    this.email = user.email
    this.phone = user.phone
    this.photoUrl = user.photoUrl
    this.deliveryLimit = user.deliveryLimit
    this.sesEmailVerified = user.sesEmailVerified
    this.status = user.status
    this.enabled = user.enabled
    this.createDate = user.createDate
    this.lastAccessAt = user.lastAccessAt
    this.userIcon = user.userIcon
    this.lastModifiedDate = user.lastModifiedDate
    this.customerId = user.customerId
    this.currentPeriodEnd = user.currentPeriodEnd
    this.cancelAtPeriodEnd = user.cancelAtPeriodEnd
    this.role = user.role
    this.googleToken = user.googleToken

    this.formattedPhone = user.formattedPhone || user.phone
  }

  /**
   * @deprecated stripeは使わない
   */
  hasStripeCustomer() {
    return !!this.customerId
  }

  isAdmin() {
    return this.role === ROLE_TYPE.ADMIN
  }

  get userId() {
    return this.id
  }

  get isManager() {
    return this.managerId === this.id
  }

  get isStaff() {
    return this.managerId !== this.id
  }

  get createdAt() {
    return this.createDate ? new Date(this.createDate).getTime() : null
  }
  /**
   * @deprecated stripeは使わない
   */
  get expiresAt() {
    // cognito 上で customerId にひもづくユーザーの current_period_end を更新する
    // 次回決済が失敗しても支払手段の更新まで自動送信などのサービスを止めないように、１〜２日の余裕をもたせる。
    const day = 60 * 60 * 24 * 1000
    return this.currentPeriodEnd ? this.currentPeriodEnd + day * 2 : null
  }

  /**
   * @deprecated stripeは使わない
   */
  get expiresAtString() {
    return this.expiresAt
      ? new Date(this.expiresAt).toLocaleString("ja", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      : null
  }

  /**
   * @deprecated stripeは使わない
   */
  get currentPeriodEndString() {
    return this.currentPeriodEnd
      ? new Date(this.currentPeriodEnd).toLocaleString("ja", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      : null
  }

  get formattedName() {
    return `${this.userName}（${this.corporateName}）`
  }
}

/**
 * これから登録されるユーザーの情報。
 */
export type UserToBeRegistered = {
  userType: "manager" | "staff"
  managerId: string
  userId: string
}
export function checkIsUserToBeRegistered(
  value?: any
): value is UserToBeRegistered {
  if (!value) {
    return false
  }
  if (!Object.hasOwn(value, "userType")) {
    return false
  }
  if (!["manager", "staff"].includes(value.userType)) {
    return false
  }
  if (typeof value.managerId !== "string" || typeof value.userId !== "string") {
    return false
  }
  return true
}
