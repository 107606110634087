import { forEach, map } from "lodash"

import axios from "@/helpers/axios"
import { Logger } from "@/helpers/logger"
import store from "@/store.js"
import { encodeObject } from "@share/base64"
import { LOCAL_STORAGE_KEY } from "@share/const"
import { Client } from "@share/entities/Client/Client"
import { EmailSendFrom } from "@share/entities/EmailSendFrom"
import { Property } from "@share/entities/Property"
import { User } from "@share/entities/User"
import { randomStr } from "@share/random"

const logger = Logger("api")

export const updateSesEmailVerified = async () => {
  const response = await axios.put(`/auth/email_verified`)
  const { data } = response
  return data
}

export const postResendUserVerificationMail = async (email) => {
  const response = await axios.post(`/auth/resend_verification`, {
    email,
  })
  const { data } = response
  return data
}

export const postCustomMailTemplateTestMail = async ({ mailType, email }) => {
  const { managerId } = store.getters
  const response = await axios.post(
    `/users/${managerId}/mail_template/${mailType}/test`,
    {
      email,
    }
  )

  const { data } = response
  return data
}

export const postEmailSendFrom = async (inputData) => {
  const { managerId } = store.getters
  const formData = new FormData()
  forEach(inputData, (v, k) => {
    formData.append(k, v)
  })
  const response = await axios.post(
    `/users/${managerId}/email_send_from`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )

  const { data } = response
  return data
}

export const putEmailSendFrom = async (inputData) => {
  const { userId, managerId } = store.getters
  const { id } = inputData
  const formData = new FormData()
  formData.append("managerId", managerId)
  forEach(inputData, (v, k) => {
    formData.append(k, v)
  })
  const response = await axios.put(
    `/users/${managerId}/email_send_from/${id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )

  const { data } = response
  return data
}

export const reSendConfirmEmailSendFrom = async ({ id }) => {
  const { managerId } = store.getters
  const response = await axios.post(
    `/users/${managerId}/email_send_from/${id}/resend_confirm`
  )
  const { data } = response
  return data
}

export const deleteEmailSendFrom = async (inputData) => {
  const { managerId } = store.getters
  const { ids } = inputData
  const response = await axios.delete(`/users/${managerId}/email_send_from`, {
    data: {
      ids,
    },
  })

  const { data } = response
  return data
}

// ログポストのため、awaitしない
export const postPropertyDetailSeen = async ({
  propertyId,
  location,
  propertyType,
}) => {
  const urlKey = localStorage.getItem(LOCAL_STORAGE_KEY.GUEST_URL_KEY)
  if (!urlKey) {
    return
  }
  await axios.post("/client_action_logs/property_detail_seen", {
    propertyId,
    propertyType,
    location,
    urlKey,
  })
}

export const postOwnerPageSeen = ({ propertyId, location, urlKey }) => {
  axios.post("/client_action_logs/owner_page_seen", {
    propertyId,
    location,
    urlKey,
  })
}

export const updateExclusiveArea = ({ exclusiveArea, urlKey }) => {
  axios.post(`/clients/update_exclusive_area/${urlKey}`, {
    exclusiveArea,
  })
}

export const getHankyo = async ({ id }) => {
  const response = await axios.get(`/hankyos/${id}`)
  const { data } = response
  return data
}

export const getHankyoList = async (serverParams) => {
  const { managerId } = store.getters
  logger.info("serverParams", JSON.stringify(serverParams, null, 2))
  const response = await axios.get("/hankyos", {
    params: {
      managerId,
      serverParams: encodeObject(serverParams),
    },
  })

  const { data } = response
  return data
}

export const putHankyo = async (inputData) => {
  const { id } = inputData
  const response = await axios.put(`/hankyos/${id}`, {
    data: inputData,
  })
  const { data } = response
  return data
}

/**
 *
 * @param {import("@share/entities/Client").ClientInputAttributes} clientAttributes
 * @param {Filter} filter
 * @returns {Promise<any>}
 */
export const postClient = async (clientInputAttributes, filter) => {
  const response = await axios.post("/clients", {
    clientInputAttributes,
    filter,
  })
  const { data } = response
  return data
}

export const postClients = async ({ csvFile }) => {
  const { userId, userType } = store.getters
  const formData = new FormData()
  formData.append("userId", userId)
  formData.append("userType", userType)
  formData.append("csvFile", csvFile)
  const response = await axios.post("/clients", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

  const { data } = response
  return data
}

export const getClientEntityByUrlKey = async (
  { urlKey },
  checkCanSendProposalProperties
) => {
  const response = await axios.get(`/clients/by_url_key/${urlKey}`, {
    params: {
      checkCanSendProposalProperties,
    },
  })
  const { data } = response
  return Client.create({ client: data })
}

/**
 * @deprecated use getClientEntityByUrlKey
 */
export const getClientByUrlKey = async ({ urlKey }) => {
  const response = await axios.get(`/clients/by_url_key/${urlKey}`)
  const { data } = response
  return data
}

export const getClients = async (serverParams) => {
  logger.info("serverParams", serverParams)
  const response = await axios.get("/clients", {
    params: {
      serverParams: encodeObject(serverParams),
    },
  })

  const { data } = response
  const { totalRecords, clients, deliveryCount } = data
  return {
    totalRecords,
    clients: map(clients, (client) => Client.create({ client })),
    deliveryCount,
  }
}

export const putClient = async ({
  id,
  urlKey,
  urlShareUserData,
  sendNotifyToUser,
}) => {
  const { userId } = store.getters
  const response = await axios.put("/clients/single", {
    id,
    urlKey,
    userId,
    urlShareUserData,
    sendNotifyToUser,
  })
  const { data } = response
  return data
}

/*
  urlKeys, idsのいずれかによりupdateする対象を選択可能
 */
export const putClients = async ({
  ids,
  urlKey,
  urlKeys,
  urlShareUserData,
  sendNotifyToUser,
  serverParams,
}) => {
  const { userId } = store.getters
  const response = await axios.put("/clients/multi", {
    ids,
    userId,
    urlKey,
    urlKeys,
    urlShareUserData,
    sendNotifyToUser,
    serverParams,
  })
  const { data } = response
  return data
}

export const deleteClients = async ({ ids, serverParams }) => {
  const { userId } = store.getters
  const response = await axios.delete("/clients", {
    data: {
      ids,
      userId,
      serverParams,
    },
  })
  const { data } = response
  return data
}

export const getCorporationUsers = async () => {
  const response = await axios.get("/users/list", {
    params: {
      userType: "corporateUser",
    },
  })

  const { data } = response
  return data
}

export const getUserById = async ({ userId }) => {
  const response = await axios.get(`/users/${userId}`)

  const { data } = response
  return data
}

export const getSelfUser = async () => {
  const response = await axios.get("/users/self")
  const { data } = response
  return new User(data)
}

/**
 * @returns {{ manager: User, staffs: EmailSendFrom[] }}
 */
export const getEmailSendFroms = async (input = {}) => {
  const userId = input.userId || store.getters.managerId
  const response = await axios.get(`/users/${userId}/email_send_from`)
  const { data } = response
  return data
}

export const getUserQueueProgresses = async () => {
  const { managerId } = store.getters
  const response = await axios.get(`/users/${managerId}/queue_progress`)
  const { data } = response
  return data
}

export const getUserCsvUploadStatus = async () => {
  const { managerId } = store.getters
  const response = await axios.get(`/users/${managerId}/csv_upload_status`)
  const { data } = response
  return data
}

export const deleteUserCsvUpload = async () => {
  const { managerId } = store.getters
  const response = await axios.delete(`/users/${managerId}/csv_upload`)
  const { data } = response
  return data
}

export const getUserMailDeliveredCount = async ({ managerId }) => {
  const response = await axios.get(`/users/${managerId}/mail_delivered_count`)

  const { data } = response
  return data
}

export const getUserMailDeliveryLimit = async () => {
  const { managerId } = store.getters
  const response = await axios.get(`/users/${managerId}/mail_delivery_limit`)

  const { data } = response
  return data
}

export const getUserMailDeliveryInfo = async ({ managerId }) => {
  const response = await axios.get(`/users/${managerId}/mail_delivery_info`)

  const { data } = response
  return data
}

export const putUserDeliveryLimit = async ({ managerId, limit }) => {
  const response = await axios.put(
    `/admin/users/${managerId}/mail_delivery_limit`,
    {
      limit,
    }
  )

  const { data } = response
  return data
}

export const postUserDeliveryLimitEmail = async ({
  deliveryLimit,
  registeredMail,
  userName,
  contactMail,
  startDate,
}) => {
  const response = await axios.post("/user_email", {
    deliveryLimit,
    registeredMail,
    userName,
    contactMail,
    startDate,
  })

  const { data } = response
  return data
}

export const postClientsEmail = async ({
  mailType,
  subject,
  content,
  ids,
  serverParams,
  prependClientName,
  category,
  customCategory,
  gptUsageCount,
  gptResponse,
}) => {
  const { userId } = store.getters
  const response = await axios.post("/client_email", {
    userId,
    mailType,
    subject,
    content,
    ids,
    serverParams,
    prependClientName,
    category,
    customCategory,
    gptUsageCount,
    gptResponse,
  })

  const { data } = response
  return data
}

export const postClientsEmailSuggest = async ({
  clientId,
  category,
  customCategory,
}) => {
  const response = await axios.post("/client_email/suggest", {
    clientId,
    category,
    customCategory,
  })

  const { data } = response
  console.log(`Prompt: ${data.prompt}`)
  return data
}

export const postUserImage = async ({ userId, imageFile }) => {
  const formData = new FormData()
  formData.append("userId", userId)
  formData.append("profileImage", imageFile)
  const response = await axios.post("/user_image", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  const { data } = response
  return data
}

export const postCosultationEmail = async ({ urlKey, property, type }) => {
  const response = await axios.post("/consultation_email", {
    urlKey,
    property,
    type,
  })

  const { data } = response
  return data
}

export const getNotificationInfo = async () => {
  const response = await axios.get("/notification_info")

  const { data } = response
  return data
}

export const getReinsDataUpdatedDate = async (link) => {
  const response = await axios.head(link)

  const { headers } = response
  return headers
}

let getContractsProcessId = null

export const getPropertiesByLatLng = async ({
  lat,
  lng,
  propertyType,
  scale,
  excludeScale,
  minLatLng,
  maxLatLng,
  sparse,
  filter,
  dataType,
  allModels,
  locations,
}) => {
  const pid = new Date().getTime()
  getContractsProcessId = pid
  const response = await axios.post("/properties/by_lat_lng", {
    lat,
    lng,
    propertyType:
      typeof propertyType === "string" ? [propertyType] : propertyType,
    scale,
    excludeScale,
    minLatLng,
    maxLatLng,
    sparse,
    dataType,
    contractDate: filter.contractDate,
    saleUpdateDate: filter.saleUpdateDate,
    rentUpdateDate: filter.rentUpdateDate,
    rentPriceFrom: filter.rentPrice.from,
    rentPriceTo: filter.rentPrice.to,
    contractPriceFrom: filter.contractPrice.from,
    contractPriceTo: filter.contractPrice.to,
    exclusiveAreaFrom: filter.exclusiveArea.from,
    exclusiveAreaTo: filter.exclusiveArea.to,
    constructionYearmonthFrom: filter.constructionYearmonth.from,
    constructionYearmonthTo: filter.constructionYearmonth.to,
    buildingAreaFrom: filter.buildingArea.from,
    buildingAreaTo: filter.buildingArea.to,
    landAreaFrom: filter.landArea.from,
    landAreaTo: filter.landArea.to,
    specialCondition: Object.entries(filter.specialCondition || {})
      .filter(([k, v]) => !!v)
      .map(([k, v]) => k)
      .join(","),
    brand: Object.entries(filter.brand || {})
      .filter(([k, v]) => !!v)
      .map(([k, v]) => k)
      .join(","),
    walkingTime: filter.walkingTime,
    allModels: allModels,
    locations,
    subPropertyType: filter.subPropertyType,
  })
  if (getContractsProcessId !== pid) {
    return {
      status: "canceled",
      properties: null,
    }
  }
  const { data } = response
  return {
    status: "ok",
    properties: data,
  }
}

/**
 *
 * @param {{propertyIds: string[], propertyType: string}} param
 */
export const getPropertiesByPropertyIds = async ({
  propertyIds,
  propertyType,
}) => {
  const response = await axios.get("/properties/by_property_ids", {
    params: {
      propertyIds: propertyIds.join(","),
      propertyType,
    },
  })
  const { data } = response
  return data
}

export const getPolygons = async ({ lat, lng, polygonType }) => {
  const response = await axios.get("/polygons", {
    params: {
      lat,
      lng,
      polygonType,
    },
  })
  const { data } = response
  return data
}

export const getImages = async ({ masterIds }) => {
  const response = await axios.get("/images", {
    params: {
      ids: masterIds.join(","),
    },
  })
  const { data } = response
  return data
}

// export const retrieveCustomerStripeData = async (userId) => {
//   const response = await axios.get(`/users/${userId}/customer`)
//   const { data } = response
//   return data
// }

export const getRecentPropertiesAmountsByResource = async () => {
  const { data } = await axios.get("/properties/recent_amounts")
  return data
}

export const postSellForm = async ({
  formId,
  urlKey,
  title,
  fields,
  answers,
}) => {
  const { data } = await axios.post("/webhook/typeform", {
    eventId: randomStr(10),
    eventType: "form_response",
    formResponse: {
      formId,
      hidden: {
        env: process.env.NODE_ENV,
        urlKey,
      },
      definition: {
        title,
        fields,
      },
      answers,
    },
  })
  return data
}

export const getOwnerInfo = async ({ urlKey, propertyId, propertyType }) => {
  const { data } = await axios.get("/properties/owner", {
    params: {
      urlKey,
      propertyId,
      propertyType,
    },
  })
  return data
}

export const getOwnerPropertySuggestions = async ({
  urlKey,
  excludePropertyIds,
}) => {
  const { data } = await axios.post("/properties/owner/suggestions", {
    urlKey,
    excludePropertyIds,
  })
  return data.data.map((x) => new Property(x))
}

export const deleteOwnerProperty = async ({ urlKey, propertyId }) => {
  await axios.delete("/properties/owner", {
    data: {
      urlKey,
      propertyId,
    },
  })
}

export const insertOwnerProperty = async ({ urlKey, propertyId }) => {
  await axios.put("/properties/owner", {
    urlKey,
    propertyId,
  })
}

export const postClientEmailRejects = async ({ urlKey, state }) => {
  const { data } = await axios.post(`/user/mail_reject`, {
    urlKey,
    state,
  })
  return data
}

export const getStockLocations = async ({ prefecture, municipality, town }) => {
  const { data } = await axios.get(
    "https://stock.pinrich.com/api/charts/location",
    {
      params: {
        prefecture,
        municipality,
        town,
      },
    }
  )
  return data
}

export const getStockData = async ({
  dataType,
  propertyType,
  townId,
  stationId,
  period,
  municipalityId,
}) => {
  const { data } = await axios.get(
    "https://stock.pinrich.com/api/charts/data",
    {
      params: {
        postedInformation: dataType.toUpperCase(),
        propertyType: propertyType.toUpperCase(),
        townId,
        period,
        stationId,
        municipalityId,
      },
    }
  )
  return data
}
export const getClientMailRejects = async ({ urlKey }) => {
  const { data } = await axios.get(`/user/mail_reject`, {
    params: {
      urlKey,
    },
  })
  return data
}

export const postMailLinkClicked = async ({ mailId, uri, urlKey }) => {
  const { data } = await axios.post(`/clients/mail_link_clicked`, {
    mailId,
    urlKey,
    uri,
  })
  return data
}

export const getLineSettings = async ({ userId }) => {
  const { data } = await axios.get(`/site_settings/line/${userId}`)
  return data
}

export const getDkimStatus = async ({ userId }) => {
  const { data } = await axios.get(`/mail_settings/${userId}/dkim/status`)
  return data
}

export const getDkimRecords = async ({ userId }) => {
  const { data } = await axios.get(`/mail_settings/${userId}/dkim/records`)
  return data
}

export const requestVerify = async ({ userId }) => {
  const { data } = await axios.post(`/mail_settings/${userId}/dkim/verify`)
  return data
}

/**
 *
 * @param {{email:string, userId:string}} param0
 * @returns {Promise<Client | null>}
 */
export const getSellClientByEmail = async ({ email, userId }) => {
  const { data } = await axios.get("/clients/sell_client_by_email", {
    params: { email, userId },
  })
  return data
}

export const fetchPropertyListByMultiSearch = async ({
  filter,
  page,
  perPage,
}) => {
  const { data } = await axios.post("/properties/multi_search", {
    filter,
    page,
    perPage,
  })
  return data
}

export const fetchPropertyListByMultiSearchCount = async ({ filter }) => {
  const { data } = await axios.post("/properties/multi_search_count", {
    filter,
  })
  return data
}

/**
 * @param {string} query - The search query.
 * @param {import("@share/entities/PropertyFilterCondition").PropertyLocationFilterCondition[]} excludedLocations - The locations to be excluded from the search.
 * @return {Promise<Object>} A promise that resolves to the search results.
 */
export const searchPropertyLocations = async (query, excludedLocations) => {
  const { data } = await axios.post("/properties/locations", {
    query,
    excludedLocations,
  })
  return data
}

export const suggestPropertyLocations = async ({ towns, stationIds }) => {
  const { data } = await axios.post("/properties/locations_suggest", {
    towns,
    stationIds,
  })
  return data
}

/**
 *
 * @param {import("@share/entities/PropertyFilterCondition").PropertyLocationFilterCondition[]} locations
 * @returns
 */
export const getPropertyLocationDetail = async (locations) => {
  const { data } = await axios.post("/properties/location_detail", {
    locations,
  })
  return data
}

export const getRecentPropertyCount = async () => {
  const { data } = await axios.get("/properties/count-property")
  return data
}

/**
 *
 * @param {string} urlKey
 * @param {import("@share/entities/PropertyFilterCondition").PropertyFilterCondition} filter
 * @returns
 */
export const logFilterCondition = async (urlKey, filter) => {
  const { data } = await axios.post("/properties/save-activity", {
    urlKey,
    filter,
  })
  return data
}

export const getFormAutoFill = async (urlKey) => {
  const { data } = await axios.get(`/clients/autofill/${urlKey}`)
  return data
}

/**
 *
 * @param {string} mailId
 * @returns {Promise<import("@share/entities/MailHistory").MailHistory>}
 */
export const getMailHistory = async (mailId) => {
  const { data } = await axios.get(`/client_email/content/${mailId}`)
  return data
}

/**
 *
 * @param {string} userId
 * @returns {Promise<import("@share/entities/MailTemplate").MailTemplate[]>}
 */
export const getMailTemplates = async () => {
  const { data } = await axios.get(`/client_email/templates`)
  return data
}

/**
 *
 * @param {{name: string, subject: string, content: string}} param0
 * @returns
 */
export const createMailTemplate = async ({ name, subject, content }) => {
  const { data } = await axios.post(`/client_email/templates`, {
    name,
    subject,
    content,
  })
  return data
}

/**
 *
 * @param {string} name
 * @returns
 */
export const deleteMailTemplate = async (name) => {
  const { data } = await axios.delete(`/client_email/templates/${name}`)
  return data
}

/**
 *
 * @param {{urlKey:string}} param0
 *
 */
export const getPropertyViewHistory = async ({ urlKey }) => {
  const { data } = await axios.get("/properties/view-history", {
    params: { urlKey },
  })
  return data
}

export const postDeviceToken = async ({ deviceToken }) => {
  const { data } = await axios.post(`/update_device__token`, {
    deviceToken,
  })
  return data
}
