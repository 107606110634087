import { postMailLinkClicked } from "@/helpers/api"

export const beforeFromMailToPage = async (to) => {
  const { mailId, urlKey } = to.query

  if (!mailId || !urlKey) {
    return
  }
  await postMailLinkClicked({
    mailId,
    urlKey,
    uri: to.fullPath,
  })
}
