import { LOCAL_STORAGE_KEY } from "@share/const"
import { AppError } from "@share/error"
import axios from "axios"

const baseURL = "/api"

const client = axios.create({
  baseURL,
})

client.interceptors.request.use((request) => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEY.JWT_TOKEN)
  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }
  return request
})

client.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    // FIXME: 一旦この実装だが、ちゃんとうまいこと考えたい。
    //  このレイヤーで処理するのではなく、vuexで言うとactionのレイヤーが適当かも
    //  ただそのためにはAPIの構成をそこそこリファクタしないといけない
    if (error.response) {
      const { status, data } = error.response
      const isRootPath = window.location.pathname === "/"
      const shouldRedirect =
        status === 401 &&
        !isRootPath &&
        data !== "login_fail" &&
        !window.isRedirecting
      if (shouldRedirect) {
        // This attribute is required to avoid duplicate display of window.alert
        window.isRedirecting = true
        window.alert(
          "ログインセッションが無効になりました。再度ログインをお願いします"
        )
        window.location.href = "/"
        return
      }
    }
    /**
     * data ... string | { message: string, data: object }
     */
    if (typeof error.response.data === "string") {
      throw new Error(error.response.data)
    } else if (error.response.data) {
      const { data } = error.response
      throw new AppError(data.message, {
        ...data.data,
      })
    }
    throw error
  }
)

export default client
