import validators from "validator"

// https://github.com/validatorjs/validator.js
export const defaultFeedbacks = {
  isEmail: () => "メールアドレスの形式で入力してください",
  isNotEmpty: () => "入力してください",
  isAscii: () => "半角英数字および記号で入力してください",
  isLength: (options) => {
    const { min, max } = options
    if (!min && !max) {
      return null
    }
    const minString = min && min !== 0 ? `${min}文字以上` : ""
    const maxString = max ? `${max}文字以下` : ""
    return `${minString}${maxString}で入力してください`
  },
  isNaturalNumber: () => "1以上の整数(半角)を入力してください",
  isNumeric: () => "数値以外が含まれています",
  equals: () => "値が一致しません",
  fileExists: () => "選択してください",
  matches: () => "使用できない文字が含まれています",
  isFQDN: () => "有効なドメイン名を入力してください",
}
const validatorsCustomized = Object.create(validators)
Object.assign(validatorsCustomized, {
  isNaturalNumber: (v) => validators.isInt(v, { min: 1 }),
  isNotEmpty: (v) => typeof v === "string" && !validators.isEmpty(v),
  fileExists: (v) => v && v instanceof File,
})

export const validate = (value, ...rules) => {
  let feedback
  for (const rule of rules) {
    const [_rule, ..._options] = Array.isArray(rule) ? rule : [rule]

    const validator = validatorsCustomized[_rule]
    if (!validator) {
      throw new Error(`invalid validator rule: ${_rule}`)
    }
    const ok = validator(value, ..._options)
    if (!ok) {
      feedback = defaultFeedbacks[_rule](..._options) || "入力に誤りがあります"
    }

    if (feedback) {
      break
    }
  }

  return feedback
}

export const validateNaturalNumber = (value) =>
  validate(value, "isNaturalNumber")

export const validateNumeric = (value) => validate(value, "isNumeric")

export const validateNumericRange = (range) => {
  return (value) => {
    const isNumericResult = validate(value, "isNumeric")
    if (isNumericResult) {
      return isNumericResult
    }
    if (!range) {
      return
    }
    const { max, min } = range
    if (value < min || max < value) {
      return `${min}から${max}の間の数で指定してください`
    }
  }
}

export const validateNotEmpty = (string) => validate(string, "isNotEmpty")

export const validateEmail = (email) => validate(email, "isEmail")

export const validateCorporateName = (corporateName) =>
  validate(corporateName, ["isLength", { max: 30 }])

export const validateUserName = (userName) =>
  validate(userName, ["isLength", { max: 30 }])

export const validatePhone = (phone) =>
  validate(phone, ["isLength", { min: 10, max: 11 }]) ||
  validate(phone, ["matches", /^[0-9]{10,11}$/])

export const validatePassword = (password) =>
  validate(password, "isAscii", ["isLength", { min: 8, max: 30 }]) ||
  (password.match(/^[\S]+[\S]+$/) ? null : "空白文字が含まれています")

export const validatePasswordCheck = (password, passwordCheck) =>
  validate(passwordCheck, "isNotEmpty", ["equals", password])

export const validateLocation = (location) =>
  validate(location, "isNotEmpty") ? "エリアや住所を入力してください" : null

export const validateFile = (file) => validate(file, "fileExists")

export const validateArea = (string) => {
  return Number(string) < 0 ? "０以上の数字を入力してください" : null
}

export const validateSubdomain = (
  subdomain,
  base = window.location.hostname
) => {
  const fulldomain = `${subdomain}.${base}`

  return (
    validate(subdomain, ["isLength", { max: 63 }]) ||
    validate(subdomain, ["matches", /^[a-zA-Z0-9-]{0,63}$/]) ||
    validate(fulldomain, ["isFQDN"])
  )
}

// *** examples ***

// validate(値, 条件１, 条件２, [条件３, 条件３のオプション])

// validate(this.value, "isNotEmpty");
// validate(this.email, "isEmail", "isNotEmpty");
// validate(this.email, ["isEmail", { ignore_whitespace: true }], "isNotEmpty");
