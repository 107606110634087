import { defineComponent, PropType, h, computed } from "vue"
import { useValidationTarget } from "@/composables/useValidationTarget"

import FormWrapperPresentation from "@/components/form/FormWrapperPresentation.vue"

let formNumber = 0

/* usage
  {
    components: {
      FormInput: FormWrapper(BaseInput)
    }
  }
*/
const FormWrapper = (WrappedComponent) => {
  return defineComponent({
    name: "FormWrapper",
    inheritAttrs: false,
    emits: ["update:modelValue"],
    props: {
      //
      // WrappedComponent = ValidationTarget 用 props
      //
      name: {
        type: String,
        required: false,
      },
      validator: {
        type: Function as PropType<(value: unknown) => string | null>,
        default: (value: unknown) => null,
      },
      required: {
        type: Boolean,
        required: false,
      },
      modelValue: {
        required: false,
      },
      //
      // WrapperComponent 用 props
      //
      wrapperLabel: {
        type: String,
        required: false,
      },
      requiredIcon: {
        type: Boolean,
        default: false,
      },
      noHeader: {
        type: Boolean,
        default: false,
      },
      //
      //
      //
      /**
       * フィードバックを表示しない
       */
      noValidationFeedback: {
        type: Boolean,
        default: false,
      },
      /**
       * validator ではなくサーバー側でバリデーションを行った結果を表示する場合に使用する
       */
      serverFeedback: {
        type: String,
        required: false,
      },
      /**
       * description slot を使う場合
       */
      describerId: {
        type: String,
        default: () => `FormDescriber__${formNumber++}`,
      },
    },
    setup(props, { attrs, slots, emit, expose }) {
      const { feedback } = useValidationTarget(props)

      const showingFeedback = computed(
        () =>
          !props.noValidationFeedback &&
          (feedback.value || props.serverFeedback)
      )

      /**
       * defineCompose 内で expose しても devtools から確認できないバグ？
       * https://github.com/vuejs/devtools/issues/1878
       */
      expose({ feedback, showingFeedback })

      return () => {
        return h(
          FormWrapperPresentation,
          {
            label: props.wrapperLabel,
            required: props.requiredIcon || undefined,
            noHeader: props.noHeader || undefined,
            style: {
              paddingBottom:
                showingFeedback || slots.description ? "0px" : "18px",
            },
          },
          () => [
            /**
             * input
             */
            h(
              WrappedComponent,
              {
                ...attrs,
                name: props.name,
                required: props.required || undefined,
                /**
                 * v-model ディレクティブは、テンプレートのコンパイル中に modelValue と onUpdate:modelValue プロパティに展開される
                 * https://v3.ja.vuejs.org/guide/render-function.html#v-if-%E3%81%A8-v-for
                 */
                modelValue: props.modelValue,
                "onUpdate:modelValue": (modelValue) =>
                  emit("update:modelValue", modelValue),
                invalid:
                  !!feedback.value || !!props.serverFeedback || undefined,
                "aria-describedby": props.describerId,
              },
              slots.default
            ),
            /**
             * フィードバック
             */
            h(
              "div",
              {
                style: props.noValidationFeedback
                  ? "display: none"
                  : "color: #fc6356; width: 100%; height: 14px; margin-top: 4px; font-size: 14px; line-height: 14px;",
              },
              showingFeedback.value
            ),
            /**
             * 説明
             */
            h(
              "div",
              {
                style: "color: var(--gray); font-size: 14px;",
                id: props.describerId,
              },
              () => [
                slots.description && slots.description({ showingFeedback }),
              ]
            ),
          ]
        )
      }
    },
  })
}
export default FormWrapper
