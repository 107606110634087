const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"

export function randomStr(N) {
  return Array.from(Array(N))
    .map(() => S[Math.floor(Math.random() * S.length)])
    .join("")
}

export function randomTrue(chance) {
  return Math.random() < chance
}
