// インポート
import isMobileJs from "ismobilejs"
const isMobileResult = isMobileJs(window.navigator.userAgent)
const ua = navigator.userAgent.toLowerCase()

export default {
  /**
   * スマホ もしくは タブレットかどうかを判定する
   * スマホ もしくは タブレットだったらtrue、それ以外はfalse
   */
  isMobileAny: function () {
    if (isMobileResult.any) {
      return true
    }
    return false
  },
  isIE11: function () {
    return ua.indexOf("trident/7") > -1
  },
  /**
   * スマホかどうかを判定する
   * スマホだったらtrue、それ以外はfalse
   */
  isMobile: function () {
    if (isMobileResult.phone) {
      return true
    }
    return false
  },
  /*
   * mobileじゃなければPC。タブレットもPCで一旦OK
   */
  isPC: function () {
    return !this.isMobile()
  },
  /**
   * タブレットかどうかを判定する
   * タブレットだったらtrue、それ以外はfalse
   */
  isTablet: function () {
    if (isMobileResult.tablet) {
      return true
    }
    return false
  },
}
