import { ref, onMounted } from "vue"

import { google } from "../lib/google"
import { createContext } from "@/composables/createContext"
import { flexiblePromise } from "@share/lib/flexiblePromise"

export const [provideAutocomplete, injectAutocomplete] =
  createContext<ReturnType<typeof useAutocomplete>>("Autocomplete")

export function useAutocomplete({
  options,
  selectFirstOnEnter = false,
}: {
  options?: google.maps.places.AutocompleteOptions
  selectFirstOnEnter?: boolean
}) {
  const autocompleteRef = ref<HTMLInputElement>()

  const { promise, resolve, reject } =
    flexiblePromise<google.maps.places.Autocomplete>()

  onMounted(async () => {
    const input = autocompleteRef.value
    if (input) {
      try {
        const autocomplete = new (await google).maps.places.Autocomplete(
          input,
          options
        )

        if (selectFirstOnEnter) {
          enableEnterKey(input)
        }

        resolve(autocomplete)
      } catch (error) {
        reject(error)
      }
    }
  })

  return {
    autocompleteRef,
    autocompletePromise: promise,
  }
}

// 以下を参考に作成
// https://stackoverflow.com/questions/7865446/google-maps-places-api-v3-autocomplete-select-first-option-on-enter/11703018#11703018
//
// google が input.addEventListener("keydown", ...) を１度だけ呼び出すので
// そこでフックを仕掛ける
function enableEnterKey(input: HTMLInputElement) {
  input.addEventListener = new Proxy(input.addEventListener, {
    apply(target, thisArg, args) {
      const [type, listener] = args

      // keydown 以外はそのまま実行する
      if (type !== "keydown") {
        target.apply(thisArg, [type, listener])
        return true
      }

      function _listener(event: Event) {
        // type === "keydown" のときはかならず Event は KeyboardEvent だが、
        // listener.apply(input, [arrowDownEvent]) ではそこまで型を推論できずにエラーになってしまう
        // この型エラーの回避のために必要
        function isKeyboardEvent(event: Event): event is KeyboardEvent {
          return true
        }
        if (isKeyboardEvent(event)) {
          const isNotSelected =
            document.getElementsByClassName("pac-item-selected").length === 0
          const shouldSimulateArrowDown =
            event.key === "Enter" && isNotSelected && !event.isComposing
          if (shouldSimulateArrowDown) {
            const arrowDownEvent = new KeyboardEvent("keydown", {
              key: "ArrowDown",
              code: "ArrowDown",
              keyCode: 40,
            })
            listener.apply(input, [arrowDownEvent])
          }
          listener.apply(input, [event])
        }
      }

      target.apply(thisArg, [type, _listener])
    },
  })
}
