<template>
  <label
    :for="id"
    class="BaseInput__base"
    :style="{ marginBottom: 0, fontSize }"
  >
    <span v-if="label" class="BaseInput__label"
      >{{ label }}{{ required ? "*" : "" }}</span
    >
    <slot>
      <input
        :id="id"
        class="BaseInput__input"
        :type="type"
        :value="modelValue"
        :min="min"
        :max="max"
        @input="$emit('update:modelValue', $event.target.value)"
        placeholder=" "
        :autocomplete="autocomplete"
        :disabled="disabled || undefined"
        :data-invalid="invalid || undefined"
        :data-large="large || undefined"
        :data-searchIcon="searchIcon || undefined"
        :step="step"
      />
    </slot>
  </label>
</template>

<script lang="ts">
import { defineComponent } from "vue"

let idNumber = 0

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: "16px",
    },
    id: {
      type: String,
      default: () => `BaseInput__${idNumber++}`,
    },
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: [String, Number],
      required: false,
    },
    min: {
      type: [String, Number],
      required: false,
    },
    max: {
      type: [String, Number],
      required: false,
    },
    step: {
      type: [String, Number],
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    invalid: {
      type: Boolean,
      required: false,
    },
    large: {
      type: Boolean,
      required: false,
    },
    searchIcon: {
      type: Boolean,
      required: false,
    },
  },
})
</script>
