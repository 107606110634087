<template>
  <teleport
    v-if="isLoaded"
    :to="'#teleport-' + (drawer ? 'drawer' : to)"
    ref="portal"
  >
    <div class="bg" :class="color" ref="bg">
      <div
        class="overlay"
        :class="{ top, center, drawer }"
        @mousedown.stop.self="$emit('close')"
      >
        <button
          v-if="closeButton"
          class="close"
          @mousedown.stop.self="$emit('close')"
          ref="close"
        ></button>
        <slot />
      </div>
    </div>
  </teleport>
</template>

<script>
import { backfaceFixed } from "@/helpers/backfaceFixed"

export default {
  emits: ["close"],
  props: {
    color: {
      type: String,
      default: "transparent",
      validator(v) {
        return ["dark", "light", "transparent"].includes(v)
      },
    },
    top: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    /**
     * @deprecated
     * portal-target が modal, drawer, image の３通りになったので非推奨
     * to を使う
     */
    drawer: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: "modal",
      validator(v) {
        return ["modal", "drawer", "image", "global"].includes(v)
      },
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
    closeButtonInside: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoaded: false,
    }
  },
  methods: {
    handleResize() {
      clearTimeout(this.timerId)
      this.timerId = setTimeout(() => {
        if (!this.$refs.bg) {
          return
        }
        this.$refs.bg.style.height = window.innerHeight + "px"
      }, 100)
    },
  },
  async mounted() {
    this.timerId = null
    this.isLoaded = true
    this.handleResize()
    window.addEventListener("resize", this.handleResize)
    backfaceFixed(true)
    await this.$nextTick()
    this.$refs.close && this.$refs.close.focus()
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize)
    backfaceFixed(false)
  },
  activated() {
    backfaceFixed(true)
  },
  deactivated() {
    backfaceFixed(false)
  },
}
</script>

<style lang="scss">
.bg {
  position: fixed;
  top: 0;
  left: 0;
  // FIXME: need total design of z-index
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.overlay {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  &.top {
    justify-content: center;
    align-items: flex-start;
    padding-top: 32px;
  }
  &.center {
    justify-content: center;
    align-items: center;
  }
  &.drawer {
    justify-content: flex-end;
    align-items: stretch;
  }
}
.dark {
  background-color: rgba(0, 0, 0, 0.6);
}
.light {
  background-color: rgba(0, 0, 0, 0.4);
}
.close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 48px;
  height: 48px;
  background: url("/assets/images/icons/cancel_white.svg") no-repeat center;
  background-color: transparent;
  background-size: 50%;
  border: none;
  cursor: pointer;
  padding: 0;
  appearance: none;
  &:hover {
    background-color: rgba($color: white, $alpha: 0.1);
  }
}
</style>
