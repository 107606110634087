/**
 * 小数点以下の桁数を指定して四捨五入
 * @param value 四捨五入対象数字
 * @param places 小数点以下桁数(マイナスの場合、整数|places|の位まで四捨五入)
 */
export const round = (value, places) => {
  const base = Math.pow(10, places)
  return Math.round(value * base) / base
}

export const getRate = (numer, denom) => {
  return denom == 0 ? 0 : round((numer * 100) / denom, 2)
}
