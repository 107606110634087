/**
 * ユーザーの登録、取得、更新、退会などを扱う。
 * TODO: vuex の global store で定義されている registerForm をここに移動する。
 */

import axios from "@/helpers/axios"
import { User, UserToBeRegistered } from "@share/entities/User"

/**
 * ユーザー登録について
 *
 * - 営業が、法人ユーザー管理画面で新規登録リンクを発行する。
 *   - `/auth/register?key=REGISTER_KEY`
 *   - 情報が空っぽの cognito ユーザーを作成し、その userId を REGISTER_KEY とする
 * - 営業が、新規登録するユーザーに登録リンクを送信する。
 * - ユーザーが、登録リンクから登録画面に遷移する。
 * - ユーザーが、ユーザー情報を入力して登録する。
 * - PinRich が、認証メールを送信する。
 * - ユーザーが、認証メールを開封し、利用を開始する。
 */

/**
 * ユーザーにピンリッチへの登録リンクを載せたメールを送信する
 * @param email 招待するユーザーのメールアドレス
 * @param managerId 担当者作成のリンクを作る場合は、管理者の managerId が必要
 */
export async function inviteUser(email: string, managerId?: string) {
  // adminCreateUser で cognito ユーザーを作成する
  await axios.post<string>(`/auth/create_user_to_be_registered`, {
    email,
    managerId,
  })
}

export async function registerUser(
  userId: string,
  attrs: {
    password: string
    corporateName: string
    userName: string
    phoneNumber: string
    userIcon: string
    iconName: string
    subdomain: string
  }
) {
  const { data: user } = await axios.post<User>(`/auth/register`, {
    userId,
    ...attrs,
  })
  return user
}

/**
 * 登録しようとしている userId の有効性をチェックする。
 * TODO: 実装
 */
export async function verifyUserToBeRegistered(
  userToBeRegistered: UserToBeRegistered
): Promise<Result<UserToBeRegistered>> {
  return {
    ok: true,
    data: userToBeRegistered,
  }
}

type Result<T> =
  | {
      ok: false
    }
  | {
      ok: true
      data: T
    }
