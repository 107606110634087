<template>
  <div class="BaseSelectRange__container">
    <BaseSelect
      :label="label"
      :modelValue="modelValue.from"
      @update:modelValue="
        (option) => changeOption(option, 'from', defaultValue)
      "
      :options="options.from"
      class="BaseSelectRange__select"
    />
    <!-- @change="emitLog($event, dotNotations['exclusiveAreaFrom'])" -->
    <span class="BaseSelectRange__tilda">〜</span>
    <BaseSelect
      :modelValue="modelValue.to"
      @update:modelValue="(option) => changeOption(option, 'to', defaultValue)"
      :options="options.to"
      class="BaseSelectRange__select"
    />
    <!-- @change="emitLog($event, dotNotations['exclusiveAreaTo'])" -->
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect.vue"

export default {
  components: {
    BaseSelect,
  },

  emits: ["update:modelValue"],
  props: {
    modelValue: {
      validator: hasPropertyFromAndTo,
    },
    options: {
      validator: hasPropertyFromAndTo,
    },
    label: { type: String, required: false },
    defaultValue: {
      type: Object,
      default: () => ({ from: "", to: "" }),
    },
  },
  methods: {
    changeOption(option, fromOrTo, defaultValue) {
      const range = createRangeKeepingRelation({
        oldRange: this.modelValue,
        newValue: option,
        fromOrTo,
        defaultValue,
      })
      this.$emit("update:modelValue", range)
    },
  },
}

/**
 * @params object { from: string, to: string }
 */
function hasPropertyFromAndTo(object) {
  const hasProperty = (key) => Object.prototype.hasOwnProperty.call(object, key)
  return ["from", "to"].every(hasProperty)
}

// from < to の関係を維持し
function createRangeKeepingRelation({
  oldRange,
  newValue,
  fromOrTo,
  defaultValue,
  canBeSame = false,
}) {
  const range = {
    ...oldRange,
    [fromOrTo]: newValue,
  }

  if (!range.from || !range.to) return range

  const isValidRange = canBeSame
    ? Number(range.from) <= Number(range.to)
    : Number(range.from) < Number(range.to)

  if (!isValidRange) {
    const targetSelector = fromOrTo === "from" ? "to" : "from"
    range[targetSelector] = defaultValue[targetSelector]
  }

  return range
}
</script>

<style lang="scss" scoped>
.BaseSelectRange__container {
  display: flex;
  align-items: flex-end;
}
.BaseSelectRange__select {
  width: 130px;
}
.BaseSelectRange__tilda {
  display: inline-block;
  padding: 0 10px;
  margin-bottom: 12px;
  font-size: 16px;
  color: $gray;
  font-weight: bold;
}
</style>
