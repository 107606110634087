import { getPropertyLocationDetail } from "@/helpers/api"
import { Line, Municipality, PrefixTown, School } from "@share/entities/Address"
import {
  PropertyFilterLocationType,
  PropertyLocationFilterCondition,
} from "@share/entities/PropertyFilterCondition"
import { Station } from "@share/entities/Station"
import { defineStore } from "pinia"
import { reactive } from "vue"

type Cache = {
  [key: `TOWN-${number}`]: PrefixTown
  [key: `MUNICIPALITY-${number}`]: Municipality
  [key: `STATION-${number}`]: Station
  [key: `SCHOOL-${number}`]: School
  [key: `LINE-${number}`]: Line
}

const useMultiSearchLocationInfo = defineStore(
  "multiSearchLocationInfo",
  () => {
    const cache = reactive<Cache>({})

    async function fetchLocationInfos(
      locations: PropertyLocationFilterCondition[]
    ) {
      const missing = locations.filter(
        ({ locationType, locationId }) =>
          !cache[`${locationType}-${locationId}`]
      )
      if (!missing.length) return
      console.log(await getPropertyLocationDetail(missing))
      for (const {
        locationId,
        locationType,
        ...detail
      } of await getPropertyLocationDetail(missing)) {
        cache[`${locationType}-${locationId}`] = detail
      }
    }

    async function setLocationInfo<T extends PropertyFilterLocationType>(
      locationType: T,
      locationId: number,
      detail: Cache[`${T}-${number}`]
    ) {
      const key = `${locationType}-${locationId}`
      cache[key] = detail
    }

    return {
      fetchLocationInfos,
      setLocationInfo,
      cache,
    }
  }
)

export default useMultiSearchLocationInfo
