import axios from "@/helpers/axios"
import {
  SiteSettings,
  stringValueKeys,
  imageFileKeys,
  booleanValueKeys,
} from "@share/entities/SiteSettings"

const utils = {
  /**
   * @param {SiteSettings} siteSettings
   * @returns {FormData} FormData
   */
  toFormData(siteSettings) {
    const formData = new FormData()
    ;[...stringValueKeys, ...imageFileKeys, ...booleanValueKeys].forEach(
      (key) => {
        const value = siteSettings[key]
        if (value !== undefined) {
          formData.append(key, value)
        }
      }
    )
    return formData
  },
}

export const get = async (userId) => {
  const { data } = await axios.get(`site_settings/${userId}`)
  return new SiteSettings(data)
}

/**
 * update
 * @param {string} userId
 * @param {Partial<SiteSettings>} siteSettings
 * @return {Promise<SiteSettings>}
 */
export const update = async (userId, siteSettings) => {
  const { data } = await axios.post(
    `/site_settings/${userId}`,
    utils.toFormData(siteSettings),
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )
  return new SiteSettings(data)
}
