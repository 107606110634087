<template>
  <div class="BaseSelect__container">
    <span v-if="label" class="BaseSelect__label"
      >{{ label }}{{ required ? "*" : "" }}</span
    >
    <select
      class="BaseSelect__select BaseSelect__select--reset"
      :class="{ 'has-img': label === '担当者' }"
      :id="id"
      :name="id"
      :value="modelValue"
      @change="$emit('update:modelValue', $event.target.value)"
      :data-selected="isSelected || undefined"
    >
      <option
        v-if="options.findIndex((x) => x.value == modelValue) === -1"
        disabled
        selected
      >
        Select
      </option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.text }}
      </option>
    </select>
    <div v-if="label && label == '担当者'">
      <img width="24" height="24" :src="getEmailSendFromPhotoUrl(modelValue)" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
const hasStringPropertyFromAndTo = (object) =>
  typeof object.text === "string" && typeof object.value === "string"

export default {
  emits: ["update:modelValue"],
  model: {
    prop: "option",
    event: "change",
  },
  props: {
    required: {
      type: Boolean,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    modelValue: {
      required: true,
    },
    options: {
      validator: (options) =>
        Array.isArray(options) && options.every(hasStringPropertyFromAndTo),
    },
  },
  computed: {
    isSelected() {
      return !!this.modelValue.value
    },
    ...mapGetters(["emailSendFromDropDownList"]),
  },
  methods: {
    getEmailSendFromPhotoUrl(id) {
      return this.emailSendFromDropDownList.find(
        (x) => x.value === id.toString()
      )?.photoUrl
    },
  },
}
</script>

<style lang="scss" scoped>
.BaseSelect__container {
  position: relative;
}
.BaseSelect__select--reset {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &::-ms-expand {
    /* IE対策 セレクトボックス右の矢印ボタンを消す */
    display: none;
  }
}
.BaseSelect__select {
  width: 100%;
  height: calc(16px + 10px * 2);
  padding-left: 10px;
  padding-right: calc(1.5em + 0.75rem);

  background-color: white;
  background-image: url(/assets/images/icons/ud_small_triangle.svg);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: 9px;

  border: 1px solid var(--light-gray);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  color: $dark-gray;

  &:focus {
    border: 1px solid $dark-gray;
    outline: none;
    box-shadow: 0 0 0 3px rgba($dark-gray, 0.25);
  }
  &[data-selected] {
    border: 1px solid $dark-gray;
  }
}
.BaseSelect__label {
  display: inline-block;
  padding: 0 4px;
  color: $dark-gray;
  font-size: 0.75em;
  font-weight: bold;
  margin-bottom: 4px;
}

.BaseSelect__select.has-img {
  padding-left: 40px;
}

.BaseSelect__container img {
  position: absolute;
  left: 0.5rem;
  top: 70%;
  transform: translateY(-50%);
}
</style>
