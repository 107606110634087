/* 物件情報を管理するモジュール */
import { usePropertyList } from "@/composables/propertyList/usePropertyList"
import {
  getPropertiesByLatLng,
  getPropertiesByPropertyIds,
} from "@/helpers/api"
import { Logger } from "@/helpers/logger"
import { compareFuncs } from "@/helpers/sort"
import {
  DELETE_PROPERTIES,
  SAVE_PROPERTIES,
  SAVE_URL_KEY,
  SELECT_BUILDING,
  SELECT_PROPERTY,
  SET_BOUNDS,
} from "@/mutationNames"
import { sortPropertiesGroupByBuilding } from "@share/entities/Building"
import { Property } from "@share/entities/Property"
import { filterFunc } from "@share/filter"
import _, { isEqual } from "lodash"
const logger = Logger("store.properties")

export default {
  namespaced: true,
  state: {
    propertiesFromApi: {
      sale: [],
      contract: [],
      rent: [],
    },
    selectedPropertyId: "",
    selectedBuildingLatLng: null,
    loadPropertiesPid: null,
    minLatLng: null,
    maxLatLng: null,
    sparse: false,
    lastScale: 0,
  },
  mutations: {
    [SAVE_PROPERTIES](
      state,
      { lastScale, properties, removeOldResult = true }
    ) {
      state.lastScale = lastScale
      const { sale, contract, rent } = properties
      const oldSalePropertyIds = new Set(
        state.propertiesFromApi.sale.map((p) => p.id)
      )
      const oldContractPropertyIds = new Set(
        state.propertiesFromApi.contract.map((p) => p.id)
      )
      const oldRentPropertyIds = new Set(
        state.propertiesFromApi.rent.map((p) => p.id)
      )
      const newSalePropertyIds = new Set(sale.map((p) => p.id))
      const newContractPropertyIds = new Set(contract.map((p) => p.id))
      const newRentPropertyIds = new Set(rent.map((p) => p.id))

      // Avoid recreating property so that images can be cached since it uses WeakMap which references
      // sale, contract properties by its object, if new object is created, the cache will be invalidated
      state.propertiesFromApi = Object.freeze({
        sale: sale
          .filter((p) => !oldSalePropertyIds.has(p.id))
          .map((p) => new Property(p))
          .concat(
            state.propertiesFromApi.sale.filter((p) =>
              removeOldResult ? newSalePropertyIds.has(p.id) : true
            )
          ),
        contract: contract
          .filter((p) => !oldContractPropertyIds.has(p.id))
          .map((p) => new Property(p))
          .concat(
            state.propertiesFromApi.contract.filter((p) =>
              removeOldResult ? newContractPropertyIds.has(p.id) : true
            )
          ),
        rent: rent
          .filter((p) => !oldRentPropertyIds.has(p.id))
          .map((p) => new Property(p))
          .concat(
            state.propertiesFromApi.rent.filter((p) =>
              removeOldResult ? newRentPropertyIds.has(p.id) : true
            )
          ),
      })
    },
    [SELECT_BUILDING](state, latLng) {
      state.selectedBuildingLatLng = latLng ? latLng : null
    },
    [DELETE_PROPERTIES](state) {
      state.propertiesFromApi = {
        sale: [],
        contract: [],
        rent: [],
      }
    },
    [SET_BOUNDS](state, { minLatLng, maxLatLng, sparse }) {
      state.minLatLng = minLatLng
      state.maxLatLng = maxLatLng
      state.sparse = sparse
    },
    [SELECT_PROPERTY](state, id) {
      state.selectedPropertyId = id ? Number(id) : null
    },
  },
  actions: {
    setBounds({ commit }, { minLatLng, maxLatLng, sparse = true }) {
      commit(SET_BOUNDS, { minLatLng, maxLatLng, sparse })
    },
    async search(
      { dispatch, rootState, commit },
      {
        location,
        position,
        propertyType,
        urlKey,
        deleteProperties = true,
        postActionLog = true,
        keepSateiPosition = false,
      }
    ) {
      const sateiTarget = rootState.sateiTarget

      if (!keepSateiPosition) {
        dispatch("setBounds", { minLatLng: null, maxLatLng: null })
      }

      if (urlKey) {
        commit(SAVE_URL_KEY, urlKey, { root: true })
        dispatch("saveGuestUrlKey", null, { root: true })
      }

      if (
        sateiTarget.location !== location ||
        sateiTarget.position.lat !== position.lat ||
        sateiTarget.position.lng !== position.lng ||
        !isEqual(new Set(sateiTarget.propertyType), new Set(propertyType))
      ) {
        logger.info("sateiTarget change detected")
        dispatch(
          "updateSateiInfo",
          {
            location,
            position: keepSateiPosition ? sateiTarget.position : position,
            mapCenter: position,
            propertyType,
            deleteProperties,
            postActionLog,
            keepSateiPosition,
          },
          { root: true }
        )
      } else {
        dispatch("updateMapCenter", { position, zoom: 16 }, { root: true })
      }
      dispatch("initializeFilter", null, { root: true })
      dispatch("initializePolygons", null, { root: true })
      dispatch("loadProperties", { deleteProperties })
    },
    async resetProperties({ commit }, { property }) {
      const properties = {
        sale: [],
        contract: [],
        rent: [],
      }
      properties[property.dataType] = [property]
      commit(SAVE_PROPERTIES, {
        lastScale: 0,
        properties,
        removeOldResult: true,
      })
    },
    async loadProperties(
      { commit, dispatch, rootState, state },
      { deleteProperties = true } = { deleteProperties: true }
    ) {
      const mapPosition = rootState.mapCenter.position
      const { position: sateiPosition, propertyType } = rootState.sateiTarget
      const position = {
        lat: mapPosition.lat || sateiPosition.lat,
        lng: mapPosition.lng || sateiPosition.lng,
      }
      if (!position.lat || !position.lng || !propertyType?.length) {
        return
      }

      const pid = new Date().getTime()
      state.loadPropertiesPid = pid

      const propertyList = usePropertyList()

      const loadPropertiesProcess = async () => {
        if (state.minLatLng) {
          const { status, properties } = await getPropertiesByLatLng({
            ...position,
            propertyType,
            minLatLng: state.minLatLng,
            maxLatLng: state.maxLatLng,
            sparse: state.sparse,
            filter: rootState.filter,
            locations: propertyList.filter.locations,
          })
          if (state.loadPropertiesPid != pid) return
          if (status === "ok") {
            commit(SAVE_PROPERTIES, {
              lastScale: 1,
              properties,
              removeOldResult: deleteProperties,
            })
          }
          return
        }
        if (state.loadPropertiesPid != pid) return
        const { status, properties } = await getPropertiesByLatLng({
          ...position,
          propertyType,
          filter: rootState.filter,
          dataType: rootState.selectedContractType,
          locations: propertyList.filter.locations,
        })
        if (state.loadPropertiesPid != pid) return
        if (status === "ok") {
          commit(SAVE_PROPERTIES, {
            lastScale: 1,
            properties,
            removeOldResult: true,
          })
        }
      }
      dispatch("process/run", loadPropertiesProcess, { root: true })
    },
    async loadPropertiesByPropertyIds({ commit, dispatch }, propertyIdsByType) {
      if (!propertyIdsByType) {
        return
      }

      const loadPropertiesProcess = async () => {
        const sale = []
        const contract = []
        const rent = []
        await Promise.all(
          Object.entries(propertyIdsByType).map(
            async ([propertyType, propertyIds]) => {
              const properties = await getPropertiesByPropertyIds({
                propertyIds: propertyIds.filter((x) => !!x),
                propertyType,
              })
              sale.push(...properties.filter((x) => x.dataType === "sale"))
              contract.push(
                ...properties.filter((x) => x.dataType === "contract")
              )
              rent.push(...properties.filter((x) => x.dataType === "rent"))
            }
          )
        )
        commit(SAVE_PROPERTIES, {
          lastScale: 0,
          properties: { sale, contract, rent },
        })
      }
      await dispatch("process/run", loadPropertiesProcess, { root: true })
    },
    selectProperty: ({ commit, state, getters }, property) => {
      commit(SELECT_PROPERTY, property && property.id)
      commit(SELECT_BUILDING, property && property.latLng)
    },
    async loadPropertiesOnFilterChange(
      { commit, dispatch, rootState, state },
      { propertyType }
    ) {
      const sateiTarget = rootState.sateiTarget
      dispatch(
        "updateSateiInfo",
        {
          location: sateiTarget.location,
          position: sateiTarget.position,
          mapCenter: rootState.mapCenter.position,
          propertyType: propertyType || sateiTarget.propertyType,
          deleteProperties: true,
          postActionLog: false,
          keepSateiPosition: true,
        },
        { root: true }
      )

      const mapPosition = rootState.mapCenter.position
      const { position: sateiPosition } = rootState.sateiTarget
      const position = {
        lat: mapPosition.lat || sateiPosition.lat,
        lng: mapPosition.lng || sateiPosition.lng,
      }
      const propertyList = usePropertyList()

      const loadPropertiesProcess = async () => {
        const { status, properties } = await getPropertiesByLatLng({
          ...position,
          scale: state.lastScale,
          propertyType: rootState.sateiTarget.propertyType,
          minLatLng: state.minLatLng,
          maxLatLng: state.maxLatLng,
          sparse: state.sparse,
          filter: rootState.filter,
          dataType: rootState.selectedContractType,
          locations: propertyList.temporaryLocations,
        })

        if (status === "ok") {
          commit(SAVE_PROPERTIES, { lastScale: state.lastScale, properties })
          dispatch("selectProperty", null)
          dispatch("updateFilterLocationName", null, { root: true })
        }
      }
      await dispatch("process/run", loadPropertiesProcess, { root: true })
    },
    async loadPropertiesOfPosition(
      { commit, dispatch, rootState, state },
      { position, scale, propertyType, loadBuilding }
    ) {
      const type = propertyType || rootState.sateiTarget.propertyType
      let filter = _.cloneDeep(rootState.filter)
      if (loadBuilding) {
        filter.contractDate = "10"
        filter.saleUpdateDate = "90"
        filter.rentUpdateDate = "14"
      }
      const propertyList = usePropertyList()

      const loadPropertiesProcess = async () => {
        const { status, properties } = await getPropertiesByLatLng({
          ...position,
          scale,
          propertyType: type,
          minLatLng: loadBuilding ? undefined : state.minLatLng,
          maxLatLng: loadBuilding ? undefined : state.maxLatLng,
          sparse: loadBuilding ? undefined : state.sparse,
          filter,
          dataType: rootState.selectedContractType,
          locations: propertyList.temporaryLocations,
          // allModels: loadBuilding,
        })

        if (status === "ok") {
          commit(SAVE_PROPERTIES, {
            lastScale: !loadBuilding ? scale : Math.max(scale, state.lastScale),
            properties,
            removeOldResult: !loadBuilding,
          })
          dispatch("updateFilterLocationName", null, { root: true })
        }
      }
      await dispatch("process/run", loadPropertiesProcess, { root: true })
    },
    deleteProperties({ commit }) {
      commit(DELETE_PROPERTIES)
    },
    saveUrlKey({ commit, dispatch }, { urlKey }) {
      if (urlKey) {
        commit(SAVE_URL_KEY, urlKey, { root: true })
        dispatch("saveGuestUrlKey", null, { root: true })
      }
    },
  },
  getters: {
    // sale と contract は id の体系が異なり、
    // 重複することがあるので、安易にまぜてはいけない。
    // saleProperties と contractProperties で分けたものを、
    // state に応じて properties で切り替えたりまぜたりする。
    saleProperties: (state) => {
      const { propertiesFromApi } = state
      const saleProperties = propertiesFromApi["sale"]
      return saleProperties
    },
    contractProperties: (state) => {
      const { propertiesFromApi } = state
      const contractProperties = propertiesFromApi["contract"]
      return contractProperties
    },
    rentProperties: (state) => {
      const { propertiesFromApi } = state
      const rentProperties = propertiesFromApi["rent"]
      return rentProperties
    },
    activeProperties(state, getters, rootState) {
      const filteredResult = filterFunc(
        getters.filteredPropertiesOfDataType,
        rootState.activeFilter
      )
      const { properties } = filteredResult
      const compareFunc =
        compareFuncs[rootState.sortType] ||
        (() => {
          logger.error("unknown sortType: ", rootState.sortType)
          return properties
        })
      return properties.sort(compareFunc)
    },
    sortedProperties(state, getters, rootState) {
      const properties = getters.filteredPropertiesOfDataType
      const compareFunc =
        compareFuncs[rootState.sortType] ||
        (() => {
          logger.error("unknown sortType: ", rootState.sortType)
          return properties
        })
      return properties.sort(compareFunc)
    },
    filteredPropertiesOfDataType(state, getters, rootState) {
      const dataType = rootState.selectedContractType
      if (dataType === "all") {
        return getters.filteredProperties
      }
      return getters.filteredProperties.filter((p) => p.dataType === dataType)
    },
    filteredProperties(state, getters, rootState) {
      // Filter has moved to backend so this is not needed anymore
      // logger.time("filteredProperties")
      // const result = filterFunc(getters.properties, rootState.filter)
      // const { properties, filterDetails } = result
      // logger.info(filterDetails)
      // logger.timeEnd("filteredProperties")
      // return properties
      return getters.properties
    },
    selectedBuilding: (state, getters) => {
      return getters.buildingsByLatLng[state.selectedBuildingLatLng]
    },
    selectedProperty: (state, getters) => {
      return getters.propertiesById[state.selectedPropertyId]
    },
    propertiesById: (state, getters) => {
      const { properties } = getters
      return _.keyBy(properties, "id")
    },
    buildingsByLatLng: (state, getters) => {
      return _.keyBy(getters.sortedBuildings, "latLng")
    },
    sortedBuildings(state, getters) {
      return Object.freeze(sortPropertiesGroupByBuilding(getters.properties))
    },
    getBuilding: (state, getters) => (latLng) => {
      return getters.sortedBuildings.find(
        (building) => building.latLng === latLng
      )
    },
    properties: (state, getters) => {
      const { saleProperties, contractProperties, rentProperties } = getters
      const allProperties = [
        ...saleProperties,
        ...contractProperties,
        ...rentProperties,
      ]
      return allProperties
    },
    activeLatLngSet(state, getters) {
      return getters.activeProperties.reduce(
        (set, p) => set.add(`${p.lat}-${p.lng}`),
        new Set()
      )
    },
    latestBuildingUpdatedAtLabel(state, getters) {
      if (!getters.sortedBuildings || getters.sortedBuildings.length === 0) {
        return ""
      }
      const latestBuilding = getters.sortedBuildings[0]
      const [year, month, day] = new Date(latestBuilding[0].updatedAt)
        .toLocaleDateString()
        .split("/")
      const updatedAtLabel =
        year && month && day && `${year}年${month}月${day}日`
      return updatedAtLabel
    },
  },
}
