export class Filter {
  constructor(
    public contractDate: "all" | "1" | "3" | "5" | "10" = "3",
    public saleUpdateDate: "1" | "7" | "30" = "30",
    public rentUpdateDate: "1" | "3" | "7" | "14" | "30" = "3",
    public ownerChange: "all" | "ownerChange" | "notOwnerChange" = "all",
    public contractUnitPrice: FilterFromTo = {
      from: "0",
      to: "99999999999",
    },
    public rentPrice: FilterFromTo = {
      from: "0",
      to: "99999999999",
    },
    public contractPrice: FilterFromTo = {
      from: "0",
      to: "99999999999",
    },
    public exclusiveArea: FilterFromTo = {
      from: "0",
      to: "99999999999",
    },
    public buildingArea: FilterFromTo = {
      from: "0",
      to: "99999999999",
    },
    public landArea: FilterFromTo = {
      from: "0",
      to: "99999999999",
    },
    public floor: FilterFromTo = {
      from: "0",
      to: "99999999999",
    },
    public floorPlanNumberOfRooms: number[] = [],
    public aboveGroundFloorLevels: FilterFromTo = {
      from: "0",
      to: "99999999999",
    },
    public constructionYearmonth: FilterFromTo = {
      from: "",
      to: "",
    },
    public filterPrice: FilterFromTo = {
      from: "0",
      to: "99999999999",
    },
    public specialCondition: Record<string, boolean> = {},
    public brand: Record<string, boolean> = {},
    public walkingTime: "1" | "5" | "7" | "15" | "20" | "" = "",

    public filterPropertyType: SubPropertyType[] = []
  ) {}
}
