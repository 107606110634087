import * as filterOptions from "@share/entities/filterOptions"

type Option = {
  label: string
  value: string
}

export default function useMultiSearchFilterOptions() {
  const options: Record<string, Option[]> = Object.fromEntries(
    Object.entries(filterOptions.options).map(([k, v]) => [
      k,
      v
        .filter((x) => x.value !== "all")
        .map((x) => ({ label: x.text, value: x.value })),
    ])
  )

  const defaultDateValue = {
    saleUpdateDate: filterOptions.defaultValue.saleUpdateDate ?? "90",
    contractDate: filterOptions.defaultValue.contractDate ?? "3",
    rentUpdateDate: filterOptions.defaultValue.rentUpdateDate ?? "14",
  }

  const defaultFilterValue = {
    rentPrice: {
      from: "0",
      to: "99999999999",
    },
    contractPrice: {
      from: "0",
      to: "99999999999",
    },
    exclusiveArea: {
      from: "0",
      to: "99999999999",
    },
    buildingArea: {
      from: "0",
      to: "99999999999",
    },
    landArea: {
      from: "0",
      to: "99999999999",
    },
    constructionYearmonth: {
      from: "",
      to: "",
    },
  }

  return {
    options,
    defaultValue: filterOptions.defaultValue,
    defaultDateValue,
    defaultFilterValue,
  }
}
