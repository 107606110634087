import {
  fetchPropertyListByMultiSearch,
  fetchPropertyListByMultiSearchCount,
} from "@/helpers/api"
import { Property } from "@share/entities/Property"
import {
  PropertyLocationFilterCondition,
  type PropertyFilterCondition,
} from "@share/entities/PropertyFilterCondition"
import { randomStr } from "@share/random"
import { useLocalStorage } from "@vueuse/core"
import { defineStore } from "pinia"
import { ref } from "vue"
import useMultiSearchFilterOptions from "../MultiSearch/useMultiSearchFilterOptions"
import useMultiSearchLocationInfo from "../MultiSearch/useMultiSearchLocationInfo"

export const usePropertyList = defineStore("propertyList", () => {
  const locationInfo = useMultiSearchLocationInfo()

  const filterOptions = useMultiSearchFilterOptions()

  const properties = ref<Property[]>()
  const totalRecords = ref()
  const currentPage = ref(1)
  const perPage = 30
  const loading = ref(false)
  const finished = ref(false)
  const requestId = ref("")

  const loadingPromiseResolver = ref<() => void>()
  const loadingPromise = ref(Promise.resolve())

  const temporaryLocations = ref<PropertyLocationFilterCondition[]>()

  const filter = useLocalStorage<
    PropertyFilterCondition & { isDefaultValue?: boolean }
  >(
    "propertyFilter",
    {
      locations: [],
      dataType: "sale",
      propertyType: "apartment",
      subPropertyType: ["apartment"],
      sort: "NEW",
      specialCondition: {},
      brand: {},
      walkingTime: "",
      ...filterOptions.defaultDateValue,
      ...filterOptions.defaultFilterValue,
      isDefaultValue: true,
    } as PropertyFilterCondition,
    {
      serializer: {
        read(raw) {
          const value = JSON.parse(raw)
          if (value.sort === "RECOMMENDED") {
            value.sort = "NEW"
          }
          if (value.walkingTime === undefined) {
            value.walkingTime = ""
          }
          return value
        },
        write(value) {
          return JSON.stringify(value)
        },
      },
    }
  )

  function setFilter(newFilter: PropertyFilterCondition) {
    filter.value = newFilter
  }

  async function reset({
    filter: newFilter,
  }: {
    filter: PropertyFilterCondition
  }) {
    if (newFilter.subPropertyType?.includes("apartment")) {
      newFilter.propertyType = "apartment"
    }
    if (
      newFilter.subPropertyType?.includes("onlyHouse") ||
      newFilter.subPropertyType?.includes("onlyLand")
    ) {
      newFilter.propertyType = "house"
    }
    filter.value = {
      ...filter.value,
      ...newFilter,
      isDefaultValue: undefined,
    }
    properties.value = []
    loading.value = true
    loadingPromise.value = new Promise(
      (r) => (loadingPromiseResolver.value = r)
    )
    finished.value = false
    const id = randomStr(10)
    requestId.value = id
    totalRecords.value = undefined
    try {
      currentPage.value = 1
      await Promise.all([
        fetchPropertyListByMultiSearch({
          filter: filter.value,
          page: 1,
          perPage,
        }).then((propertiesResult) => {
          if (id !== requestId.value) return
          properties.value = propertiesResult.properties
            .filter((x) => x.dataType === "contract" || !!x.imageUrls?.length)
            .map((x) => new Property(x))
          if (propertiesResult.properties.length < perPage) {
            finished.value = true
          }
        }),
        fetchPropertyListByMultiSearchCount({
          filter: filter.value,
        }).then((countResult) => {
          if (id !== requestId.value) return
          totalRecords.value = countResult.totalRecords
        }),
      ])
    } finally {
      if (id !== requestId.value) return
      loading.value = false
      loadingPromiseResolver.value?.()
    }
  }

  async function fetchNext() {
    if (
      finished.value ||
      loading.value ||
      !requestId.value ||
      !properties.value
    )
      return
    const id = requestId.value

    try {
      loading.value = true

      currentPage.value += 1

      const propertiesResult = await fetchPropertyListByMultiSearch({
        filter: filter.value,
        page: currentPage.value,
        perPage,
      })
      if (id !== requestId.value) return

      properties.value = properties.value.concat(
        propertiesResult.properties
          .filter((x) => x.dataType === "contract" || !!x.imageUrls?.length)
          .map((x) => new Property(x))
      )
      if (!propertiesResult.properties.length) {
        finished.value = true
      }
    } finally {
      if (id !== requestId.value) return
      loading.value = false
    }
  }

  async function getLocation() {
    const firstLocation = filter.value.locations[0]
    let location: string | undefined = undefined
    let latLng: string | undefined = undefined
    const { locationType, locationId } = firstLocation
    switch (locationType) {
      case "MUNICIPALITY":
      case "TOWN": {
        const firstProperty = properties.value?.[0]
        if (!firstProperty) {
          break
        }
        location = firstProperty.locationName
        latLng = `${firstProperty.lat}-${firstProperty.lng}`
        break
      }
      case "STATION": {
        await locationInfo.fetchLocationInfos([firstLocation])
        const info = locationInfo.cache[`STATION-${locationId}`]

        location = info.station + (info.station.slice(-1) !== "駅" ? "駅" : "")
        latLng = `${info.lat}-${info.lng}`
        break
      }
      case "SCHOOL": {
        await locationInfo.fetchLocationInfos([firstLocation])
        const info = locationInfo.cache[`SCHOOL-${locationId}`]

        location = info.name
        latLng = `${info.lat}-${info.lng}`
        break
      }
      case "LINE": {
        if (!firstLocation?.stationIds?.[0]) break
        await locationInfo.fetchLocationInfos([
          {
            locationType: "STATION",
            locationId: firstLocation.stationIds[0],
          },
        ])
        const info =
          locationInfo.cache[`STATION-${firstLocation.stationIds[0]}`]
        location = info.station + (info.station.slice(-1) !== "駅" ? "駅" : "")
        latLng = `${info.lat}-${info.lng}`
        break
      }
    }
    if (!location || !latLng) {
      return undefined
    }
    return { locationName: location, latLng }
  }

  return {
    properties,
    totalRecords,
    reset,
    fetchNext,
    loading,
    finished,
    filter,
    setFilter,
    getLocation,
    loadingPromise,
    temporaryLocations,
  }
})
