import subscription from "./subscription"
import properties from "./properties"
import process from "./process"
import clientEmail from "./clientEmail"
import listClient from "./listClient"

export default {
  subscription,
  properties,
  process,
  clientEmail,
  listClient,
}
