import { cloneDeep } from "lodash"

/**
 * @description
 * - deepCopy した target の proxy を返す
 * - get は存在するプロパティまたは unwrapKey のみ可能
 * - set は不可
 * - proxy[unwrapKey] は proxy されていない deepCopy を返す
 *
 * @example
 * unwrap する
 * ```
 * const unwraped = window.__pinrich__.dangerousUnwrap
 * unwraped.hoge = 1
 * ```
 */
export const readonlyCopy = (target, unwrapKey = "dangerousUnwrap") => {
  if (!target) return
  const clone = cloneDeep(target)
  if (unwrapKey in clone) {
    throw new Error(`unwrapKey ${unwrapKey} is defined in target object`)
  }

  const proxy = new Proxy(clone, {
    get(target, key) {
      if (key === unwrapKey) {
        return target
      }
      if (Object.prototype.hasOwnProperty.call(target, key) === undefined) {
        throw new Error(`invalid page constant key: ${key}`)
      }
      return target[key]
    },
    set() {
      throw new Error("cannot set value to page constant")
    },
  })

  return proxy
}
