import { computed } from "vue"

import { createContext } from "@/composables/createContext"
import { useVuex } from "@/composables/useVuex"
import { pageConstants } from "@/helpers/pageConstants"
// import { USER_TYPE } from "@share/const";

export const [
  provideSharedShouldShowConsultation,
  injectShouldShowConsultation,
] = createContext<ReturnType<typeof useShouldShowConsultation>>(
  "ShouldShowConsultation",
  true
)

export const useShouldShowConsultation = (showAnyway = false) => {
  const property = useVuex(
    (store) => store.getters["properties/selectedProperty"]
  )
  const isNotContract = computed(() => {
    return property.value && property.value.dataType !== "contract"
  })
  const isNotSold = computed(() => {
    return property.value && property.value.status !== "sold"
  })

  // const userType = useVuex(store => store.getters.userType);
  // const userIsNotCorporateUser = computed(() => {
  //   return userType.value !== USER_TYPE.CORPORATE_USER;
  // });

  const isNotDefaultDomain = !!pageConstants.siteSettings.subdomain

  const shouldShowConsultation = computed(() => {
    if (showAnyway) {
      return true
    }
    return isNotContract.value && isNotSold.value && isNotDefaultDomain
    // return isNotContract.value && userIsNotCorporateUser.value;
  })

  return {
    shouldShowConsultation,
    isNotContract,
    isNotSold,
    isNotDefaultDomain,
    // userIsNotCorporateUser
  }
}
