/* ユーザーの請求情報を管理するモジュール */
export default {
  namespaced: true,
  state: {
    initialized: false,
    viaAgency: false,
    coupon: {
      name: "",
      amount_off: 0,
      percent_off: 0,
    },
    tax_rates: [
      {
        display_name: "消費税",
        percentage: 10,
      },
    ],
    plan: {
      id: "",
      price: "",
    },
    card: {
      id: "",
      brand: "",
      last4: "",
    },
  },
  mutations: {
    store(state, { viaAgency, coupon, tax_rates, plan, card }) {
      if (viaAgency !== undefined) state.viaAgency = viaAgency
      if (coupon !== undefined) state.coupon = coupon
      if (tax_rates !== undefined) state.tax_rates = tax_rates
      if (plan !== undefined) state.plan = plan
      if (card !== undefined) state.card = card

      state.initialized = true
    },
  },
  actions: {
    store(context, data) {
      context.commit("store", data)
    },
  },
}
