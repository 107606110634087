<template>
  <div class="FormWrapper__container" :data-label="!!label ? 'exists' : 'none'">
    <div v-if="!noHeader" class="FormWrapper__header">
      <div v-if="label" class="FormWrapper__label">
        {{ label }}{{ required ? "*" : "" }}
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import IconRequired from "@/components/icon/IconRequired.vue"

export default {
  components: {
    IconRequired,
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.FormWrapper__container {
  width: 100%;
}
.FormWrapper__container + .FormWrapper__container[data-label="exists"] {
  margin-top: 12px;
}
.FormWrapper__container + .FormWrapper__container[data-label="none"] {
  margin-top: 4px;
}
.FormWrapper__container[data-label="exists"]:first-of-type {
  margin-top: 18px;
}
.FormWrapper__header {
  display: flex;
  margin-bottom: 4px;
  height: 14px;
}
.FormWrapper__label {
  display: inline-block;
  padding: 0 4px;
  color: $dark-gray;
  font-size: 0.75em;
  font-weight: bold;
  margin-bottom: 4px;
}
.FormWrapper__required {
  margin-left: auto;
  font-size: var(--font-size);
}
</style>
