<template>
  <button
    type="button"
    class="BasePhotoInput"
    :class="{ [size]: size, invalid }"
    @click="onClick"
    @keydown="onKeydown"
  >
    <div class="BasePhotoInput__content">
      <img :src="imgSrc" class="BasePhotoInput__icon" />
      <label for="id" class="BasePhotoInput__label">
        <slot>{{ label }}</slot>
      </label>
    </div>
    <input
      :id="id"
      ref="inputRef"
      type="file"
      accept="image/*"
      style="display: none"
      @change="onPhotoChange"
    />
  </button>
</template>

<script>
let idNum = 0

export default {
  data() {
    return {
      photoDataUrl: null,
    }
  },
  computed: {
    imgSrc() {
      return (
        this.photoDataUrl || this.photoUrl || "/assets/images/icons/upload.svg"
      )
    },
  },
}
</script>

<script setup>
import { computed, ref } from "vue"

const emits = defineEmits(["update:modelValue"])

const props = defineProps({
  photoUrl: {
    type: String,
  },
  label: {
    type: String,
    default: "プロフィール写真を選択する",
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "small",
    validator(size) {
      return ["small", "large"].includes(size)
    },
  },
})

const id = "BasePhotoInput__button--" + idNum++

const photoDataUrl = ref(undefined)

const imgSrc = computed(() => {
  return (
    photoDataUrl.value || props.photoUrl || "/assets/images/icons/upload.svg"
  )
})

const inputRef = ref(undefined)
function onClick() {
  inputRef.value?.click()
}
function onKeydown(e) {
  const keys = {
    13: "enter",
    32: "space",
  }
  if (keys[e.keyCode]) {
    e.preventDefault()
    inputRef.value?.click()
  }
}

async function onPhotoChange(e) {
  // validation
  const file = e.target.files[0]
  if (!file) {
    return
  }
  const sizeLimit = 1024 * 1024 * 1 // 1 MB
  if (file.size > sizeLimit) {
    window.alert("選択できるファイルのサイズは1MBまでです")
    return
  }

  photoDataUrl.value = await asyncReadAsDataURL(file)

  emits("update:modelValue", file)

  function asyncReadAsDataURL(file) {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject()
        return
      }
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/partial/BaseInput.scss";

.BasePhotoInput {
  &.small {
    --content-height: 30px;
    --icon-width: 30px;
    --icon-height: 30px;
    --icon-border-radius: 50%;
    --icon-object-fit: contain;
  }
  &.large {
    --content-height: 65px;
    --icon-width: 65px;
    --icon-height: 65px;
    --icon-border-radius: 2px;
    --icon-object-fit: cover;
  }
  @extend .BaseInput__input;
  height: unset;
  margin-bottom: 0;
  position: relative;
  width: 100%;
  padding: 6px;
  line-height: 0;
  border: 1px solid var(--dark-gray);
  border-radius: 4px;
  background: white;
  color: var(--dark-gray);
  cursor: pointer;
  &:focus {
    @extend .BaseInput__input--focused;
    font-weight: bold;
  }
  // spacer
  &:after {
    content: "";
    display: inline-block;
    height: var(--content-height);
  }
}
.BasePhotoInput__content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  overflow: hidden;
}
.BasePhotoInput__icon {
  display: inline-block;
  width: var(--icon-width);
  height: var(--icon-height);
  border-radius: var(--icon-border-radius);
  padding: 0px;
  background: white;
  object-fit: var(--icon-object-fit);
  object-position: center center;
  // &[src="@/../public/assets/images/icons/profile.svg"],
  // &[src="@/../public/assets/images/icons/upload.svg"] {
  //   height: 55px;
  // }
}
.BasePhotoInput__label {
  display: inline-flex;
  align-items: center;
  justify-content: left;
  margin-left: 10px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
}
</style>
