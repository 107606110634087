import { ClientData } from "./Client"

export const DEFAULT_PER_PAGE = 30

export const createClientListQuery = (
  query?: Partial<ClientListQuery>
): ClientListQuery => {
  return Object.assign(
    {},
    {
      presets: undefined,
      columnFilters: [],
      sort: [],
      page: 1,
      perPage: DEFAULT_PER_PAGE,
    },
    query
  )
}

export type ClientListQuery = {
  presets: PresetFilterType[] | undefined
  /**
   * NOTE: 本当はフロントエンド側で ColumnFilter[] にしてから渡したいが、そこまでのリファクタは今はしない
   */
  columnFilters: ColumnFilter[] | OldColumnFilters
  sort: string[]
  page: number
  perPage: number
}

export type PresetFilterType =
  | "hot"
  | "rankB"
  | "rankC"
  | "noMailSent"
  | "pursuing"
  | "dealPhaseDealing"
  | "dealPhaseFinished"
  | "undeliverable"

export type ColumnFilter = {
  method: "where" | "whereNot" | "whereIn"
  key: keyof ClientData
  operator: ">" | ">=" | "=" | "<" | "<=" | "like" | "in"
  value: string
}

/**
 * @deprecated ColumnFilter に置き換える
 * @see ColumnFilter
 */
export type OldColumnFilters = {
  [key in keyof ClientData]?: string
}
