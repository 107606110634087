<template>
  <SharedContextProvider>
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
    <div id="teleport-modal"></div>
    <div id="teleport-drawer"></div>
    <div id="teleport-image"></div>
    <div id="teleport-global"></div>
  </SharedContextProvider>
</template>

<script lang="ts" setup>
import SharedContextProvider from "@/components/SharedContextProvider.vue"

import { watch } from "vue"
import { useRoute } from "vue-router"

import { watchRoute } from "@/helpers/metaTag"

const route = useRoute()

const excludeComponents = [
  "AiSateiFormView",
  "LoanFormView",
  "SpOwnerView",
  "OwnerPropertyView",
  "PropertyDetailView",
  "PropertyListView",
  "SearchTopView",
]

watch(
  route,
  (to, from) => {
    watchRoute(to, from)
  },
  {
    immediate: true,
  }
)
</script>

<style>
#app {
  position: relative;
}
</style>
