import jsLogger from "js-logger"

const logLevels = {
  localserver: jsLogger.TRACE,
  development: jsLogger.TRACE,
  test: jsLogger.DEBUG,
  production: jsLogger.ERROR,
}

jsLogger.useDefaults({
  formatter: (messages, context) => {
    messages.unshift(`${context.level.name}:`)
    if (context.name) {
      messages.unshift(`[${context.name}]`)
    }
  },
})
jsLogger.setLevel(logLevels[import.meta.env.MODE])

function Logger(name) {
  return jsLogger.get(name)
}

export { Logger }
