export const beforeConfirmEmailSendFrom = async (to) => {
  const { fail } = to.query

  if (fail) {
    window.alert(
      "担当者のメールアドレスが認証できませんでした。リンクの有効期限が切れていた可能性があります。担当者の追加画面より認証メールを再送して下さい。"
    )
  } else {
    window.alert("担当者のメールアドレスを認証しました。")
  }
}
