import uaParser from "@/helpers/uaparser"

/**
 * 条件によってコンポーネントを切り替える。
 * @param {object} components デフォルトでは { sp: component, pc: component } を期待
 * @param {function} condition デフォルトでは () => "sp" | "pc"
 * @returns {() => Promise<object>} アクセス時に非同期に解決される必要があるので async 関数を返す
 * @example
  const routes = [
    {
      path: "/",
      name: "top",
      component: conditionalComponent({
        sp: () => import("@/views/LandingPageView.vue"),
        pc: wrapComponent(
          () => import("@/views/LandingPageView.vue"),
          () => import("@/components/auth/AuthLogin.vue")
        )
      }, () => (uaParser.isMobile() ? "sp" : "pc"))
    },
  ]
 */
export const conditionalComponent =
  (components, condition = () => (uaParser.isMobile() ? "sp" : "pc")) =>
  async () => {
    const _condition = condition()
    const _component = components[_condition]
    if (!_component) {
      throw new Error(`component for '${_condition}' was not specified`)
    }
    return await _getComponent(_component)
  }

/**
 * 条件によってコンポーネントをラップする。ラッパーは使い回すことが予想されるのでカリー化。
 * @param {{ wrapper: () => Promise<object> , condition: () => boolean }} option 
 * @returns {(wrappedComponent) => () => Promise<object>} component
 * @example
 * ```
  const wrapWithLPIfPc = conditionalWrapper({
    wrapper: () => import("@/views/LandingPageView.vue"),
    condition: () => !uaParser.isMobile()
  });
  const routes = [{
    path: "/register",
    name: "register",
    component: wrapWithLPIfPc(() =>
      import("@/components/auth/AuthRegisterUserData.vue")
    )
  }]

  // 以下のショートハンド
  const routes = [{
    path: "/register",
    name: "register",
    component: conditionalComponent({
      sp: () => import("@/components/auth/AuthRegisterUserData.vue"),
      pc: wrapComponent(
        () => import("@/components/views/LandingPageView.vue"),
        () => import("@/components/auth/AuthRegisterUserData.vue")
      )
    })
  }]
  ```
 */
export const conditionalWrapper =
  ({ wrapper, condition }) =>
  (wrapped) =>
  async () => {
    if (typeof condition !== "function") {
      throw new Error("condition should be specified")
    }
    if (condition()) {
      return {
        ...(await wrapComponent(wrapper, wrapped)),
        name: "ConditionalWrapper",
      }
    } else {
      return await _getComponent(wrapped)
    }
  }

export const wrapComponent = async (
  wrapperComponent,
  wrappedComponent,
  options = { name: "WrapComponent" }
) => {
  const wrapper = await _getComponent(wrapperComponent)
  const wrapped = await _getComponent(wrappedComponent)
  return {
    render() {
      return (
        <wrapper attrs={this.$attrs}>
          <wrapped attrs={this.$attrs} />
        </wrapper>
      )
    },
    ...options,
  }
}

const _getComponent = async (_component) => {
  if (!_component) {
    throw new Error(`component was not found`)
  }

  // 静的インポート or Promise
  if (typeof _component !== "function") {
    return await _component
  }
  const component = await _component()
  if (component.default) {
    // 動的インポート
    return component.default
  } else {
    // 関数を使ってコンポーネントを組み立てる場合
    return component
  }
}
