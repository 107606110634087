<template>
  <label
    :for="radioId"
    class="BaseRadio__wrapper"
    :class="{ BaseRadio__checked: modelValue === value }"
  >
    <input
      type="radio"
      class="BaseRadio__input"
      :id="radioId"
      :value="value"
      :checked="modelValue === value"
      @change="$emit('update:modelValue', $event.target.value)"
      :name="name"
      :disabled="disabled || undefined"
    />
    <span class="BaseRadio__label">
      <slot></slot>
    </span>
  </label>
</template>

<script>
let count = 0
const generateRadioId = () => `BaseRadio__${count++}`

export default {
  emits: ["update:modelValue"],
  props: {
    value: { type: String, required: true },
    modelValue: { type: String, required: true },
    id: { type: String, required: false },
    disabled: { type: Boolean, required: false },
    name: { type: String, required: false },
  },
  created() {
    this.radioId = this.id || generateRadioId()
  },
}
</script>

<style lang="scss" scoped>
.BaseRadio__wrapper {
  display: inline-block;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  position: relative;
}
.BaseRadio__input {
  position: absolute;
  opacity: 0;
}
.BaseRadio__label {
  position: relative;
  display: inline-block;

  padding-left: calc(18px + 8px);

  .BaseRadio__input:disabled + & {
    color: $light-gray;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    display: inline-block;
    border-radius: 50%;
  }

  /* circle */
  &::before {
    height: 18px;
    width: 18px;
    top: 2px;
    left: 0;

    background: white;

    .BaseRadio__input:focus + & {
      box-shadow: 0 0 0 3px $light-gray;
    }
    .BaseRadio__input:not(:focus) + & {
      box-shadow: none;
      border: 1px solid $light-gray;
    }

    .BaseRadio__input:checked + & {
      background: $blue;
      border: none;
    }
    .BaseRadio__input:checked:hover + & {
      background: $royal-blue;
    }
    .BaseRadio__input:checked:disabled + & {
      background: $light-gray;
    }
  }

  /* inner circle */
  &::after {
    height: 8px;
    width: 8px;

    top: 7px;
    left: 5px;

    background: white;
  }
}
</style>

<!-- 
<template>
  <label :for="radioId" class="BaseRadio__wrapper">
    <input
      type="radio"
      class="BaseRadio__input"
      :id="radioId"
      :value="value"
      :checked="modelValue === value"
      @change="(value) => emit('update:modelValue', value)"
      :name="name"
      :disabled="disabled"
    />
    <span class="BaseRadio__label">
      <slot></slot>
    </span>
  </label>
</template>

<script setup lang="ts">
import { computed } from "vue"

const props = defineProps<{
  value: string
  modelValue: string
  id?: string
  name?: string
  label?: string
  disabled?: boolean
}>()
const emit = defineEmits<{
  (name: "update:modelValue", value: string): void
}>()

const radioId = computed(() => {
  return props.id || getRadioId()
})
const getRadioId = (() => {
  let count = 0
  return function () {
    return "BaseRadioButton__" + count++
  }
})()
</script>

<style lang="scss" scoped>
.BaseRadio__wrapper {
  display: inline-block;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  position: relative;
}
.BaseRadio__input {
  position: absolute;
  opacity: 0;
}
.BaseRadio__label {
  position: relative;
  display: inline-block;

  padding-left: calc(18px + 8px);

  .BaseRadio__input:disabled + & {
    color: $light-gray;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    display: inline-block;
    border-radius: 50%;
  }

  /* circle */
  &::before {
    height: 18px;
    width: 18px;
    top: 0;
    left: 0;

    background: white;

    .BaseRadio__input:focus + & {
      box-shadow: 0 0 0 3px $light-gray;
    }
    .BaseRadio__input:not(:focus) + & {
      box-shadow: none;
      border: 1px solid $light-gray;
    }

    .BaseRadio__input:checked + & {
      background: $blue;
      border: none;
    }
    .BaseRadio__input:checked:hover + & {
      background: $royal-blue;
    }
    .BaseRadio__input:checked:disabled + & {
      background: $light-gray;
    }
  }

  /* inner circle */
  &::after {
    height: 8px;
    width: 8px;

    top: 5px;
    left: 5px;

    background: white;
  }
}
</style> -->
