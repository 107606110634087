import { formatRelative } from "date-fns"
import { ja } from "date-fns/locale"
import { moment } from "./moment"

moment.locale("ja")

export const heiBei = (n) => {
  return `${n}㎡`
}
export const year = (n) => {
  const array = n.split(/-|\//)
  const date = new Date(parseInt(array[0]), parseInt(array[1]) - 1)
  return `${date.getFullYear()}年`
}

export function manYen(p, digits = 0) {
  return (p / 10000).toFixed(digits)
}

export const truncatedString = (str, len) => {
  if (!str) {
    return str
  }
  return str.length <= len ? str : str.substr(0, len) + "..."
}

export const isZenkaku = (str) => {
  return String(str).match(/[\x01-\x7E\uFF65-\uFF9F]/) ? false : true
}

export const toHankaku = (str) => {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
  })
}

/**
 * 整数または小数に対応。符号あり/なし対応
 * valueが全角だった場合の半角化も対応
 */
export const isNumber = (value) => {
  let val = value
  if (isZenkaku(value)) {
    val = toHankaku(value)
  }
  return /^[+-]?\d*[\.]?\d+$/.test(val)
}

export const latLngRound = (n) => {
  return Math.round(n * 1000000) / 1000000
}

export const formattedDateTime = (n) => {
  if (n !== undefined && n !== null) {
    const formattedDateTime = moment(n).calendar(null, {
      sameDay: "[今日] HH:mm",
      nextDay: "[明日] HH:mm",
      nextWeek: "YYYY年MM月DD日",
      lastDay: "[昨日] HH:mm",
      lastWeek: "YYYY年MM月DD日",
      sameElse: "YYYY年MM月DD日",
    })
    return formattedDateTime
  }
  return ""
}

export const formattedDate = (n) => {
  if (n !== undefined && n !== null) {
    const formattedDate = moment(n).calendar(null, {
      sameDay: "YYYY/MM/DD",
      nextDay: "YYYY/MM/DD",
      nextWeek: "YYYY/MM/DD",
      lastDay: "YYYY/MM/DD",
      lastWeek: "YYYY/MM/DD",
      sameElse: "YYYY/MM/DD",
    })
    return formattedDate
  }
  return ""
}

export const formattedRegisteredDate = (n) => {
  if (n !== undefined && n !== null) {
    const formattedDate = moment(n).calendar(null, {
      sameDay: "YYYY年MM月DD日",
      nextDay: "YYYY年MM月DD日",
      nextWeek: "YYYY年MM月DD日",
      lastDay: "YYYY年MM月DD日",
      lastWeek: "YYYY年MM月DD日",
      sameElse: "YYYY年MM月DD日",
    })
    return formattedDate
  }
  return ""
}

export const formatRelativeDuration = (n) => {
  if (n !== undefined && n !== null) {
    const originalDate = new Date(n)
    const now = new Date()

    const formattedDate = formatRelative(originalDate, now, { locale: ja })
    return formattedDate
  }
  return ""
}

export function bytesToSize(bytes, options = {}) {
  const { useBinaryUnits = false, decimals = 2 } = options

  if (decimals < 0) {
    throw new Error(`Invalid decimals ${decimals}`)
  }

  const base = useBinaryUnits ? 1024 : 1000
  const units = useBinaryUnits
    ? ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
    : ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(base))

  return `${(bytes / Math.pow(base, i)).toFixed(decimals)} ${units[i]}`
}

export function numberUnitFormat(num) {
  let remainder = num
  const base = 10000
  const units = ["", "万", "億", "兆", "京", "垓"]
  let result = ""

  for (let i = units.length - 1; i >= 0; i--) {
    const unit = units[i]
    const current = Math.floor(remainder / Math.pow(base, i))
    if (current) {
      result += current + unit
    }
    remainder = remainder % Math.pow(base, i)
  }
  return result
}
