<template>
  <input
    ref="autocompleteRef"
    type="text"
    @focus="onAutocompleteFocus"
    :id="props.id"
    :value="props.modelValue.location"
    :placeholder="props.placeholder"
    :disabled="!isReady"
    @keypress.enter.prevent="(event) => event?.target?.blur()"
  />
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useAutocomplete } from "./composables/useAutocomplete"
import { parseAddressComponents } from "@share/googleMapGeocode"
import { randomStr } from "@share/random"

const emit = defineEmits<{
  (name: "update:modelValue", modelValue: AutocompleteValue): void
}>()

const props = withDefaults(
  defineProps<{
    id?: string
    modelValue: AutocompleteValue
    placeholder: string
  }>(),
  {
    id: () => `GmapAutocomplete__${randomStr(5)}`,
    placeholder: "  ",
  }
)

/**
 * イベントリスナーがセットされたら true になる
 */
const isReady = ref(false)

const { autocompleteRef, autocompletePromise } = useAutocomplete({
  options: {
    componentRestrictions: { country: "jp" },
  },
  selectFirstOnEnter: true,
})
autocompletePromise.then((autocomplete) => {
  autocomplete.addListener("place_changed", () => {
    const { geometry, address_components, name } = autocomplete.getPlace()
    if (address_components === undefined) {
      return
    }
    // GeocoderAddressComponentとAddressComponentが何故かマッチしてくれないので
    // 仕方なくanyにする
    const { location, address } = parseAddressComponents(
      address_components as any
    )

    const value = assignValue({
      location,
      address,
      position: {
        lat: geometry?.location?.lat(),
        lng: geometry?.location?.lng(),
      },
      name: name ?? "",
    })
    emit("update:modelValue", value)
  })
  isReady.value = true
})

const defaultPosition: {
  lat: number | undefined
  lng: number | undefined
} = {
  lat: undefined,
  lng: undefined,
}
const defaultAddress = {
  postCode: "",
  city: "",
  street: "",
  buildingName: "",
}

function assignValue(
  { position, address, location, name } = {
    position: defaultPosition,
    address: defaultAddress,
    location: "",
    name: "",
  }
) {
  let value = { location, name }
  if (props.modelValue.position) {
    Object.assign(value, {
      position,
    })
  }
  if (props.modelValue.address) {
    Object.assign(value, {
      address,
    })
  }
  return value
}

function onAutocompleteFocus() {
  autocompleteRef.value.value = ""
  emit("update:modelValue", assignValue())
}
</script>
