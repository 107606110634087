<template>
  <div :class="$style.required">必須</div>
</template>

<style lang="scss" module>
.required {
  padding: 1px 2px;
  font-size: var(--font-size);
  line-height: 12px;
  border-radius: 2px;
  background: $red;
  color: $white;
  user-select: none;
}
</style>
