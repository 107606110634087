<template>
  <div>
    <span v-if="props.before">
      {{ props.before }}
    </span>
    <BaseInput
      v-bind="$attrs"
      :modelValue="props.modelValue"
      @update:modelValue="(v) => $emit('update:modelValue', v)"
      :style="{ width: props.inputWidth }"
      :invalid="props.invalid"
    />
    <span v-if="props.after">
      {{ props.after }}
    </span>
  </div>
</template>

<script setup lang="ts">
import BaseInput from "./BaseInput.vue"

const props = withDefaults(
  defineProps<{
    modelValue: string
    before?: string
    after?: string
    inputWidth?: string
    invalid?: boolean
  }>(),
  {
    inputWidth: "135px",
    invalid: false,
  }
)

defineEmits<{
  (name: "update:modelValue", value: string)
}>()
</script>
