import {
  BUILDING_IMAGE_SUFFIX,
  EXTERIOR_IMAGE_SUFFIX,
  EXTERIOR_IMAGE_TYPE,
  IMAGE_TYPE_BY_GROUP,
} from "../const"
import { Base } from "./Base"

/**
 * @typedef {object} ImageAttributes
 * @property {string} createdAt - "2020-11-06T10:45:08.000Z",
 * @property {string} exclusiveArea - 20.49,
 * @property {number} floorPlanNumberOfRooms - 1,
 * @property {string} floorPlanType - "K",
 * @property {number|null} hasWatermark - null,
 * @property {number} id - 521854,
 * @property {string} imageGroup - "room",
 * @property {number} imageHeight - 321,
 * @property {string} imageType - "間取り図",
 * @property {string} imageUrl - "743_floorplan_3.jpg",
 * @property {number} imageWidth - 600,
 * @property {number} masterId - 743,
 * @property {number|null} noWatermark - 0,
 * @property {string} updatedAt - "2020-11-19T08:36:29.000Z"
 */

/**
 * @typedef {"間取り図" | "玄関" | "洗面設備" | "居室・リビング" | "収納" | "バルコニー" | "バス・シャワールーム" | "トイレ" | "キッチン" | "眺望"} RoomImageType
 * @typedef {"建物外観" | "エントランス" | "ロビー" | "駐車場" | "駐輪場" | "庭" | "公園"} BuildingImageType
 * @typedef {RoomImageType | BuildingImageType} ImageType
 * @typedef {"room" | "building"} ImageGroup
 * @typedef {string} imageKey '1234-3-LDK-45.67'
 * @typedef {{ attributes: ImageAttributes }} ImageFromDB
 * @typedef {{ group: ImageGroup, type: ImageType, url: string }} ImageData
 * @typedef {{ imageBasePath: string, images: ImageData[] }} ResponseData
 */

const getImageKey = (imageFromDB) => {
  const { floorPlanNumberOfRooms, floorPlanType, exclusiveArea, imageGroup } =
    imageFromDB
  if (imageGroup === "building") {
    return "building"
  } else {
    return `${floorPlanNumberOfRooms}-${floorPlanType}-${exclusiveArea}`
  }
}

/**
 * @type {(imageFromDB: ImageFromDB) => ImageData}
 */
const convertoToImageData = (imageFromDB) => {
  const { imageGroup, imageType, imageUrl } = imageFromDB
  return {
    group: imageGroup,
    type: imageType,
    url: imageUrl,
  }
}

/**
 * @type {(groupName: ImageGroup) => Map<ImageType, ImageData>}
 */
const createImageMap = (groupName) => {
  const types = IMAGE_TYPE_BY_GROUP[groupName]
  if (!types) {
    throw new TypeError(
      `Unknown image group "${groupName}". It should be "room" or "building"`
    )
  }
  return types.reduce((map, type) => {
    map[type] = []
    return map
  }, {})
}

export class ApartmentImages extends Base {
  constructor({ images, masterId }) {
    super()
    this.masterId = masterId
    this.images = images.filter((i) => i.masterId === masterId)
    this.imagesByImageKey = this.getImagesByImageKey()
  }

  getImagesByImageKey() {
    if (this.imagesByImageKey) {
      return this.imagesByImageKey
    }
    // console.log(JSON.stringify(this.images, null, 2));
    const imagesMap = this.images.reduce((imagesMap, imageFromDB) => {
      if (imageFromDB.imageGroup === "other") return imagesMap
      const imageKey = getImageKey(imageFromDB)
      const imageData = convertoToImageData(imageFromDB)

      if (!imagesMap[imageKey]) {
        imagesMap[imageKey] = createImageMap(imageData.group)
      }

      // imageKey ごとに画像を配列に追加する
      const imagesOfType = imagesMap[imageKey][imageData.type]
      if (imagesOfType) {
        // 建物画像の場合は画像タイプごとに４枚まで
        if (imageKey !== "building" || imagesOfType.length < 4) {
          imagesOfType.push(imageData)
        }
      } else {
        // 不明な画像タイプ
      }
      return imagesMap
    }, {})

    const result = {}
    Object.keys(imagesMap).forEach((imageKey) => {
      /**
       * @type {Map<ImageType, ImageData[]>}
       */
      const imagesMapOfKey = imagesMap[imageKey]

      /**
       * @type {ImageData[]}
       * @description IMAGE_TYPE_BY_GROUP 順に並んでいる
       */
      const images = []
      for (const imagesOfType of Object.values(imagesMapOfKey)) {
        images.push(...imagesOfType)
      }

      // 最初の建物外観だけ別のキーで抜き出す
      if (imageKey === "building") {
        const exteriorIndex = images.findIndex(
          (image) => image.type === EXTERIOR_IMAGE_TYPE
        )
        // もし建物外観画像がなければ、 type: building のうち先頭の画像を抜き出す
        const index = exteriorIndex != -1 ? exteriorIndex : 0
        const firstBuildingImage = images.splice(index, 1)[0]
        result[EXTERIOR_IMAGE_SUFFIX] = [firstBuildingImage]
        result[BUILDING_IMAGE_SUFFIX] = images
      } else {
        result[imageKey] = images
      }
    })
    return result
  }

  getImageUrlsByPropertyId({ properties }) {
    if (!this.imagesByImageKey) {
      this.getImagesByImageKey()
    }
    const imagesByPropertyId = this.getImagesByPropertyId({ properties })
    return imagesByPropertyId.reduce((result, imagesByType, propertyId) => {
      const { exterior, building, room } = imagesByType
      const imageObjects = [...exterior, ...building, ...room]
      result[propertyId] = imageObjects.map((x) => x.url)
      return result
    }, {})
  }
}
