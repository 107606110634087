import { checkRegistrationStep } from "@/helpers/auth"
import { setMetaTagOf } from "@/helpers/metaTag"
import uaParser from "@/helpers/uaparser"
import { verifyUserToBeRegistered } from "@/repositories/user"
import store from "@/store"
import {
  // conditionalComponent,
  conditionalWrapper,
} from "@/views/conditionalComponent"
import { REGISTRATION_STEP } from "@share/const"

const { NOT_REGISTERED, NEED_VERIFICATION } = REGISTRATION_STEP

const commonSetting = {
  register: {
    async beforeEnter(to, from, next) {
      setMetaTagOf.managementView()
      const { userType, managerId, userId } = to.query
      if (!userType || !managerId || !userId) {
        return next({ name: "pc_lp_login" })
      }

      const result = await verifyUserToBeRegistered({
        userType,
        managerId,
        userId,
      })
      if (!result.ok) {
        return next({ name: "pc_lp_login" })
      }

      return next()
    },
  },
  verify: {
    beforeEnter(to, from, next) {
      setMetaTagOf.managementView()
      if (uaParser.isMobile()) {
        next({ name: "sp_lp" })
      }
      next(checkRegistrationStep([NOT_REGISTERED, NEED_VERIFICATION]))
    },
  },
  login: {
    beforeEnter(to, from, next) {
      setMetaTagOf.managementView()
      if (uaParser.isMobile()) {
        next({ name: "sp_lp" })
      }
      if (!store.getters.isLoggedIn) {
        next()
      } else {
        next({ name: "management" })
      }
    },
  },
  passwordReset: {
    props: (route) => {
      return {
        email: atob(route.query.email),
        confirmCode: route.query.confirmCode,
        password: route.query.password,
      }
    },
    beforeEnter(to, from, next) {
      setMetaTagOf.managementView()
      const ok =
        !from.name &&
        !!to.query.email &&
        !!to.query.confirmCode &&
        !!to.query.password
      if (!ok) {
        next({ name: "pc_lp_password_reset_mail" })
      } else {
        next()
      }
    },
  },
  mailMe: {
    beforeEnter(to, from, next) {
      next({ name: "pc_lp_login" })
      // if (store.getters.customerState === "EXPIRED") {
      //   next()
      // } else {
      //   next({ name: "pc_lp_login" })
      // }
    },
  },
}
const wrapWithLPIfPc = conditionalWrapper({
  wrapper: () => import("@/views/LandingPageView.vue"),
  condition: () => !uaParser.isMobile(),
})

// TODO: conditionalComponent で SP PC で同じルートに
// export const useAuthRoutes = (routes) => {
//   const authRoutes = [
//     {
//       path: "/auth/register",
//       name: "auth_register",
//       ...commonSetting.register,
//       component: wrapWithLPIfPc(() =>
//         import("@/components/auth/AuthRegister.vue")
//       ),
//     },

//     /* PC Routes */
//     {
//       path: "/pc/lp",
//       name: "pc_lp",
//       component: () => import("@/views/LandingPageView.vue"),
//       children: [
//         {
//           path: "register/verify",
//           name: "pc_lp_register_verify",
//           ...commonSetting.verify,
//           component: () => import("@/components/auth/AuthRegisterVerify.vue"),
//         },
//         {
//           path: "login",
//           name: "pc_lp_login",
//           ...commonSetting.login,
//           meta: {
//             tags: {
//               googlebot: "all, max-snippet:-1",
//             },
//           },
//           component: () => import("@/components/auth/AuthLogin.vue"),
//         },
//         {
//           path: "password_reset_mail",
//           name: "pc_lp_password_reset_mail",
//           component: () =>
//             import("@/components/auth/AuthPasswordResetMail.vue"),
//         },
//         {
//           path: "password_reset",
//           name: "pc_lp_password_reset",
//           ...commonSetting.passwordReset,
//           component: () => import("@/components/auth/AuthPasswordReset.vue"),
//         },
//         {
//           path: "mail_me",
//           name: "pc_lp_mail_me",
//           ...commonSetting.mailMe,
//           component: () => import("@/components/auth/AuthMailMe.vue"),
//         },
//         {
//           // /pc/lp/hoge など、上記以外の間違ったルートはリダイレクト
//           path: "*",
//           redirect: { name: "pc_lp" },
//         },
//       ],
//     },
//     /* SP Routes */
//     {
//       path: "/sp/",
//       component: () => import("@/views/SpFullView.vue"),
//       children: [
//         {
//           path: "register/verify",
//           name: "sp_register_verify",
//           ...commonSetting.verify,
//           component: () => import("@/components/auth/AuthRegisterVerify.vue"),
//         },

//         {
//           path: "login",
//           name: "sp_login",
//           ...commonSetting.login,
//           component: () => import("@/components/auth/AuthLogin.vue"),
//         },
//         {
//           path: "password_reset_mail",
//           name: "sp_password_reset_mail",
//           component: () =>
//             import("@/components/auth/AuthPasswordResetMail.vue"),
//         },
//         {
//           path: "password_reset",
//           name: "sp_password_reset",
//           ...commonSetting.passwordReset,
//           component: () => import("@/components/auth/AuthPasswordReset.vue"),
//         },
//         {
//           path: "mail_me",
//           name: "sp_mail_me",
//           ...commonSetting.mailMe,
//           component: () => import("@/components/auth/AuthMailMe.vue"),
//         },
//       ],
//     },
//     {
//       path: "/sp/lp",
//       name: "sp_lp",
//       meta: {
//         beta: true,
//       },
//       component: () => import("@/views/LandingPageView.vue"),
//     },
//   ]

//   authRoutes.forEach((route) => {
//     routes.push(route)
//   })
// }

export const useAuthRoutes = (routes) => {
  const authRoutes = [
    {
      path: "/auth/register",
      name: "auth_register",
      ...commonSetting.register,
      component: () => import("@/views/NewMailLandingPageView.vue"),
      children: [
        {
          path: "",
          component: () =>
            import("management").then((module) => module.AuthRegister),
        },
      ],
    },

    {
      path: "/pc/lp/password_reset",
      name: "pc_lp_password_reset",
      ...commonSetting.passwordReset,
      component: () => import("@/views/LpPasswordResetView.vue"),
    },

    /* PC Routes */
    {
      path: "/pc/lp",
      name: "pc_lp",
      component: () => import("@/views/NewLandingPageView.vue"),
      children: [
        {
          path: "register/verify",
          name: "pc_lp_register_verify",
          ...commonSetting.verify,
          component: () =>
            import("management").then((module) => module.AuthRegisterVerify),
        },
        {
          path: "login",
          name: "pc_lp_login",
          ...commonSetting.login,
          meta: {
            tags: {
              googlebot: "all, max-snippet:-1",
            },
          },
          component: () =>
            import("management").then((module) => module.AuthLogin),
        },
        {
          path: "password_reset_mail",
          name: "pc_lp_password_reset_mail",
          component: () =>
            import("management").then((module) => module.AuthPasswordResetMail),
        },
        {
          // /pc/lp/hoge など、上記以外の間違ったルートはリダイレクト
          path: "*",
          redirect: { name: "pc_lp" },
        },
      ],
    },
    /* SP Routes */
    {
      path: "/sp/lp/password_reset",
      name: "sp_password_reset",
      ...commonSetting.passwordReset,
      component: () => import("@/views/LpPasswordResetView.vue"),
    },

    {
      path: "/sp/",
      component: () => import("@/views/NewSpLandingPageView.vue"),
      children: [
        {
          path: "register/verify",
          name: "sp_register_verify",
          ...commonSetting.verify,
          component: () =>
            import("management").then((module) => module.AuthRegisterVerify),
        },
        {
          path: "login",
          name: "sp_login",
          ...commonSetting.login,
          meta: {
            tags: {
              googlebot: "all, max-snippet:-1",
            },
          },
          component: () =>
            import("management").then((module) => module.AuthLogin),
        },
        {
          path: "password_reset_mail",
          name: "sp_password_reset_mail",
          component: () =>
            import("management").then((module) => module.AuthPasswordResetMail),
        },
        {
          // /pc/lp/hoge など、上記以外の間違ったルートはリダイレクト
          path: "*",
          redirect: { name: "sp_lp" },
        },
      ],
    },
    {
      path: "/sp/lp",
      name: "sp_lp",
      meta: {
        beta: true,
      },
      component: () => import("@/views/NewSpLandingPageView.vue"),
    },
  ]

  authRoutes.forEach((route) => {
    routes.push(route)
  })
}
