<template>
  <div>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { onUnmounted, watch } from "vue"
import { useStore } from "vuex"

import {
  provideSharedShouldShowConsultation,
  useShouldShowConsultation,
} from "@/composables/useShouldShowConsultation"
import {
  provideSharedSiteSettings,
  useSiteSettings,
} from "@/composables/useSiteSettings"

// import {
//   provideHankyoSiteSettings,
//   useHankyoSiteSettings,
// } from "@/composables/useHankyoSiteSettings"

// import {
//   provideSharedMailSettings,
//   useMailSettings,
// } from "@/composables/useMailSettings"

// import {
//   provideSharedLineSettings,
//   useLineSettings,
// } from "@/composables/useLineSettings"

const store = useStore()

/**
 * site settings
 */
const siteSettings = useSiteSettings()
provideSharedSiteSettings(siteSettings)

// const hankyoSiteSetting = useHankyoSiteSettings()
// provideHankyoSiteSettings(hankyoSiteSetting)

// const mailSettings = useMailSettings()
// provideSharedMailSettings(mailSettings)

// const lineSettings = useLineSettings()
// provideSharedLineSettings(lineSettings)

const unwatch = watch(
  () => store.getters.managerId,
  (managerId) => {
    if (managerId) {
      siteSettings.loadSiteSettings({ managerId })
      // hankyoSiteSetting.loadHankyoSiteSettings({ managerId })
      // mailSettings.loadMailSettings(managerId)
      // lineSettings.loadLineSettings({ managerId })
    } else {
      siteSettings.initialize({})
      // hankyoSiteSetting.initialize({})
      // mailSettings.initialize({})
      // lineSettings.initialize({})
    }
  },
  { immediate: true }
)
onUnmounted(unwatch)

/**
 * shouldShowConsultation
 */
const shouldShowConsultation = useShouldShowConsultation()
provideSharedShouldShowConsultation(shouldShowConsultation)
</script>

<style scoped></style>
