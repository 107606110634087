import { getPropertiesByPropertyIds } from "@/helpers/api"
import { fetchApartmentImagesInCache } from "@/helpers/imagesCache"
import { Property } from "@share/entities/Property"
import { computedAsync, useLocalStorage } from "@vueuse/core"
import { chunk } from "lodash"
import { defineStore } from "pinia"
import { useStore } from "vuex"

export const usePropertyHistory = defineStore("propertyViewHistory", () => {
  const histories = useLocalStorage<
    { propertyId: string; propertyType: string }[]
  >("propertyViewHistory", [])

  function setHistories(
    newHistory: { propertyId: string; propertyType: PropertyType }[]
  ) {
    histories.value = newHistory
  }

  function prependHistory(h: {
    propertyId: string
    propertyType: PropertyType
  }) {
    histories.value = [
      h,
      ...histories.value
        .filter(
          (x) =>
            !(
              x.propertyId === h.propertyId && x.propertyType === h.propertyType
            )
        )
        .slice(0, 9),
    ]
  }

  const historyProperties = computedAsync(async () => {
    const historyIndex = Object.fromEntries(
      Object.entries(histories.value).map(([i, v]) => [
        `${v.propertyType}-${v.propertyId}`,
        Number(i),
      ])
    )
    const apartmentIds = histories.value
      .filter((x) => x.propertyType === "apartment")
      .map((x) => x.propertyId)
    const houseIds = histories.value
      .filter((x) => x.propertyType === "house")
      .map((x) => x.propertyId)
    const properties: Property[] = []
    if (apartmentIds.length > 0) {
      const apartments = (
        await getPropertiesByPropertyIds({
          propertyIds: apartmentIds,
          propertyType: "apartment",
        })
      ).map((x) => new Property(x))
      properties.push(...apartments)
    }
    if (houseIds.length > 0) {
      const houses = (
        await getPropertiesByPropertyIds({
          propertyIds: houseIds,
          propertyType: "house",
        })
      ).map((x) => new Property(x))
      properties.push(...houses)
    }
    await updateImageCache(properties)
    return properties.sort(
      (a, b) =>
        historyIndex[`${a.propertyType}-${a.propertyId}`] -
        historyIndex[`${b.propertyType}-${b.propertyId}`]
    )
  }, [])

  const store = useStore()

  async function updateImageCache(properties) {
    for (const c of chunk(properties || [], 4)) {
      await fetchApartmentImagesInCache(c)
      store.dispatch("markImageCacheUpdated")
    }
  }

  return {
    histories,
    setHistories,
    prependHistory,
    historyProperties,
  }
})
