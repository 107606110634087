export const UPDATE_FILTER_VALUE = "updateFilterValue"
export const INITIALIZE_FILTER = "initializeFilter"
export const SAVE_PROPERTIES = "saveProperties"
export const UPDATE_DATA_TYPE = "updateDataType"
export const SELECT_PROPERTY = "selectProperty"
export const SELECT_BUILDING = "selectBuilding"
export const USER_LOGIN = "userLogin"
export const USER_LOGOUT = "userLogout"
export const SET_USER_ERROR_CODE = "setUserError"
export const ENABLE_SESSION_FROM_URL_SHARE = "enableSessionFromUrlShare"
export const UPDATE_USER_EMAIL = "updateUserEmail"
export const UPDATE_USER_INFO = "updateUserInfo"
export const UPDATE_USER_PHOTO_INFO = "updateUserPhotoInfo"
export const UPDATE_FILTER_LOCATION_NAME = "updateFilterLocationName"
export const UPDATE_SATEI_INFO_LOCATION = "updateSateiInfoLocation"
export const SAVE_URL_KEY = "createReference"
export const UPDATE_REFERENCE = "updateReference"
export const DELETE_PROPERTIES = "deleteProperties"
export const LOAD_POLYGONS = "loadPolygons"
export const UPDATE_SATEI_INFO = "updateSateiInfo"
export const UPDATE_IS_FILTER_MODE = "isFilterMode"
export const INITIALIZE_POLYGONS = "initializePolygons"
export const REPLACE_LAYERS = "replaceLayers"
export const UPDATE_MAP_CENTER = "updateMapCenter"
export const UPDATE_SELL_FORM_EMAIL_SEND_FROM = "updateSellFormEmailSendFrom"
export const UPDATE_LOCAL_STORAGE_VALUE = "updateLocalStorageValue"
export const UPDATE_EMAIL_SEND_FROMS = "updateEmailSendFroms"
export const UPDATE_SP_SIMPLE_MESSAGE_CONTENTS = "updateSpSimpleMessageContents"
export const UPDATE_CUSTOM_MAIL_TEMPLATE_DATA = "updateCustomMailTemplateData"
export const UPDATE_LIST_APPEARANCE_TYPE = "updateListAppearanceType"
export const UPDATE_SORT_TYPE = "updateSortType"
export const MARK_IMAGE_CACHE_UPDATED = "markImageCacheUpdated"
export const UPDATE_MANAGER = "updateManager"
export const SET_BOUNDS = "setBounds"
export const RETRIEVE_FILTER = "retrieveFilter"
export const RESET_FILTER_PRICE = "resetFilterPrice"
