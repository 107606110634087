import { Filter } from "@share/entities/Filter"

/**
 * @type {Filter}
 */
export const initialFilter = new Filter()

export const initialState = {
  userErrorCode: "",
  selectedContractType: "sale", // sale, contract, refer, all
  selectedPropertyId: "",
  modalMode: "",
  sellFormEmailSendFrom: null,
  sateiTarget: {
    position: {
      lat: "",
      lng: "",
    },
    location: "",
    exclusiveArea: "",
    propertyType: "",
    referencePrice: "",
  },
  mapCenter: {
    position: {
      lat: "",
      lng: "",
    },
    zoom: 0,
  },
  isProcessing: false,
  listAppearanceType: "Photos",
  location: [],
  sessionPropertySearchNum: 0,
  isSessionFromUrlShare: false,
  // NOTE: storeで使う際にはここを独自ロジックで初期化して使う
  filter: new Filter(),
  activeFilter: {
    locationName: [],
  },
  sortType: "NEW",
  urlKey: "",
  manager: undefined,
  emailSendFroms: [],
  registerForm: {
    isLoggedIn: false,
    userId: "",
    managerId: "",
    email: "",
    sesEmailVerified: "",
    corporateName: "",
    userName: "",
    phone: "",
    photoName: "",
    photoUploaded: "notUploaded",
    photoUrl: "/assets/images/icons/profile.svg",
    role: "",
    customerId: "",
    currentPeriodEnd: 0,
    cancelAtPeriodEnd: false,
    createdAt: null,
    currentPeriodEndString: null,
    expiresAt: null,
    expiresAtString: null,
  },
  spSimpleMessageContents: [],
  // constのLOCAL_STORAGE_KEYSの値が更新されると、localStorageの値としてstore上も保存される
  // 各種componentからwatchして使いたいときはここを参照する
  localStorageValues: {},
  isFilterMode: false,
  imageCacheUpdatedAt: Number.MAX_SAFE_INTEGER,
}
