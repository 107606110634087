<template>
  <label :for="checkboxId" class="BaseCheckbox__wrapper">
    <input
      type="checkbox"
      class="BaseCheckbox__input"
      :id="checkboxId"
      :value="value"
      v-model="model"
      :disabled="disabled"
    />
    <span class="BaseCheckbox__label">
      <slot></slot>
    </span>
  </label>
</template>

<script>
let count = 0
const generateCheckboxId = () => `BaseCheckbox__${count++}`

export default {
  emits: ["update:modelValue"],
  props: {
    value: { type: String, required: true },
    modelValue: { type: Array, required: true },
    id: { type: String, required: false },
    disabled: { type: Boolean, required: false },
  },
  created() {
    this.checkboxId = this.id || generateCheckboxId()
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit("update:modelValue", v)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.BaseCheckbox__wrapper {
  display: inline-block;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  position: relative;
}
.BaseCheckbox__input {
  position: absolute;
  opacity: 0;
}
.BaseCheckbox__label {
  position: relative;
  display: inline-block;

  padding-left: calc(16px + 8px);

  .BaseCheckbox__input:disabled + & {
    color: $light-gray;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    display: inline-block;
  }

  /* box */
  &::before {
    height: 16px;
    width: 16px;

    left: 0px;

    border-radius: 2px;
    background: white;

    .BaseCheckbox__input:focus + & {
      box-shadow: 0 0 0 3px $light-gray;
    }
    .BaseCheckbox__input:not(:focus) + & {
      border: 1px solid $light-gray;
    }

    .BaseCheckbox__input:checked + & {
      background: $blue;
      border: none;
    }
    .BaseCheckbox__input:checked:hover + & {
      background: $royal-blue;
    }
    .BaseCheckbox__input:checked:disabled + & {
      background: $light-gray;
    }
  }

  /* checkmark */
  &::after {
    height: 5px;
    width: 9px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;

    transform: rotate(-45deg);

    left: 4px;
    top: 4px;

    .BaseCheckbox__input + & {
      content: none;
    }
    .BaseCheckbox__input:checked + & {
      content: "";
    }
  }
}
</style>
