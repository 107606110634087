import { POLYGON_TYPE } from "@share/const"

class Container {
  constructor(children = []) {
    this.children = children
    for (const child of children) {
      this[child.id] = child
    }
  }
  get hasChildren() {
    return this.children.length !== 0
  }
  // extends Array とすると、 computed に登録したときに
  // this[child.id] = child; によって生やしたプロパティが消失する。
  // sublayers に Container を入れたいので、
  // Container を 独自に iteratable & array like にすることで対応。
  [Symbol.iterator]() {
    return this.children[Symbol.iterator]()
  }
  get length() {
    return this.children.length
  }
}

class ContainerWithIdAndName extends Container {
  constructor(children, { id, name }) {
    super(children)
    this.id = id
    this.name = name
  }
}

class Layer extends ContainerWithIdAndName {
  constructor({ sublayers, id, name, detailName, options, icon }) {
    super(sublayers, { id, name })
    this.detailName = detailName
    this.options = options
    this.icon = icon
  }
  get sublayers() {
    return this.children
  }
  get hasSublayers() {
    return this.hasChildren
  }
}

const eleSchool = new Layer({
  id: POLYGON_TYPE.ELE_SCHOOL,
  name: "小学校",
  options: {
    strokeColor: "#228B22",
    strokeOpacity: 1.0,
    strokeWeight: 2,
    fillColor: "#228B22",
    fillOpacity: 0.1,
  },
  icon: "building",
})

const jhSchool = new Layer({
  id: POLYGON_TYPE.JH_SCHOOL,
  name: "中学校",
  options: {
    strokeColor: "#228B22",
    strokeOpacity: 1.0,
    strokeWeight: 2,
    fillColor: "#228B22",
    fillOpacity: 0.1,
  },
  icon: "book",
})
const flood = new Layer({
  id: POLYGON_TYPE.FLOOD,
  name: "浸水",
  detailName: "氾濫浸水想定区域",
  sublayers: new Container([
    new Layer({
      id: "1",
      name: "~0.5m",
      options: {
        strokeColor: "transparent",
        fillColor: "#C9DDEF",
        fillOpacity: 0.5,
      },
    }),
    new Layer({
      id: "2",
      name: "0.5~5.0m",
      options: {
        strokeColor: "transparent",
        fillColor: "#2D7BB7",
        fillOpacity: 0.5,
      },
    }),
    new Layer({
      id: "3",
      name: "5.0m~",
      options: {
        strokeColor: "transparent",
        fillColor: "#0F4D92",
        fillOpacity: 0.5,
      },
    }),
  ]),
  icon: "flood",
})
const sedimentDisaster = new Layer({
  id: POLYGON_TYPE.SEDIMENT_DISASTER,
  name: "土砂",
  sublayers: new Container([
    new Layer({
      id: "1",
      name: "土砂災害警戒区域",
      options: {
        strokeColor: "transparent",
        fillColor: "#C19A21",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "2",
      name: "土砂災害特別警戒区域",
      options: {
        strokeColor: "transparent",
        fillColor: "#E12168",
        fillOpacity: 0.3,
      },
    }),
  ]),
  icon: "umbrella",
})
const tsunami = new Layer({
  id: POLYGON_TYPE.TSUNAMI,
  name: "津波",
  detailName: "津波浸水想定区域",
  sublayers: new Container([
    new Layer({
      id: "1",
      name: "~0.3m",
      options: {
        strokeColor: "transparent",
        fillColor: "#FFCC01",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "2",
      name: "0.3~1.0m",
      options: {
        strokeColor: "transparent",
        fillColor: "#FF9A01",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "3",
      name: "1.0~5.0m",
      options: {
        strokeColor: "transparent",
        fillColor: "#FF6701",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "4",
      name: "5.0~",
      options: {
        strokeColor: "transparent",
        fillColor: "#900009",
        fillOpacity: 0.3,
      },
    }),
  ]),
  icon: "tsunami",
})
const useDistrict = new Layer({
  id: POLYGON_TYPE.USE_DISTRICT,
  name: "用途地域",
  sublayers: new Container([
    new Layer({
      id: "1",
      name: "第一種低層住居専用地域",
      options: {
        strokeColor: "#339933",
        strokeWeight: 2,
        fillColor: "#339933",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "2",
      name: "第二種低層住居専用地域",
      options: {
        strokeColor: "#008080",
        strokeWeight: 2,
        fillColor: "#008080",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "3",
      name: "第一種中高層住居専用地域",
      options: {
        strokeColor: "#00CC00",
        strokeWeight: 2,
        fillColor: "#00CC00",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "4",
      name: "第二種中高層住居専用地域",
      options: {
        strokeColor: "#CCCC00",
        strokeWeight: 2,
        fillColor: "#CCCC00",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "5",
      name: "第一種住居地域",
      options: {
        strokeColor: "#FFD966",
        strokeWeight: 2,
        fillColor: "#FFD966",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "6",
      name: "第二種住居地域",
      options: {
        strokeColor: "#FFCC99",
        strokeWeight: 2,
        fillColor: "#FFCC99",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "7",
      name: "準住居地域",
      options: {
        strokeColor: "#FF9999",
        strokeWeight: 2,
        fillColor: "#FF9999",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "8",
      name: "近隣商業地域",
      options: {
        strokeColor: "#FF6699",
        strokeWeight: 2,
        fillColor: "#FF6699",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "9",
      name: "商業地域",
      options: {
        strokeColor: "#FF99FF",
        strokeWeight: 2,
        fillColor: "#FF99FF",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "10",
      name: "準工業地域",
      options: {
        strokeColor: "#CC99FF",
        strokeWeight: 2,
        fillColor: "#CC99FF",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "11",
      name: "工業地域",
      options: {
        strokeColor: "#99CCFF",
        strokeWeight: 2,
        fillColor: "#99CCFF",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "12",
      name: "工業専用地域",
      options: {
        strokeColor: "#6799FF",
        strokeWeight: 2,
        fillColor: "#6799FF",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "21",
      name: "田園住居地域",
      options: {
        strokeColor: "#86B81B",
        strokeWeight: 2,
        fillColor: "#86B81B",
        fillOpacity: 0.3,
      },
    }),
    new Layer({
      id: "99",
      name: "不明",
      options: {
        strokeColor: "#666666",
        strokeWeight: 2,
        fillColor: "#666666",
        fillOpacity: 0.3,
      },
    }),
  ]),
  icon: "flag",
})
export const layers = new Container([
  eleSchool,
  jhSchool,
  flood,
  sedimentDisaster,
  tsunami,
  useDistrict,
])
