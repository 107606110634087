let resolver

/**
 * process module
 *
 * @motivation
 * 複数の Promise のうちひとつでも pending のものがあるか判定したい。
 * マップ画面で、物件とポリゴンのどちらかひとつでもロード中ならスピナーを表示するときなど。
 *
 * @example
 * $store.dispatch("process/run", async () => { await longTask() })
 * $store.getters["process/isRunning"]
 *
 * すべてのプロミスが終了するのを待つ
 * await $store.dispatch("process/run", async () => { await longTask() })
 * await $store.state.process.promise
 */
export default {
  namespaced: true,
  state: {
    // process が開始するときに初期化され、すべて終了するときに解決する
    promise: Promise.resolve(),
    _pids: [],
  },
  mutations: {
    add(state, pid) {
      if (state._pids.length === 0) {
        state.promise = new Promise((resolve) => {
          resolver = resolve
        })
      }
      state._pids.push(pid)
    },
    remove(state, pid) {
      const index = state._pids.findIndex((_pid) => _pid === pid)
      if (index === -1) {
        throw new Error(`process ${pid} is not found`)
      }
      state._pids.splice(index, 1)

      if (state._pids.length === 0) {
        resolver()
      }
    },
  },
  actions: {
    async run({ commit }, process) {
      const pid = getPid()
      commit("add", pid)
      const result = await process()
      commit("remove", pid)
      return result
    },
  },
  getters: {
    isRunning(state) {
      return state._pids.length > 0
    },
  },
}

const getPid = (() => {
  let _pid = 0
  return () => _pid++
})()
