import { CLIENT_DELIVERY_STATUS, DEAL_TYPE } from "@share/const"
import {
  CLIENT_HISTORY_SETTINGS,
  getIsHistoryUpdateLatestInteractionDatetime,
} from "@share/entities/ClientHistory"
import { latLngRound } from "@share/format"
import { getRate } from "@share/math"
import { moment } from "@share/moment"
import { utcToJstFormat } from "@share/timezone"
import { getDomain } from "@share/url"
import { Base } from "../Base"

import { isAfter, isBefore, parseISO, subDays, subHours } from "date-fns"

/**
 * @typedef ClientInitializer
 * @property {import("@share/entities/Client").ClientData} client
 * @property {moment.Moment} [currentTime] 月跨ぎの処理の基準値などに利用。デフォルトは現在時刻。指定するのはテストやデバッグ用の場合のみ
 */
export class Client extends Base {
  /**
   * @param {ClientInitializer}
   */
  constructor({ client, currentTime = moment() }) {
    super()

    // 必須プロパティ
    this.userId = client.userId
    this.urlKey = client.urlKey
    this.email = client.email
    this.propertyType = client.propertyType
    this.dataSource = client.dataSource
    this.status = client.status
    this.dealPhase = client.dealPhase
    this.dealType = client.dealType
    this.referenceData = client.referenceData

    // FIXME: アプリ的にはreferenceData.sateiTarget.positionにあればOK
    //  lat,lngはMySQLのindexの関係から存在しているカラム
    //  referenceData.sateiTarget.positionからのvirtualカラムに対応させるタイミングでここのロジックを外す
    this.lat = client.referenceData.sateiTarget.position.lat
    this.lng = client.referenceData.sateiTarget.position.lng

    // 任意プロパティ
    this.id = client.id
    this.clientName = client.clientName
    this.phone = client.phone
    this.memo = client.memo
    this.latestInteractionDatetime = client.latestInteractionDatetime
    this.latestHotActionDatetime = client.latestHotActionDatetime
    this.latestActionDatetime = client.latestActionDatetime
    this.latestMailDatetime = client.latestMailDatetime
    this.latestMailActionDatetime = client.latestMailActionDatetime
    this.latestSiteActionDatetime = client.latestSiteActionDatetime
    this.latestUpdateActionDatetime = client.latestUpdateActionDatetime
    this.urlShareClickNum = client.urlShareClickNum
    this.mailOpenNum = client.mailOpenNum
    this.mailDeliveredNum = client.mailDeliveredNum
    this.logNum = client.logNum
    this.searchLogNum = client.searchLogNum
    this.consultationContactNum = client.consultationContactNum
    this.consultationDataRequestNum = client.consultationDataRequestNum
    this.mailBeforeSendErrorNum = client.mailBeforeSendErrorNum
    this.deletedAt = client.deletedAt
    this.latestConsultationMailDatetime = client.latestConsultationMailDatetime
    this.emailSendFromId = client.emailSendFromId
    this.hankyoId = client.hankyoId
    this.mailDeliveredNumCurrentMonth = client.mailDeliveredNumCurrentMonth
    this.consultationContactMailOpenNum = client.consultationContactMailOpenNum
    this.consultationContactMailClickNum =
      client.consultationContactMailClickNum
    this.consultationDataRequestMailDeliveredNum =
      client.consultationDataRequestMailDeliveredNum
    this.consultationDataRequestMailOpenNum =
      client.consultationDataRequestMailOpenNum
    this.consultationDataRequestMailClickNum =
      client.consultationDataRequestMailClickNum
    this.consultationTalkMailDeliveredNum =
      client.consultationTalkMailDeliveredNum
    this.createdAt = client.createdAt
    this.updatedAt = client.updatedAt

    // relationのプロパティ
    this.emailSendFromData = client.emailSendFromData
    /**
     * @type {(import("@share/entities/ClientHistory").ClientHistory)[]}
     */
    this.histories = client.histories
    this.userMemoCount = client.userMemoCount
    this.workflows = client.workflows
    this.memoDeletedAt = client.memoDeletedAt
    this.mailRejects = client.mailRejects
    this.lineAccount = client.lineAccount
    this.lineUnreadMessageCount = client.lineUnreadMessageCount
    this.lineLastMessage = client.lineLastMessage

    this.canSendProposalProperties = client.canSendProposalProperties
    /**
     * @type {(import("@share/entities/Line/LineMessage").LineMessage)[]}
     */
    this.lineMessages = client.lineMessages

    // privateプロパティ
    this.currentTime = currentTime
    this.lastCheckedAt = client.lastCheckedAt
    this.hankyoDetail = client.hankyoDetail
  }

  /*
   static methods
   */

  static isDuplicateClient(p1, p2) {
    return (
      p1.userId === p2.userId &&
      p1.email === p2.email &&
      latLngRound(p1.lat) === latLngRound(p2.lat) &&
      latLngRound(p1.lng) === latLngRound(p2.lng) &&
      p1.propertyType === p2.propertyType
    )
  }

  static originalColumnName(columnName) {
    return columnName.replace(/Jst$/, "")
  }

  /**
   *
   * @param {{dealPhase: import("@share/entities/Client").ClientDealPhase}}
   * @returns {import("@share/entities/Client").ClientDeliveryStatus}
   */
  static getStatusFromDealPhase({ dealPhase }) {
    if (dealPhase === "pursuing") {
      return CLIENT_DELIVERY_STATUS.DELIVER
    }
    return CLIENT_DELIVERY_STATUS.STOPPED
  }

  // TODO: typing
  // beforeStatus, afterStatusはoptional
  static getExpectedStatus({
    dealPhase,
    beforeStatus = CLIENT_DELIVERY_STATUS.STOPPED,
    afterStatus = undefined,
    deliveryCount,
    deliveryLimit,
  }) {
    const recommendStatus = this.getStatusFromDealPhase({ dealPhase })
    return this.getSaveStatusFromCount({
      beforeStatus,
      afterStatus: afterStatus || recommendStatus,
      deliveryLimit,
      deliveryCount,
    })
  }

  static getSaveStatusFromCount({
    beforeStatus = CLIENT_DELIVERY_STATUS.STOPPED,
    afterStatus,
    deliveryCount,
    deliveryLimit,
  }) {
    const reachedLimit = deliveryCount >= deliveryLimit
    const overLimit = deliveryCount > deliveryLimit
    /* eslint-disable */
    switch (beforeStatus) {
      case CLIENT_DELIVERY_STATUS.DELIVER: {
        if (
          afterStatus === CLIENT_DELIVERY_STATUS.STOPPED ||
          afterStatus === CLIENT_DELIVERY_STATUS.USER_STOPPED
        ) {
          return afterStatus
        }
        if (overLimit) {
          return CLIENT_DELIVERY_STATUS.OVER_LIMIT
        }
        return afterStatus
      }
      case CLIENT_DELIVERY_STATUS.OVER_LIMIT: {
      }
      case CLIENT_DELIVERY_STATUS.STOPPED: {
        if (afterStatus === CLIENT_DELIVERY_STATUS.DELIVER) {
          if (reachedLimit) {
            return CLIENT_DELIVERY_STATUS.OVER_LIMIT
          }
        }
        return afterStatus
      }
      default: {
        return beforeStatus
      }
    }
    /* eslint-enable */
  }

  /*
    instance methods
   */

  countUp({ attrName, countNum = 1 }) {
    if (typeof this[attrName] !== "number") {
      throw new Error(`TRYING_TO_INCREMENT_NOT_NUMBER_ATTRIBUTE ${attrName}`)
    }
    this[attrName] = this[attrName] + countNum
  }

  deliveredThisMonth() {
    return this.currentMonth === this.latestDeliveredMonth
  }

  shouldDeliverPropertyMail() {
    return this.status === CLIENT_DELIVERY_STATUS.DELIVER
  }

  isNotRecentlySentPropertyMail(termDays = 14) {
    const latestMailDatetime = this.latestMailDatetime || this.createdAt
    return new Date(latestMailDatetime) < subDays(new Date(), termDays)
  }

  isJustCreated(hours = 16) {
    return new Date(this.createdAt) > subHours(new Date(), hours)
  }

  wantToSell() {
    return this.dealType === DEAL_TYPE.SELL
  }

  wantToBuy() {
    return this.dealType === DEAL_TYPE.BUY
  }

  wantToRent() {
    return this.dealType === DEAL_TYPE.RENT
  }

  /*
    setters
   */
  set exclusiveArea(value) {
    this.referenceData.sateiTarget.exclusiveArea = value
  }
  get exclusiveArea() {
    return this.referenceData.sateiTarget.exclusiveArea
  }

  /*
    getters
   */

  get referUrl() {
    return this.referenceData.referUrl
  }
  get domain() {
    return getDomain(this.referUrl)
  }

  /*
    sateiTarget related getters
   */

  get location() {
    return this.referenceData.sateiTarget.location
  }

  get position() {
    return this.referenceData.sateiTarget.position
  }

  get clientNameAtMail() {
    return this.clientName ? `${this.clientName}様` : "お客様"
  }

  get referencePrice() {
    return this.referenceData.sateiTarget.referencePrice
  }

  /*
    filter related getters
   */
  get filter() {
    return this.referenceData.filter
  }

  get floor() {
    return this.filter.floor
  }

  get contractUnitPrice() {
    return this.filter.contractUnitPrice
  }

  get contractPrice() {
    return this.filter.contractPrice
  }

  get exclusiveAreaRange() {
    return this.filter.exclusiveArea
  }

  get landAreaRange() {
    return this.filter.landArea
  }

  get floorPlanNumberOfRooms() {
    return this.filter.floorPlanNumberOfRooms
  }

  get aboveGroundFloorLevels() {
    return this.filter.aboveGroundFloorLevels
  }

  get constructionYearmonth() {
    return this.filter.constructionYearmonth
  }

  get contractDate() {
    return this.filter.contractDate
  }

  get ownerChange() {
    return this.filter.ownerChange
  }

  get saleUpdateDate() {
    return this.filter.saleUpdateDate
  }

  /*
    time related getters
   */
  get currentMonth() {
    return this.currentTime.get("month") + 1
  }

  get latestDeliveredMonth() {
    return moment(this.latestMailDatetime).get("month") + 1
  }

  get mailDeliveredNumCurrentMonthCalc() {
    return this.deliveredThisMonth() ? this.mailDeliveredNumCurrentMonth : 0
  }

  get latestActionDatetimeJst() {
    return utcToJstFormat(this.latestActionDatetime)
  }

  get latestMailDatetimeJst() {
    return utcToJstFormat(this.latestMailDatetime)
  }

  get createdAtJst() {
    return utcToJstFormat(this.createdAt)
  }

  /*
    number related getters
   */
  get mailOpenRate() {
    return getRate(this.mailOpenNum, this.mailDeliveredNum)
  }

  get urlShareClickRate() {
    return getRate(this.urlShareClickNum, this.mailOpenNum)
  }
}

/**
 * 有望な顧客かどうか
 * = 14日以内に `isHotAction: true` の history がある
 * @param {Client} client
 */
export function isClientHot(client) {
  return client.histories?.some((history) => {
    const isHotAction = CLIENT_HISTORY_SETTINGS[history.title]?.isHotAction
    if (!isHotAction) {
      return false
    }
    const now = new Date()
    // 14日以内の履歴かどうか
    const isRecentHistory = isAfter(now, subDays(now, 14))

    return isRecentHistory
  })
}

/**
 * 対応が必要な顧客かどうか
 * @param {Client} client
 */
export function mustContactClient(client) {
  if (isClientHot(client)) {
    let indexOfLatestInteraction = -1
    let indexOfLatestHotAction = -1
    const recentHistories = client.histories.filter((x) =>
      isAfter(parseISO(x.createdAt), subDays(new Date(), 14))
    )

    // histories の後ろから interaction と hotAction の history を探す
    for (let i = recentHistories.length - 1; i >= 0; i--) {
      // 途中で両方見つかったらそれらを比べて終わり
      if (0 <= indexOfLatestInteraction && 0 <= indexOfLatestHotAction) {
        return indexOfLatestInteraction < indexOfLatestHotAction
      }
      const history = recentHistories[i]
      if (
        indexOfLatestInteraction < 0 &&
        getIsHistoryUpdateLatestInteractionDatetime(history)
      ) {
        indexOfLatestInteraction = i
      }
      if (
        indexOfLatestHotAction < 0 &&
        CLIENT_HISTORY_SETTINGS[history.title].isHotAction
      ) {
        indexOfLatestHotAction = i
      }
    }

    // 全部調べた上で、hotAction なのに対応していない場合
    if (indexOfLatestInteraction < 0 && 0 <= indexOfLatestHotAction) {
      return true
    }
  }

  return false
}

/**
 * メール設定を変更したほうがよいかどうか。以下のふたつを満たす。
 * - 作成されてから14日以上経過している
 * - 最新メール配信日時が14日以上前か、一度もメールが配信されていない
 * @param {Client} client
 */
export function shouldChangeClientMailSettings(client) {
  // 追客中でなければ無視
  if (client.dealPhase !== "pursuing") {
    return false
  }

  // 14日前
  const thresholdDate = subDays(new Date(), 14)

  const createdAtDate = new Date(client.createdAt)
  const isClientOldEnough = isBefore(createdAtDate, thresholdDate)
  if (!isClientOldEnough) {
    return false
  }

  const latestMailDatetimeDate = new Date(client.latestMailDatetime)
  const isMailNotSentRecently = isBefore(latestMailDatetimeDate, thresholdDate)
  if (!isMailNotSentRecently) {
    return false
  }

  // 14日以内に配信エリアを変更またはスキップしている
  const didChangeMailSettingsAfterWarning = client.histories.some((history) => {
    const isClientUpdatedHistory = history.title === "clientUpdated"
    if (!isClientUpdatedHistory) {
      return false
    }

    const didChangeRecently = isAfter(
      new Date(history.createdAt),
      thresholdDate
    )
    if (!didChangeRecently) {
      return false
    }

    const didChangeMailSettingsOrSkip =
      Object.keys(history.data.diff).includes("referenceData") ||
      getIsHistoryUpdateLatestInteractionDatetime(history)
    if (!didChangeMailSettingsOrSkip) {
      return false
    }
    return true
  })
  if (didChangeMailSettingsAfterWarning) {
    return false
  }

  return true
}
