<template>
  <div class="BaseRadioButtons__container">
    <BaseRadioButton
      class="BaseRadioButtons__button"
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :modelValue="modelValue"
      @update:modelValue="(value) => $emit('update:modelValue', value)"
      :name="name"
      :disabled="option.disabled"
      >{{ option.text }}</BaseRadioButton
    >
  </div>
</template>

<script>
import BaseRadioButton from "@/components/form/BaseRadioButton.vue"

let count = 0

export default {
  components: {
    BaseRadioButton,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
    },
    label: {
      type: String,
    },
    options: {
      validator: (options) => {
        return options.every(
          (option) =>
            typeof option.value === "string" && typeof option.text === "string"
        )
      },
      default: () => [],
    },
    name: {
      type: String,
      default: () => "BaseRadioButtons__" + count++,
    },
  },
}
</script>

<style lang="scss" scoped>
.BaseRadioButtons__container {
  @supports (display: grid) {
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 12px;
  }
}
.BaseRadioButtons__button {
  @supports not (display: grid) {
    margin-right: 24px;
    margin-bottom: 12px;
  }
}
</style>
