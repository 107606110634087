import * as api from "@/helpers/api"
import * as apiAuth from "@/helpers/api/apiAuth"
import { Logger } from "@/helpers/logger"
import store from "@/store"
import { LOCAL_STORAGE_KEY } from "@share/const"

// import { CUSTOMER_STATE_TYPE } from "@share/const";

const logger = Logger("auth")

/**
 * ログイン・ログアウト
 */

export const login = async (email, password) => {
  if (store.getters.isLoggedIn) {
    await logout()
  }
  const res = await apiAuth.login(email, password)
  logger.debug("loginResult", res)
  const { token } = res
  let { user } = res
  localStorage.setItem(LOCAL_STORAGE_KEY.JWT_TOKEN, token)
  if (token) {
    // SESメール認証が終わってなかった場合、ここで情報更新をトライする
    if (!user.sesEmailVerified) {
      logger.debug("sesEmail not verified")
      await api.updateSesEmailVerified()
      user = await api.getSelfUser()
      logger.debug("self user updated", user)
    }

    await updateStoreUserInfos(user)
    await updateStoreUserAdminInfos(user)
  } else {
    throw new Error("login failed, no token returned")
  }
}

export const logout = async () => {
  const result = await apiAuth.logout()
  logger.debug("logout result", result)
  localStorage.removeItem(LOCAL_STORAGE_KEY.JWT_TOKEN)
  await store.dispatch("userLogout")
  return result
}

export const adminSignUp = async (userData) => {
  await apiAuth.postUserAdmin(userData)
}

export const resendSignUpCode = async (email) => {
  return await api.postResendUserVerificationMail(email)
}

export const updateUserProfile = async (
  userId,
  { email, corporateName, userName, phone, photoUrl, photoName }
) => {
  const userProfile = {
    email,
    corporateName,
    userName,
    phone,
    photoUrl,
    photoName,
  }
  // update cognito
  const result = await apiAuth.updateUserProfile(userId, userProfile)
  logger.debug("updateUserProfileResult", result)

  // update stripe
  // Sync implementation with management/src/stores/useUserList.ts when updating
  // below function
  // FIXME: Unify api across 2 projects
  // await apiAuth.updateCustomerDataOnStripe(userId, userProfile)

  await updateStoreUserInfos(userProfile)
}

export const updatePassword = async (email, newPassword) => {
  try {
    await apiAuth.changePassword(email, newPassword)
  } catch (error) {
    const message = /Incorrect username or password./.test(error.message)
      ? "現在のパスワードが間違えています"
      : error.message
    throw new Error(message)
  }
}

export const postUserPhotoToS3 = async (file) => {
  if (!file) {
    throw new Error("no file to post")
  }
  const userId = store.getters.userId

  let fileUrl
  try {
    const imageResult = await api.postUserImage({
      userId,
      imageFile: file,
    })
    fileUrl = imageResult.fileUrl
  } catch (error) {
    throw (
      error || new Error("プロフィール写真が正常にアップロードされていません")
    )
  }
  return fileUrl
}

/**
 * confirm user data
 */

export const forgotPassword = async (email) => {
  await apiAuth.forgotPassword(email)
}

// パスワード変更時認証
export const confirmPassword = async ({ confirmCode, password, email }) => {
  await apiAuth.confirmForgotPassword(email, password, confirmCode)
}

/**
 * 情報取得
 */

/**
 * 登録ステップを満たしているかチェックし、満たさなければ現在の状態に応じたルートオブジェクトを返す。
 */
export const checkRegistrationStep = (requiredSteps) => {
  const registrationStep = store.getters.registrationStep
  const ok =
    (typeof requiredSteps === "string" && registrationStep === requiredSteps) ||
    (Array.isArray(requiredSteps) && requiredSteps.includes(registrationStep))
  if (ok) {
    logger.debug("checkRegistrationStep", {
      ok,
      registrationStep,
      requiredSteps,
    })
    return undefined
  } else {
    const name = {
      NOT_REGISTERED: "pc_lp_login",
      NEED_VERIFICATION: "pc_lp_register_verify",
      COMPLETED: "management",
    }[registrationStep]
    const route = {
      name,
      query: { redirected: true },
    }
    logger.debug("checkRegistrationStep", {
      ok,
      route,
      registrationStep,
      requiredSteps,
    })

    return route
  }
}

/**
 * private methods
 */

// ユーザーのregisterFormの値が変更された際にlocalStorageにも同期する
// FIXME: ログイン機構をサーバーサイドに移す時に消したい
const updateLocalStorageRegisterForm = async () => {
  await store.dispatch("updateLocalStorageValue", {
    key: LOCAL_STORAGE_KEY.REGISTER_FORM,
    value: store.state.registerForm,
  })
}

// admin api からのみ取得できるデータを store に保存する
const updateStoreUserAdminInfos = async (user) => {
  const {
    createdAt,
    currentPeriodEndString,
    expiresAt,
    expiresAtString,
    role,
  } = user

  store.dispatch("updateUserInfo", {
    createdAt,
    currentPeriodEndString,
    expiresAt,
    expiresAtString,
    role,
  })
  await updateLocalStorageRegisterForm()

  /*
  {
    cancelAtPeriodEnd: false,
    corporateName: "テスト株式会社",
    createDate: "2021-04-03T08:55:18.735Z",
    createdAt: 1617440118735,
    currentPeriodEnd: 1620032151000,
    currentPeriodEndString: "5/3/2021",
    customerId: "cus_JEYNHQZFYq9FQQ",
    deliveryLimit: 5,
    email: "xxxx@gmail.com",
    enabled: true,
    expiresAt: 1620204951000,
    expiresAtString: "5/5/2021",
    id: "53f51d96-aab8-4a37-a852-c4267ae5a34e",
    lastModifiedDate: "2021-04-03T08:55:53.183Z",
    phone: "09090909090",
    photoUrl: "https://pinrich-files.s3-ap-northeast-1.amazonaws.com/profile.png",
    status: "CONFIRMED",
    userName: "xxx"
  }
  */
}

// cognito から取得・パースしたデータを store に保存する
const updateStoreUserInfos = async ({
  userId,
  managerId,
  email,
  sesEmailVerified,
  corporateName,
  userName,
  phone,
  customerId,
  currentPeriodEnd,
  cancelAtPeriodEnd,
  photoName,
  photoUploaded,
  photoUrl,
  role,
}) => {
  store.dispatch("userLogin")
  // ログインユーザーを切り替えた時、前のユーザー情報がセッションに残る可能性があるので、更新する
  store.dispatch("updateUserEmail", email)
  store.dispatch("updateUserInfo", {
    userId,
    managerId,
    sesEmailVerified,
    corporateName,
    userName,
    phone,
    customerId,
    currentPeriodEnd,
    cancelAtPeriodEnd,
    role,
  })
  store.dispatch("updateUserPhotoInfo", {
    photoName,
    photoUploaded,
    photoUrl,
  })
  await updateLocalStorageRegisterForm()
}

/**
 * @deprecated PN-1601 ローカルのユーザーデータが更新されていないことによるエラー。
 * TODO: ユーザーデータの持ち方を改善する。
 */
export async function refreshRegisterForm() {
  const user = await api.getSelfUser()
  await updateStoreUserInfos(user)
}
