export const insert = (array, newItem, compareFn) => {
  const inserter = (max, min = 0, index = Math.floor(max / 2)) => {
    if (index === max && index === min) {
      array.splice(index, 0, newItem)
      return
    }
    const compared = compareFn(newItem, array[index])
    if (compared > 0) {
      inserter(max, (min = index + 1), Math.floor((max + min) / 2))
    } else if (compared < 0) {
      inserter((max = index), min, Math.floor((max + min) / 2))
    } else {
      array.splice(index, 0, newItem)
    }
  }
  inserter(array.length)
}
const ORDER = {
  ASC: "ASC",
  DESC: "DESC",
}
const calcValuesByOrder =
  (order) =>
  ({ lhs, rhs }) => {
    switch (order) {
      case ORDER.ASC:
        return lhs - rhs
      case ORDER.DESC:
        return rhs - lhs
      default:
        throw new Error(`unknown order type ${order}`)
    }
  }

// compare functions
export const byUpdatedAt = (lhs, rhs) => {
  if (!lhs.updatedAt || !rhs.updatedAt) {
    throw new Error("updatedAt property does not exist")
  }
  return new Date(rhs.updatedAt) - new Date(lhs.updatedAt)
}

const byContractPriceBase = (order) => {
  const calcValues = calcValuesByOrder(order)
  return (lhs, rhs) => {
    if (!lhs.contractPrice || !rhs.contractPrice) {
      throw new Error("contractPrice property does not exist.")
    }
    return calcValues({ lhs: lhs.contractPrice, rhs: rhs.contractPrice })
  }
}
export const byContractPrice = byContractPriceBase(ORDER.DESC)
export const byContractPriceAsc = byContractPriceBase(ORDER.ASC)

const byAreaBase = (order) => {
  const calcValues = calcValuesByOrder(order)
  const limitValue = order === ORDER.DESC ? "0" : "9999999999"

  return (lhs, rhs) => {
    if (lhs.propertyType !== rhs.propertyType) {
      throw new Error("propertyTypes are not same.")
    }
    const attrName =
      lhs.propertyType === "apartment" ? "exclusiveArea" : "buildingArea"
    return calcValues({
      lhs: lhs[attrName] || limitValue,
      rhs: rhs[attrName] || limitValue,
    })
  }
}
export const byArea = byAreaBase(ORDER.DESC)

const byConstructionYearmonthBase = (order) => {
  const calcValues = calcValuesByOrder(order)
  const limitValue = order === ORDER.DESC ? "0" : "9999999999"

  return (lhs, rhs) => {
    const values = {
      lhs: (lhs.constructionYearmonth || limitValue).replace("/", ""),
      rhs: (rhs.constructionYearmonth || limitValue).replace("/", ""),
    }
    return calcValues(values)
  }
}
export const byConstructionYearmonth = byConstructionYearmonthBase(ORDER.DESC)

// sale は contract より前
export const saleBeforeContract = (lhs, rhs) => {
  if (!lhs.dataType || !rhs.dataType) {
    throw new Error("dataType property does not exist.")
  }
  return lhs.dataType === "sale" && rhs.dataType === "contract" ? -1 : 1
}

// sale は updatedAt の降順
export const saleByUpdatedAt = (lhs, rhs) => {
  if (!lhs.dataType || !rhs.dataType) {
    throw new Error("dataType property does not exist.")
  }
  if (!lhs.updatedAt || !rhs.updatedAt) {
    throw new Error("updatedAt property does not exist.")
  }
  return lhs.dataType === "sale" &&
    rhs.dataType === "sale" &&
    lhs.updatedAt > rhs.updatedAt
    ? -1
    : 1
}

export const rentByUpdatedAt = (lhs, rhs) => {
  if (!lhs.dataType || !rhs.dataType) {
    throw new Error("dataType property does not exist.")
  }
  if (!lhs.updatedAt || !rhs.updatedAt) {
    throw new Error("updatedAt property does not exist.")
  }
  return lhs.dataType === "rent" &&
    rhs.dataType === "rent" &&
    lhs.updatedAt > rhs.updatedAt
    ? -1
    : 1
}

// contract は contractDate の降順
// contractDate が同じなら updatedAt の降順
export const contractByContractDateAndUpdatedAt = (lhs, rhs) => {
  if (!lhs.dataType || !rhs.dataType) {
    throw new Error("dataType property does not exist.")
  }
  if (lhs.dataType !== "contract" || rhs.dataType !== "contract") {
    return 1
  }
  if (!lhs.contractDate || !rhs.contractDate) {
    throw new Error("contractDate property does not exist.")
  }
  if (!lhs.updatedAt || !rhs.updatedAt) {
    throw new Error("updatedAt property does not exist.")
  }
  // contractDate は文字列だが、月と日が必ず２桁なのでそのまま比較してよい
  // ２桁でないと "2020-10-01" < "2020-9-30" になってしまう
  if (lhs.contractDate > rhs.contractDate) {
    return -1
  } else if (
    lhs.contractDate === rhs.contractDate &&
    lhs.updatedAt > rhs.updatedAt
  ) {
    return -1
  }
  return 1
}

// 排他的な複数のルールを束ねる
export const bindCompareFuncs = (funcs) => (lhs, rhs) =>
  funcs.some((fn) => fn(lhs, rhs) < 0) ? -1 : 1
export const propertiesCompareFunc = bindCompareFuncs([
  saleBeforeContract,
  saleByUpdatedAt,
  contractByContractDateAndUpdatedAt,
  rentByUpdatedAt,
])

// ソートキーをもとにソート
export const compareFuncs = {
  NEW: propertiesCompareFunc,
  // UPDATED_AT: byUpdatedAt,
  PRICE: byContractPrice,
  PRICE_ASC: byContractPriceAsc,
  AREA: byArea,
  CONSTRUCTION_YEARMONTH: byConstructionYearmonth,
  CONTRACT_DATE: contractByContractDateAndUpdatedAt,
}
