import groupBy from "lodash/groupBy"
import map from "lodash/map"
import reduce from "lodash/reduce"
import { ApartmentImages } from "../entities/ApartmentImages"

export const getApartmentImagesList = ({ images }) => {
  const imagesByMasterId = groupBy(images, "masterId")
  return map(imagesByMasterId, (images, masterId) =>
    ApartmentImages.create({ images, masterId })
  )
}

export const getApartmentImagesByMasterId = ({ images }) => {
  const apartmentImagesList = getApartmentImagesList({ images })
  return reduce(
    apartmentImagesList,
    (result, a) => {
      result[a.masterId] = a
      return result
    },
    {}
  )
}
