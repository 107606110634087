import { ref, onUnmounted, readonly, watchEffect } from "vue"
import store from "@/store"

/**
 * @param {(store) => any} getter
 * @param {(store, dataRef) => void} setter
 * @returns
 */
export const useVuex = (getter, setter = undefined) => {
  const dataRef = ref(getter(store))

  if (setter) {
    watchEffect(() => {
      setter(store, dataRef)
    })
  }

  const unwatch = store.watch(
    () => getter(store),
    (newValue) => {
      dataRef.value = newValue
    }
  )

  onUnmounted(() => {
    unwatch()
  })

  // vue2 & js では readonly は意味がない
  // https://github.com/vuejs/composition-api#readonly
  return readonly(dataRef)
}
