<template>
  <BaseForm>
    <div
      class="BaseInput__input BaseLocationAutoComplete"
      :class="{ 'BaseInput__input--large': large }"
      :style="large ? 'font-size: 22px' : ''"
    >
      <GmapAutocomplete
        :id="id"
        :placeholder="placeholder"
        :modelValue="modelValue"
        @update:modelValue="emits('update:modelValue', $event)"
        class="BaseLocationAutoComplete__input"
      />
      <button
        v-if="!hideButton"
        class="BaseLocationAutoComplete__button"
        :disabled="isProcessing"
        @click="onSubmit"
      >
        <FaIcon v-if="!isProcessing" :icon="['fas', 'search']" />
        <div style="position: absolute; width: inherit">
          <Loading
            :active="isProcessing"
            @update:active="(v) => emits('update:isProcessing', v)"
            :is-full-page="false"
            :width="22"
            :height="22"
            :opacity="0"
            color="var(--color-primary-100)"
          />
        </div>
      </button>
    </div>
  </BaseForm>
</template>

<script lang="ts">
export default {}
</script>

<script setup lang="ts">
import { BaseForm } from "@/components/form"
import { GmapAutocomplete } from "@/components/gmap/base"
import Loading from "vue-loading-overlay"

const props = defineProps<{
  id?: string
  large?: boolean
  placeholder?: string
  isProcessing?: boolean
  hideButton?: boolean
  modelValue: AutocompleteValue
}>()

const emits = defineEmits(["update:modelValue", "update:isProcessing"])

const emitInput = (value) => emits("update:modelValue", value)
const onSubmit = () => {
  emitInput(props.modelValue)
}
</script>

<style lang="scss" scoped>
.BaseLocationAutoComplete {
  display: flex;
  padding: 0;
  overflow: hidden;
}
.BaseLocationAutoComplete__input {
  flex: 1;
  width: 0; // safari 対応
  padding: 1rem;
  border: none;
  &:focus {
    outline: none;
  }
}
.BaseLocationAutoComplete__button {
  height: 100%;
  width: 70px;
  @include max-width("md") {
    width: 50px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  color: var(--light-gray);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border-left: 1px solid var(--grayish-white);
  &:focus {
    outline: none;
    background-color: var(--grayish-white);
    color: var(--gray);
    font-weight: bold;
  }
  &:hover {
    background-color: var(--grayish-white);
    color: var(--gray);
  }
}
</style>
