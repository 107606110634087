<template>
  <div class="BaseModalContainer">
    <button
      v-if="onClose"
      class="BaseModalContainer__close"
      @mousedown.stop.self="onClose"
    ></button>
    <div class="BaseModalContainer__inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    onClose: {
      type: Function,
      required: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.BaseModalContainer {
  position: relative;
}
.BaseModalContainer__close {
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 0px;
  margin: 14px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/assets/images/icons/cancel.svg");
  background-origin: center;
  background-position: center;
  background-size: 24px 24px;
  background-color: transparent;
  background-repeat: no-repeat;
  &:hover {
    background-color: rgba($color: var(--light-gray), $alpha: 0.1);
  }
}

.BaseModalContainer__inner {
  height: 100%;
  padding: 16px;
}
</style>
