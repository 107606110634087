/**
 * 更新時フォームから送信され、直接 DB に保持する
 */
export const stringValueKeys = [
  "subdomain",
  "metaTitle",
  "metaDescription",
  "aiSateiMetaTitle",
  "aiSateiMetaDescription",
  "loanBuyMetaTitle",
  "loanBuyMetaDescription",
  "loanSellMetaTitle",
  "loanSellMetaDescription",
] as const

/**
 * 画像ファイル
 * 更新時フォームから送信され、 S3 にそれぞれ保存したあとに imageSrc を DB に保持する
 */
export const imageFileKeys = [
  "metaImage",
  "faviconImage",
  "logoImage",
  "backgroundImage",
] as const

/**
 *  DBには0,1で保存され、Model層でbooleanになる
 */
export const booleanValueKeys = [] as const
export type BooleanValueKey = (typeof booleanValueKeys)[number]

/**
 * フロントエンドからは直接変更されず、参照するだけ
 */
export const imageSrcKeys = [
  "metaImageSrc",
  "faviconImageSrc",
  "logoImageSrc",
  "backgroundImageSrc",
] as const

export class SiteSettings {
  subdomain: string
  metaTitle: string
  metaDescription: string
  metaImageSrc: string
  faviconImageSrc: string
  logoImageSrc: string
  backgroundImageSrc: string
  aiSateiMetaTitle: string
  aiSateiMetaDescription: string
  loanBuyMetaTitle: string
  loanBuyMetaDescription: string
  loanSellMetaTitle: string
  loanSellMetaDescription: string
  metaImage?: Blob
  faviconImage?: Blob
  logoImage?: Blob
  backgroundImage?: Blob

  constructor(settings: Partial<SiteSettings> = {}) {
    this.subdomain = settings.subdomain || ""
    this.metaTitle = settings.metaTitle || ""
    this.metaDescription = settings.metaDescription || ""

    this.metaImageSrc = settings.metaImageSrc || ""
    this.faviconImageSrc = settings.faviconImageSrc || ""
    this.logoImageSrc = settings.logoImageSrc || ""
    this.backgroundImageSrc = settings.backgroundImageSrc || ""

    this.aiSateiMetaTitle = settings.aiSateiMetaTitle || ""
    this.aiSateiMetaDescription = settings.aiSateiMetaDescription || ""
    this.loanBuyMetaTitle = settings.loanBuyMetaTitle || ""
    this.loanBuyMetaDescription = settings.loanBuyMetaDescription || ""
    this.loanSellMetaTitle = settings.loanSellMetaTitle || ""
    this.loanSellMetaDescription = settings.loanSellMetaDescription || ""

    this.metaImage = settings.metaImage || undefined
    this.faviconImage = settings.faviconImage || undefined
    this.logoImage = settings.logoImage || undefined
    this.backgroundImage = settings.backgroundImage || undefined
  }
}
