import { reactive } from "vue"

import { createContext } from "@/composables/createContext"

type ValidateValue = {
  (): string | null
}
type Injected = {
  register: (name: string, validateValue: ValidateValue) => void
  getFeedback: (name: string) => string | null | undefined
}
export const [provideValidationGroup, injectValidationGroup] =
  createContext<Injected>("validation group")

export const useValidationGroup = () => {
  /**
   * inject される。
   *
   */
  function register(name: string, validateValue: ValidateValue) {
    if (validateValueMap.has(name)) {
      throw new Error(`duplicated name: ${name}`)
    }
    validateValueMap.set(name, validateValue)
  }

  /**
   * inject される。
   * 登録されたフォーム要素はここから feedback を受け取る。
   */
  function getFeedback(name: string) {
    return feedbackMap.get(name)
  }

  /**
   * register された validateValue をすべて実行する
   */
  async function validateGroup() {
    const feedbacks: string[] = []

    validateValueMap.forEach((validateValue, name) => {
      const feedback = validateValue()
      feedbackMap.set(name, feedback)
      if (feedback) {
        feedbacks.push(feedback)
      }
    })

    const hasError = feedbacks.length > 0
    if (hasError) {
      throw new Error("入力内容を確認してください。")
    }

    return true
  }

  /**
   * name から feedback へのマップ。
   */
  const feedbackMap = reactive(new Map<string, string | null>())

  /**
   * name から validateValue へのマップ。
   */
  const validateValueMap = reactive(new Map<string, ValidateValue>())

  provideValidationGroup({
    register,
    getFeedback,
  })

  return {
    validateGroup,
    validateValueMap,
    feedbackMap,
    // feedbacks,
  }
}
