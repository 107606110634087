export class AppError extends Error {
  constructor(message, data, status) {
    super(message)
    this.data = data
    this.status = status
  }
}

export const throwError = (message, data) => {
  const error = new AppError(message, data)
  // NOTE: serverでもclientでも汎用的に表示したいので、consoleを使う
  // eslint-disable-next-line no-console
  console.error(
    JSON.stringify({
      message,
      data,
      stack: error.stack,
    })
  )
  throw error
}
