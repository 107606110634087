import { readonlyCopy } from "./readonlyCopy"

const globalObjName = "__pinrich__"

/**
 * @typedef {{
 *   subdomain: string;
 *   metaTitle: string;
 *   metaDescription: string;
 *   metaImageSrc: string;
 *   faviconImageSrc: string;
 *   logoImageSrc: string;
 *   backgroundImageSrc: string;
 *   aiSateiMetaTitle: string;
 *   aiSateiMetaDescription: string;
 *   loanBuyMetaTitle: string;
 *   loanBuyMetaDescription: string;
 *   loanSellMetaTitle: string;
 *   loanSellMetaDescription: string;
 *   lineEnabled: boolean;
 *   lineLiffId: string;
 * }} SiteSettings
 * @typedef {{
 *   id: string,
 *   name: string,
 *   phone: string,
 *   formattedPhone: string,
 *   photoUrl: string,
 *   corporateName: string,
 *   formattedName: string
 * }} User
 *
 * @type {Readonly<{
 *   siteSettings: SiteSettings,
 *   user: User;
 * }>}
 */
export const pageConstants = readonlyCopy(window[globalObjName])
export function isCustomDomain() {
  return !!pageConstants.siteSettings.subdomain
}
window[globalObjName] = pageConstants
