import { insert, propertiesCompareFunc } from "@/helpers/sort"
// FIXME: imagesCacheはプラットフォーム依存のモジュールなので、Entityがこれに直接依存しているのはよくない。
//   DI化する必要有り。server側で使えないモジュールになっている。
//   unit testもサーバー側に持ってきたことで、できなくなっている
import { getAllImagesOfProperty } from "@/helpers/imagesCache"

import { Property } from "@share/entities/Property"

export class Building extends Array implements Locatable {
  constructor(properties?: Property | Property[]) {
    super()

    if (properties) {
      const ps = Array.isArray(properties) ? properties : [properties]
      this.add(...ps)
    }
  }

  _propertyNamesSet = new Set()
  propertiesByType: {
    [_ in "all" | "sale" | "contract" | "rent"]: Property[]
  } = {
    all: this,
    sale: [],
    contract: [],
    rent: [],
  }
  // sateiTarget 用
  _position = undefined
  _latLng = undefined
  isSateiTarget = false

  // 挿入ソートで追加していく
  add(...properties) {
    for (const property of properties) {
      if (!property.dataType) continue
      insert(this, property, propertiesCompareFunc)
      this._propertyNamesSet.add(property.locationName)
      this.propertiesByType[property.dataType].push(property)
    }
  }

  // 自作クラスのインスタンスを Vuex getters に入れると getters/setters が消失するので、 Object.freeze() して使う
  get masterId() {
    return this[0] ? this[0].masterId : "sateiTarget"
  }
  get position() {
    return this._position || this[0].position
  }
  set position(position) {
    this._position = position
  }
  get lat() {
    return this.latLng.lat
  }
  get lng() {
    return this.latLng.lng
  }
  get latLng() {
    return this._latLng || this[0].latLng
  }
  set latLng(latLng) {
    this._latLng = latLng
  }
  get propertyNames() {
    return Array.from(this._propertyNamesSet)
  }
  get address() {
    return (
      (this[0].prefectureName || "") +
      (this[0].locationName1 || "") +
      (this[0].town || "")
    )
  }
  get constructionYearmonth() {
    if (this.propertyType !== "apartment") {
      return undefined
    }
    return this[0].constructionYearmonth
  }
  get aboveGroundFloorLevels() {
    if (this.propertyType !== "apartment") {
      return undefined
    }
    return this[0].aboveGroundFloorLevels
  }
  get aboveGroundFloorLevelsDisplay() {
    if (this.propertyType !== "apartment") {
      return undefined
    }
    return this.aboveGroundFloorLevels && this.aboveGroundFloorLevels + "階建て"
  }
  get buildingName() {
    if (this.propertyType !== "apartment") {
      return undefined
    }
    return this[0].buildingName
  }
  get propertyType(): "sateiTarget" | "apartment" | "house" {
    return this.isSateiTarget && this.length === 0
      ? "sateiTarget"
      : this[0].propertyType
  }
  get propertyTypeLabel() {
    return {
      apartment: "マンション",
      house: "戸建",
    }[this.propertyType]
  }
  get unitAmount() {
    if (this.propertyType !== "apartment") {
      return undefined
    }
    return this[0].unitAmount
  }
  get structure() {
    if (this.propertyType !== "apartment") {
      return undefined
    }
    return this[0].structure
  }
  get buildingCoverageRatio() {
    if (this.propertyType !== "apartment") {
      return undefined
    }
    return this[0].buildingCoverageRatio
  }
  get floorAreaRatio() {
    if (this.propertyType !== "apartment") {
      return undefined
    }
    return this[0].floorAreaRatio
  }
  get managementForm() {
    if (this.propertyType !== "apartment") {
      return undefined
    }
    return this[0].managementForm
  }
  get manager() {
    if (this.propertyType !== "apartment") {
      return undefined
    }
    return this[0].manager
  }
  get usageArea() {
    if (this.propertyType !== "apartment") {
      return undefined
    }
    return this[0].usageArea
  }
  get landPrivilege() {
    if (this.propertyType !== "apartment") {
      return undefined
    }
    return this[0].landPrivilege
  }
  get cityPlanning() {
    if (this.propertyType !== "apartment") {
      return undefined
    }
    return this[0].cityPlanning
  }
  get legalRestrictions() {
    if (this.propertyType !== "apartment") {
      return undefined
    }
    return this[0].legalRestrictions
  }
  get images() {
    return getAllImagesOfProperty(this[0])
  }
  get saleProperties() {
    return this.propertiesByType.sale
  }
  get contractProperties() {
    return this.propertiesByType.contract
  }
  get rentProperties() {
    return this.propertiesByType.rent
  }
  get priceRange() {
    let min = Infinity
    let max = -Infinity
    for (const property of this) {
      const price = parseInt(property.contractPrice)
      if (price < min) {
        min = price
      }
      if (max < price) {
        max = price
      }
    }
    return { min, max }
  }
  get priceRangeLabel() {
    const { min, max } = this.priceRange
    const range = min === max ? `${min}万円` : `${min}〜${max}万円`
    return range
  }
}

/**
 * 同一建物内の事例を updatedAt を降順に並べ替え、さらに建物の最新の事例の updatedAt が降順になるように並べ替える関数
 * @param {Property[]} properties
 * @return {Property[][]} list of properties group by buildings
 */
export const sortPropertiesGroupByBuilding = (properties: Property[]) => {
  const mapLatLngToIndex = new Map()
  const propertiesOfBuildings: Building[] = []
  for (const property of properties) {
    const index = mapLatLngToIndex.get(property.latLng)
    if (index >= 0) {
      propertiesOfBuildings[index].add(property)
    } else {
      const building = new Building(property)
      const lastIndex = propertiesOfBuildings.push(building)
      mapLatLngToIndex.set(building.latLng, lastIndex - 1)
    }
  }

  propertiesOfBuildings.sort((lhs, rhs) =>
    propertiesCompareFunc(lhs[0], rhs[0])
  )
  return propertiesOfBuildings
}
