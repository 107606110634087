<template>
  <div class="BaseCheckboxes__container" :class="{ grid }">
    <BaseCheckbox
      class="BaseCheckboxes__checkbox"
      :class="{ grid }"
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :disabled="option.disabled"
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
      >{{ option.text }}</BaseCheckbox
    >
  </div>
</template>

<script>
import BaseCheckbox from "@/components/form/BaseCheckbox.vue"

export default {
  emits: ["update:modelValue"],
  components: {
    BaseCheckbox,
  },
  props: {
    label: {
      type: String,
    },
    options: {
      validator: (options) => {
        return options.every(
          (option) =>
            typeof option.value === "string" && typeof option.text === "string"
        )
      },
      default: () => [],
    },
    modelValue: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    grid: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.BaseCheckboxes__container {
  display: flex;
  flex-flow: column;
  gap: var(--s0);
}
.BaseCheckboxes__container .grid {
  @supports (display: grid) {
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  }
}
.BaseCheckboxes__checkbox .grid {
  @supports not (display: grid) {
    margin-right: 24px;
    margin-bottom: 12px;
  }
}
</style>
