<template>
  <form novalidate @submit.prevent="onSubmit($event)">
    <slot />
  </form>
</template>

<script setup lang="ts">
/**
 * submit イベントをトラップして、バリデーションを実行する form コンポーネント
 *
 * @example
 * <BaseForm @submit="onSubmit" @error="onError">
 *   <FormInput v-model="input" />
 * </BaseForm>
 */

import { useValidationGroup } from "@/composables/useValidationGroup"

const emit = defineEmits<{
  /**
   * フォームバリデーションに成功したとき
   */
  (name: "submit", e: SubmitEvent): void
  /**
   * フォームバリデーションに失敗したとき
   */
  (name: "error", e: Error): void
}>()

const validation = useValidationGroup()

async function onSubmit(e: SubmitEvent) {
  try {
    await validation.validateGroup()
    emit("submit", e)
  } catch (err) {
    emit("error", err as Error)
  }
  return false
}
</script>
