export { default as BaseForm } from "@/components/form/BaseForm.vue"

import FormWrapper from "@/components/form/FormWrapper"

import BaseInput from "@/components/form/BaseInput.vue"
import BasePhotoInput from "@/components/form/BasePhotoInput.vue"
import BaseCheckboxes from "@/components/form/BaseCheckboxes.vue"
import BaseRadioButtons from "@/components/form/BaseRadioButtons.vue"
import BaseOptionalBoxes from "@/components/form/BaseOptionalBoxes.vue"
import BaseSelect from "@/components/form/BaseSelect.vue"
import BaseSelectRange from "@/components/form/BaseSelectRange.vue"
import BaseAutoSuggestInput from "@/components/form/BaseAutoSuggestInput.vue"
import BaseLocationAutoComplete from "@/components/form/BaseLocationAutoComplete.vue"
import BaseInputWithText from "@/components/form/BaseInputWithText.vue"
import BaseTextArea from "@/components/form/BaseTextArea.vue"
import Toggle from "@/components/atoms/Toggle.vue"

export const FormToggle = FormWrapper(Toggle)
export const FormInput = FormWrapper(BaseInput)
export const FormPhotoInput = FormWrapper(BasePhotoInput)
export const FormCheckboxes = FormWrapper(BaseCheckboxes)
export const FormRadioButtons = FormWrapper(BaseRadioButtons)
export const FormOptionalBoxes = FormWrapper(BaseOptionalBoxes)
export const FormSelect = FormWrapper(BaseSelect)
export const FormSelectRange = FormWrapper(BaseSelectRange)
export const FormAutoSuggestInput = FormWrapper(BaseAutoSuggestInput)
export const FormLocationAutoComplete = FormWrapper(BaseLocationAutoComplete)
export const FormInputWithText = FormWrapper(BaseInputWithText)
export const FormTextArea = FormWrapper(BaseTextArea)
