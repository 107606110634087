import uaParser from "@/helpers/uaparser"
import store from "@/store.js"
import { CONSULTATION_DATA_REQUEST_FROM } from "@share/const"

export const beforeConsultation = async (from, to, next) => {
  const {
    propertyId,
    propertyType,
    type,
    location,
    exclusiveArea,
    contractPrice,
    oldContractPrice,
    roomBuildingFloor,
    floorType,
    dataType,
    email,
    mailKey,
    urlKey,
    listingAgent,
    listingAgentPhone,
  } = from.query
  try {
    await store.dispatch("saveAndPostConsultation", {
      type,
      from: CONSULTATION_DATA_REQUEST_FROM.EMAIL,
      urlKey,
      property: {
        propertyId,
        propertyType,
        location,
        exclusiveArea,
        contractPrice,
        listingAgent,
        listingAgentPhone,
        oldContractPrice,
        roomBuildingFloor,
        floorType,
        dataType,
      },
      mailKey,
      email,
    })

    if (uaParser.isMobile()) {
      const messageContents = [
        {
          classes: {
            size: "large",
          },
          sentences: [
            `${location}の資料請求を受け付けました。`,
            "担当者から詳細情報をお送りさせていただきます。",
            "詳細情報が届かない場合は、大変お手数をお掛けしますが、下記までご連絡ください。",
            `${email}`,
          ],
        },
      ]
      store.dispatch("updateSpSimleMessageContents", messageContents)
    }
  } catch (e) {
    window.alert("エラーが発生しました: " + e.message)
  }

  next()
}
