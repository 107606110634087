import { inject, provide, defineComponent } from "vue"

/**
 * @example
 * const [provideXXX, injectXXX] = createContext(
 *   "XXX",
 *   true
 * );
 */
export function createContext<T = unknown>(
  contextName: string,
  required: true
): [(context: T) => void, () => T]
export function createContext<T = unknown>(
  contextName: string,
  required: false
): [(context: T) => void, () => T | undefined]
export function createContext<T = unknown>(
  contextName: string
): [(context: T) => void, () => T | undefined]
export function createContext<T = unknown>(contextName = "", required = false) {
  const contextKey = Symbol(contextName)
  const provider = (context: T) => provide(contextKey, context)

  if (required) {
    const injectContext = () => {
      const injection = inject<T>(contextKey)
      if (!injection) {
        throw new Error(
          `required context ${contextKey.toString()} is not provided.`
        )
      }
      return injection
    }
    return [provider, injectContext]
  } else {
    const injectContext = () => inject<T | undefined>(contextKey, undefined)
    return [provider, injectContext]
  }
}

/**
 * @example
 * const [provider, injectXXX] = createContext("XXX");
 * const useXXX = () => { return { ... } }
 * const XXXProviderComponent = providerComponentFactory(
 *   "XXXProviderComponent",
 *   provider,
 *   useXXX
 * )
 */
export const providerComponentFactory = (name, provider, hooks) => {
  return defineComponent({
    name,
    setup() {
      const context = hooks()
      provider(context)
      return {
        context,
      }
    },
    render(r) {
      return r("div", this.$slots.default)
    },
  })
}
