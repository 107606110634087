import { ref } from "vue"

import { injectValidationGroup } from "@/composables/useValidationGroup"
import { validateNotEmpty } from "@/helpers/validate"

type Validator<T = unknown> = (value: T) => string | null
type ValidationTargetOption = {
  name?: string
  validator: Validator
  required?: boolean
  modelValue?: any
}

let count = 0

/**
 * ValidationTargetOption は Reactive で
 */
export const useValidationTarget = (option: ValidationTargetOption) => {
  const validationGroup = injectValidationGroup()
  const _name = option.name ? option.name : "Target_" + count++

  if (validationGroup) {
    validationGroup.register(_name, validateValue)
  }

  const feedback = ref<string | null>(null)

  function validateValue() {
    const _feedback = (() => {
      if (option.required && typeof option.modelValue === "string") {
        return (validateNotEmpty(option.modelValue) ||
          option.validator(option.modelValue)) as string | null
      }
      if (option.required) {
        return option.validator(option.modelValue)
      }
      return option.modelValue ? option.validator(option.modelValue) : null
    })()
    feedback.value = _feedback
    return _feedback
  }

  return {
    name: _name,
    feedback,
    validateValue,
  }
}
